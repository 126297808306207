// store.ui


const defaultState = {
  alert: {
    isOpen: false,
  },
  notification: {
    isOpen: false,
  },
  menu: {
    isOpen: false,
  },
};


export default function reducer(ui = defaultState, action) {
  switch (action.type) {
    case 'PROGRESS_START': {
      const inProgress = (ui.inProgress || 0) + 1;
      return { ...ui, inProgress };
    }

    case 'PROGRESS_END': {
      const inProgress = Math.max(ui.inProgress - 1, 0);
      return { ...ui, inProgress };
    }

    case 'UI_TOAST': {
      const toast = action.payload;
      return { ...ui, toast };
    }

    case 'SET_NOTIFICATION': {
      const notification = action.payload;
      return { ...ui, notification: { ...ui.notification, ...notification } };
    }

    case 'SET_ALERT': {
      const alert = action.payload;
      return { ...ui, alert: { ...ui.alert, ...alert } };
    }

    case 'SET_MENU': {
      const isOpen = action.payload;
      return { ...ui, menu: { isOpen } };
    }

    default: {
      return ui;
    }
  }
}
