import * as errors          from '../../util/errors';
import * as Resources       from '../../util/resources';
import createActionCreators from 'redux-resource-action-creators';


const resourceType = 'messageTemplates';

export const remote = {
  CREATE_MESSAGE_TEMPLATE: onCreateMessageTemplate,
  UPDATE_MESSAGE_TEMPLATE: onUpdateMessageTemplate,
  DELETE_MESSAGE_TEMPLATE: onDeleteMessageTemplate,
};


// -- Message Templates

export function readMessageTemplates(businessID) {
  return async function(dispatch) {
    try {
      await dispatch(Resources.readResources({
        url:                `${API_URL}/business/${businessID}/message_templates`,
        resourceType,
        list:               businessID,
        mergeListIds:       false,
        requestKey:         `read-${businessID}`,
        showProgess:        false,
        showDefaultErrorUI: false,
        transformData:      ({ messageTemplates }) => messageTemplates,
      }));
    } catch (error) {
      dispatch(errors.showToastError(error));
      throw error;
    }
  };
}


export function silentlyReadMessageTemplates(businessID) {
  return async function(dispatch) {
    await dispatch(Resources.silentlyReadResources({
      url:                `${API_URL}/business/${businessID}/message_templates`,
      resourceType,
      list:               businessID,
      mergeListIds:       false,
      requestKey:         `read-${businessID}`,
      showProgess:        false,
      showDefaultErrorUI: false,
      transformData:      ({ messageTemplates }) => messageTemplates,
    }));
  };
}


export function saveMessageTemplate({ businessID, template }) {
  if (template.id)
    return updateMessageTemplate({ businessID, template });
  else
    return createMessageTemplate({ businessID, template });
}


export function createMessageTemplate({ businessID, template }) {
  return async function(dispatch) {
    const resources = await dispatch(Resources.createResources({
      url:           `${API_URL}/business/${businessID}/message_templates`,
      body:          template,
      resourceType,
      requestKey:    'create-message-template',
      list:          businessID,
      transformData: data => [ data ],
    }));
    return resources[0];
  };
}


export function updateMessageTemplate({ businessID, template }) {
  return async function(dispatch) {
    await dispatch(Resources.updateResources({
      url:           `${API_URL}/business/${businessID}/message_templates/${template.id}`,
      body:          template,
      resourceType,
      list:          businessID,
      resources:     [ template ],
      transformData: data => [ data ],
    }));
  };
}


export function deleteMessageTemplate({ businessID, template }) {
  return async function(dispatch) {
    await dispatch(Resources.deleteResources({
      url:       `${API_URL}/business/${businessID}/message_templates/${template.id}`,
      resourceType,
      resources: [ template.id ],
    }));
  };
}


// Push action handlers

function onCreateMessageTemplate(createdTemplate) {
  return async function(dispatch) {
    const actionCreators = createActionCreators('create', {
      resourceType,
      list:      createdTemplate.business,
      resources: [ createdTemplate ],
    });
    dispatch(actionCreators.succeeded());
  };
}


function onUpdateMessageTemplate(updatedTemplate) {
  return async function(dispatch) {
    const actionCreators = createActionCreators('update', {
      resourceType,
      list:      updatedTemplate.business,
      resources: [ updatedTemplate ],
    });
    dispatch(actionCreators.succeeded());
  };
}


function onDeleteMessageTemplate(deletedTemplate) {
  return function(dispatch) {
    const actionCreators = createActionCreators('delete', {
      resourceType,
      resources: [ deletedTemplate ],
    });
    dispatch(actionCreators.succeeded());
  };
}
