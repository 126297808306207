import addSearchFields from './add_search_fields';


export default function toResource(customer) {
  // Customer detail returns a list of activities.
  if (!customer.lastActivity && customer.activities) {
    const lastActivity = customer.activities[customer.activities.length - 1];
    return addSearchFields({ ...customer, lastActivity });
  } else
    return addSearchFields(customer);
}
