import * as Conversations from '../conversations';


export const remote = {
  UPDATE_QUOTE: quoteUpdated,
};


export function quoteUpdated({ business: businessID, customer: contactID }) {
  return async function(dispatch) {
    await dispatch(Conversations.silentlyReadConversationFromContactID({ businessID, contactID }));
  };
}
