import * as User  from '../../store/user';
import { lazy }   from 'react';
import renderPage from '../../components/page';

const PaymentsTab       = lazy(() => import(/* webpackChunkName: 'payments' */ './payments_tab'));
const InvoiceDetailPage = lazy(() => import(/* webpackChunkName: 'payments' */ './invoice_detail_page'));
const InvoicesListPage  = lazy(() => import(/* webpackChunkName: 'payments' */ './invoices_list'));
const QuoteListPage     = lazy(() => import(/* webpackChunkName: 'payments' */ './quote_list_page'));
const QuoteDetailPage   = lazy(() => import(/* webpackChunkName: 'payments' */ './quote_detail_page'));
const PayoutsList       = lazy(() => import(/* webpackChunkName: 'payments' */ './payouts_list'));


const tabComponent = {
  component: renderPage(PaymentsTab),
  className: 'payments-tab',
  name:      'payments',
};

export default {
  name:      'Payments',
  path:      '/:businessID/payments',
  component: renderPage(PaymentsTab, { tabPage: true }),
  className: 'payments-tab',
  tabComponent,
  routes:    [
    {
      path:      'invoices',
      name:      'InvoicesPage',
      component: renderPage(InvoicesListPage),
      className: 'invoices-list-page',
      tabComponent,
      routes:    [
        {
          name:      'InvoiceDetail',
          path:      ':invoiceID',
          component: renderPage(InvoiceDetailPage),
          className: 'invoice-detail-page',
          tabComponent,
        },
      ],
    },
    {
      path:      'payouts',
      name:      'PayoutsPage',
      component: renderPage(PayoutsList),
      className: 'payouts-list-page',
      allow:     User.isManager,
      tabComponent,
    },
    {
      path:      'quotes',
      name:      'QuotesPage',
      component: renderPage(QuoteListPage),
      className: 'quote-list-page',
      tabComponent,
      routes:    [
        {
          name:      'QuoteDetail',
          path:      ':quoteID',
          component: renderPage(QuoteDetailPage),
          className: 'quote-detail-page',
          tabComponent,
        },
      ],
    },
  ],
};
