// Reference: https://firebase.google.com/docs/reference/js/firebase.messaging

import * as UI              from '../store/ui';
import * as User            from '../store/user';
import getFirebaseMessaging from './get_firebase_messaging';
import ms                   from 'ms';
import onDataMessage        from './on_data_message';
import OptInBanner          from './opt_in_banner';
import React                from 'react';
import rollbar              from '../rollbar';
import useBoolean           from '../hooks/use_boolean';


export default function Firebase({ enableFirebase, disableFirebase }) {
  const messaging = getFirebaseMessaging();

  if (messaging)
    return <InitializeFirebase {...{ enableFirebase, disableFirebase }}/>;
  else
    return null;
}


function InitializeFirebase({ enableFirebase, disableFirebase }) {
  const [ workerRegistered, setWorkerRegistered ] = useBoolean(false);

  return (
    <>
      {!workerRegistered && (
        <RegisterServiceWorker {...{ setWorkerRegistered }}/>
      )}
      {workerRegistered && (
        <>
          <AddListeners/>
          <CheckPermission {...{ enableFirebase, disableFirebase }}/>
          <OnSignedOut/>
        </>
      )}
    </>
  );
}


function RegisterServiceWorker({ setWorkerRegistered }) {
  React.useEffect(function() {
    let interval;

    async function register() {
      const messaging    = getFirebaseMessaging();
      const registration = await window.navigator.serviceWorker.register('/sw.js');

      // Old app had its own service worker.
      if (typeof registration.update === 'function') {
        try {
          await registration.update();
        } catch (error) {
          rollbar.error(error);
        }
      }

      await messaging.useServiceWorker(registration);

      // If app is open, update Service Worker every hour.  The browser will
      // also attempt to refresh on push events (but only if there are push
      // events), sync events (we don't do that yet), and ignore cache if the
      // last update was more than 24 hours ago.
      //
      // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#manual_updates
      interval = setInterval(function() {
        registration.update();
      }, ms('1h'));

      setWorkerRegistered();
    }

    register();

    return () => {
      clearInterval(interval);
    };
  }, [ setWorkerRegistered ]);

  return null;
}


function AddListeners() {
  React.useEffect(function() {
    const messaging = getFirebaseMessaging();

    // Message arrives while app is in foreground.  It may be a notification
    // message (show in-app) or a data message.  Firebase forwards the message
    // to this function.
    messaging.onMessage(function({ notification, data }) {
      if (notification) {
        const { title, body } = notification;
        const url             = notification.click_action || data.url;
        UI.notify({ title, body, url });
      }

      if (data)
        onDataMessage(data);
    });

    // Message arrives while app in background, and is a data message.
    // (Notification messages displayed by OS/Firebase)
    // sw.js forwards us the message by calling client.postMessage().
    window.navigator.serviceWorker.addEventListener('message', function({ data }) {
      if (data)
        onDataMessage(data);
    });
  }, []);

  return null;
}


function CheckPermission({ enableFirebase, disableFirebase }) {
  const [ optedIn, optIn ]   = useBoolean(false);
  const [ optedOut, optOut ] = useBoolean(false);

  const [ requestedPermission, setRequestedPermission ] = React.useState(false);

  const permission = window.Notification?.permission;
  const isDefault  = (permission === 'default');
  const isGranted  = (permission === 'granted');

  const showOptInBanner       = (isDefault && !requestedPermission && !optedOut);
  const showRequestPermission = (isDefault && optedIn && !requestedPermission);
  const showGranted           = (isGranted);
  const showDenied            = (!isDefault && !isGranted);

  return (
    <>
      {showOptInBanner && <OptInBanner {...{ optIn, optOut }}/>}
      {showRequestPermission && <RequestPermission {...{ setRequestedPermission }}/>}
      {showGranted && <PermissionGranted {...{ enableFirebase, disableFirebase }}/>}
      {showDenied && <PermissionDenied {...{ disableFirebase }}/>}
    </>
  );
}


function RequestPermission({ setRequestedPermission }) {
  React.useEffect(function() {
    async function requestPermission() {
      const messaging = getFirebaseMessaging();
      try {
        await messaging.requestPermission();
      } catch (error) {
        const isIgnored = (error.code === 'messaging/permission-default');
        const isDenied  = (error.code === 'messaging/permission-blocked');
        if (!(isIgnored || isDenied))
          console.error(error);
      } finally {
        setRequestedPermission(true);
      }
    }

    requestPermission();
  }, [ setRequestedPermission ]);

  return null;
}


function PermissionGranted({ enableFirebase, disableFirebase }) {
  React.useEffect(function() {
    const messaging = getFirebaseMessaging();

    async function updateDeviceToken() {
      const deviceToken = await messaging.getToken();
      User.setDeviceToken(deviceToken);
    }

    async function initializeDeviceToken() {
      try {
        await updateDeviceToken();
        messaging.onTokenRefresh(() => updateDeviceToken().catch(rollbar.error));
        enableFirebase();
      } catch (error) {
        disableFirebase();
        rollbar.error(error);
        rollbar.info('Permission is granted, but Firebase failed to get a token');
      }
    }

    initializeDeviceToken();
  }, [ enableFirebase, disableFirebase ]);

  return null;
}


function PermissionDenied({ disableFirebase }) {
  React.useEffect(function() {
    disableFirebase();
  }, [ disableFirebase ]);
  return null;
}


function OnSignedOut() {
  const isSignedOut = User.useIsSignedOut();

  React.useEffect(function() {
    async function stop() {
      const messaging = getFirebaseMessaging();
      try {
        await messaging.deleteToken();
      } catch (error) {
        rollbar.error(error);
      }
    }

    if (isSignedOut)
      stop();
  }, [ isSignedOut ]);

  return null;
}
