import * as Contacts          from '../contacts';
import * as Conversations     from '../conversations';
import * as InvoicesSelectors from './selectors';
import * as Resources         from '../../util/resources';
import * as UI                from '../../store/ui';
import { getInvoiceStatus }   from './helpers';
import createActionCreators   from 'redux-resource-action-creators';


export const remote = {
  UPDATE_INVOICE: invoiceUpdated,
};


export function readInvoices(businessID) {
  return async function(dispatch) {
    await dispatch(Resources.readResources({
      url:                `${API_URL}/business/${businessID}/invoices`,
      resourceType:       'invoices',
      list:               businessID,
      mergeListIds:       false,
      requestKey:         `read-${businessID}`,
      showDefaultErrorUI: false,
      transformData:      ({ invoices }) => invoices.map(toInvoice),
    }));
  };
}

export function toInvoice(invoice) {
  return {
    ...invoice,
    status: getInvoiceStatus(invoice),
  };
}


export function readInvoice(invoiceID) {
  return async function(dispatch, getState) {
    const readStatus = InvoicesSelectors.getInvoiceReadStatus(getState(), invoiceID);

    if (!readStatus.pending) {
      await dispatch(Resources.readResources({
        url:           `${API_URL}/invoice/${invoiceID}`,
        resourceType:  'invoices',
        resources:     [ invoiceID ],
        transformData: data => [ toInvoice(data) ],
      }));
    }
  };
}


export function silentlyReadInvoice(invoice) {
  return async function(dispatch, getState) {
    const readStatus = InvoicesSelectors.getInvoiceReadStatus(getState(), invoice.id);

    if (!readStatus.pending) {
      await dispatch(Resources.silentlyReadResources({
        url:           `${API_URL}/invoice/${invoice.id}`,
        resourceType:  'invoices',
        resources:     [ invoice.id ],
        transformData: data => [ toInvoice(data) ],
      }));
    }
  };
}


export function reloadInvoice(invoice) {
  return async function(dispatch) {
    const contactID  = invoice.customer.id;
    const businessID = invoice.business.id;

    await dispatch(silentlyReadInvoice(invoice));
    await dispatch(Contacts.silentlyReadContact(contactID, businessID));
    await dispatch(Conversations.silentlyReadConversationFromContactID({ contactID, businessID }));
  };
}


export function invoiceUpdated(invoice) {
  return reloadInvoice(invoice);
}


export function markVoid(businessID, invoice) {
  return async function(dispatch) {
    const actionCreators = createActionCreators('update', {
      resourceType: 'invoices',
      list:         businessID,
      resources:    [ invoice ],
    });

    try {
      dispatch(actionCreators.pending());
      const { id }   = invoice;
      const response = await dispatch(Resources.request({
        showDefaultErrorUI: true,
        method:             'DELETE',
        url:                `${API_URL}/invoice/${id}`,
      }));

      const { statusCode } = response;
      const updatedInvoice = { ...invoice, status: 'void', canBeVoided: false };

      dispatch(actionCreators.succeeded({ resources: [ updatedInvoice ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}


export function refund(businessID, invoice) {
  return async function(dispatch) {
    const actionCreators = createActionCreators('update', {
      resourceType: 'invoices',
      list:         businessID,
      resources:    [ invoice ],
    });

    try {
      dispatch(actionCreators.pending());
      const { id }   = invoice;
      const response =  await dispatch(Resources.request({
        showDefaultErrorUI: true,
        method:             'POST',
        url:                `${API_URL}/invoice/${id}/refund`,
      }));

      const { statusCode } = response;
      const updatedInvoice = { ...invoice, status: 'refunded', canBeRefunded: false };

      dispatch(actionCreators.succeeded({ resources: [ updatedInvoice ], statusCode }));
      UI.toast('Payment has been refunded.');
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}


export function resend(invoiceID) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      showDefaultErrorUI: true,
      method:             'POST',
      url:                `${API_URL}/invoice/${invoiceID}/send`,
    }));
  };
}

export function markAsPaid({ businessID, paymentMethod, invoice, amount = invoice.amount }) {
  return async function(dispatch) {
    const actionCreators = createActionCreators('update', {
      resourceType: 'invoices',
      list:         businessID,
      resources:    [ invoice ],
    });

    try {
      dispatch(actionCreators.pending());
      const { id }   = invoice;
      const response = await dispatch(Resources.request({
        url:                `${API_URL}/invoice/${id}/payment`,
        showDefaultErrorUI: true,
        method:             'POST',
        body:               {
          method: paymentMethod,
          amount,
        },
      }));

      const { statusCode } = response;
      const { body }       = response;
      const updatedInvoice = body;

      dispatch(actionCreators.succeeded({ resources: [ updatedInvoice ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}
