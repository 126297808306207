import * as ENV       from '../util/env';
import * as Resources from '../util/resources';
import ms             from 'ms';
import React          from 'react';
import rollbar        from '../rollbar';
import store          from '../store';


export async function getAccessToken() {
  const endpoint = ENV.isCordova() ? '/me/twilio_voip' : '/me/twilio_voice';
  const { body } = await store.dispatch(Resources.silentRequest({
    url: `${API_URL}${endpoint}`,
  }));
  return body;
}


export function useAccessToken() {
  const [ token, setToken ] = React.useState();

  React.useEffect(() => {
    async function loadAccessToken() {
      setToken(await getAccessToken());
    }

    loadAccessToken();

    setInterval(() => {
      loadAccessToken().catch(rollbar.error);
    }, ms('5min'));
  }, []);

  return token;
}
