import * as ENV from '../util/env';

const buildInfo = {
  version: APP_VERSION,
  build:   null,
};

export default buildInfo;


async function getCordovaBuildInfo() {
  if (ENV.isCordova() && window.cordova.getAppVersion) {
    const { getAppVersion } = window.cordova;
    const build             = await getAppVersion.getVersionCode();
    buildInfo.build         = build;
  }
}

getCordovaBuildInfo();
