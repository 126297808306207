import * as ENV from './env';


//  Sign out and error boundary use this to do a "hard" reset:
//  - Wipe out any persistent state
//  - Reload window
//  - Navigate to /
export default function bail() {
  if (typeof window !== 'undefined') {
    window.sessionStorage.clear();
    window.localStorage.clear();

    // This will reload the document (clear the global state), and also redirect
    // user to the root page, so they can sign in again
    if (ENV.isCordova())
      reloadCordovaApp();
    else
      window.location.replace(window.location.pathname);
  }
}


function reloadCordovaApp() {
  // Show splash screen (useful because the app takes time to load)
  window.navigator?.splashscreen?.show();
  // Reload original app url
  window.location.replace('/');
}
