import * as Business  from '../store/business/actions';
import * as Campaigns from '../store/campaigns/actions';
import * as Contacts  from '../store/contacts/actions';
import * as Feedback  from '../store/feedback/actions';
import * as Invoice   from '../store/invoices/actions';
import * as Payments  from '../store/payments/actions';
import * as Templates from '../store/message_templates/actions';
import * as User      from '../store/user/actions';
import rollbar        from '../rollbar';


const resources = [
  Business,
  Contacts,
  Campaigns,
  Feedback,
  Invoice,
  Templates,
  User,
  Payments,
];

// Push message with data received.  If this is a Redux action, run it.
// Typically, the Redux action should be load/reload some data (eg update
// customer list, reload message history for customer).
export default function dispatchAction(action) {
  return async function(dispatch) {
    const { type, payload } = action;
    const actionCreators    = resources.map(creators => creators.remote[type]).filter(Boolean);
    if (actionCreators.length > 0) {
      for await (const creator of actionCreators) {
        try {
          await dispatch(creator(payload));
        } catch (error) {
          rollbar.error(error);
        }
      }
    } else
      rollbar.error(`Discarding push message, no action creator found: ${JSON.stringify(action)}`);
  };
}
