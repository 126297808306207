import { lazy }   from 'react';
import renderPage from '../../components/page';


const AddLocationPage = lazy(() => import(/* webpackChunkName: 'contacts' */ './add_location_page'));


const tabComponent = {
  name:      'addLocation',
  component: null,
};


export default {
  path:      '/:businessID/add-location',
  component: renderPage(AddLocationPage),
  className: 'add-location-page',
  name:      'AddLocation',
  tabComponent,
};
