import * as User        from '../../store/user';
import { lazy }         from 'react';
import renderPage       from '../../components/page';
import SignupBackground from '../signup/signup_background';
import store            from '../../store';

const AccountTab         = lazy(() => import(/* webpackChunkName: 'account' */ './account_tab'));
const NotificationsPage  = lazy(() => import(/* webpackChunkName: 'account' */ './notifications_page'));
const ProfilePage        = lazy(() => import(/* webpackChunkName: 'account' */ './profile_page'));
const UnknownUserPage    = lazy(() => import(/* webpackChunkName: 'account' */ './unknown_user_page'));
const ChangePasswordPage = lazy(() => import(/* webpackChunkName: 'change_password' */ './change_password_page'));


const tabComponent = {
  component: renderPage(AccountTab),
  className: 'account-tab',
  name:      'account',
};

export default {
  path:      '/account',
  alias:     '/:businessID/account',
  component: renderPage(AccountTab, { tabPage: true }),
  className: 'account-tab',
  name:      'Account',
  tabComponent,
  routes:    [
    {
      path: 'unknown',
      name: 'Unknown',
      // eslint-disable-next-line max-params
      beforeEnter(routeTo, routeFrom, resolve, reject) {
        const isAnonymous = User.isAnonymous(store.getState());

        if (isAnonymous)
          resolve();
        else {
          reject();
          this.navigate('/');
        }
      },
      component:  renderPage(UnknownUserPage),
      className:  'unknown-user-page',
      background: SignupBackground,
    },
    {
      path:      'profile',
      alias:     '/:businessID/account/profile',
      name:      'Profile',
      component: renderPage(ProfilePage),
      className: 'profile-page',
      tabComponent,
    },
    {
      path:      'notifications',
      alias:     '/:businessID/account/notifications',
      name:      'Notifications',
      component: renderPage(NotificationsPage),
      className: 'notifications-page',
      tabComponent,
    },
    {
      name:      'ChangePassword',
      path:      'change_password',
      alias:     '/:businessID/account/change_password',
      component: renderPage(ChangePasswordPage),
      className: 'change-password-page',
      tabComponent,
    },
  ],
};
