import * as ENV             from '../../util/env';
import * as Resources       from '../../util/resources';
import * as Selectors       from './selectors';
import * as UI              from '../../store/ui';
import createActionCreators from 'redux-resource-action-creators';


export const remote = {
  // Dispatch to reload the business resource.  Payload is the business ID.
  LOAD_BUSINESS: silentlyLoadBusiness,
};


// Whenever user navigates to new URL, use this to load information about the
// new business
export function setBusinessID(businessID) {
  return async function(dispatch, getState) {
    const shouldLoadBusiness = shouldLoadResource(Selectors.getReadStatus(getState(), businessID));
    if (shouldLoadBusiness)
      await loadBusiness(businessID)(dispatch, getState);

    const shouldLoadTeam = shouldLoadResource(Selectors.getTeamReadStatus(getState(), businessID));
    if (shouldLoadTeam)
      await readTeam(businessID)(dispatch, getState);

    function shouldLoadResource(readStatus) {
      return (readStatus.idle || readStatus.failed);
    }
  };
}


// Whenever user navigates to new URL, use this to load information about the
// new business
export function loadBusiness(businessID) {
  return async function(dispatch, getState) {
    const readStatus = Selectors.getReadStatus(getState(), businessID);

    if (readStatus.pending)
      return;

    await dispatch(Resources.readResources({
      url:           `${API_URL}/business/${businessID}`,
      resourceType:  'business',
      resources:     [ businessID ],
      transformData: business => [ business ],
    }));
  };
}


export function loadBusinessIfNeeded(businessID) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);
    if (!business)
      await dispatch(loadBusiness(businessID));
  };
}


// Reloads information about the business on push message.
export function silentlyLoadBusiness(businessID) {
  return async function(dispatch) {
    await dispatch(Resources.silentlyReadResources({
      url:           `${API_URL}/business/${businessID}`,
      resourceType:  'business',
      resources:     [ businessID ],
      transformData: business => [ business ],
    }));
  };
}

export function updateBusiness({ businessID, ...body }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    await dispatch(Resources.updateResources({
      url:           `${API_URL}/business/${businessID}`,
      body,
      resourceType:  'business',
      resources:     [ business ],
      transformData: data => [ data ],
    }));
  };
}

export function updateBusinessSettings({ businessID, settings }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    await dispatch(Resources.updateResources({
      url:           `${API_URL}/business/${businessID}`,
      body:          { settings },
      resourceType:  'business',
      resources:     [ business ],
      transformData: data => [ data ],
    }));
  };
}


export function updateBusinessCSVMapping({ businessID, csvMapping }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    await dispatch(Resources.updateResources({
      url:           `${API_URL}/business/${businessID}`,
      body:          { csvMapping },
      resourceType:  'business',
      resources:     [ business ],
      transformData: data => [ data ],
    }));
  };
}


export function setSmsFollowUp({ businessID, enabled }) {
  return async function(dispatch) {
    const settings = {
      twilio: {
        followUp: enabled,
      },
    };
    await dispatch(updateBusinessSettings({ businessID, settings }));
  };
}

export function setClassify({ businessID, classifyOption }) {
  return async function(dispatch) {
    const settings = {
      classify: classifyOption,
    };
    await dispatch(updateBusinessSettings({ businessID, settings }));
  };
}

export function setThankYouRepeat({ businessID, repeat }) {
  return async function(dispatch) {
    const settings = {
      schedule: {
        thankYouRepeat: repeat,
      },
    };
    await dispatch(updateBusinessSettings({ businessID, settings }));
  };
}

export function setChat({
  businessID,
  avatarURL,
  color,
  enabled,
  greeting,
  followUp,
  followUpAfterHours,
  multiLocationEnabled,
  displayName,
  postalCodesEnabled,
}) {
  return async function(dispatch) {
    const settings = {
      chat: {
        avatarURL,
        color,
        enabled,
        greeting,
        followUp,
        followUpAfterHours,
        multiLocationEnabled,
        displayName,
        postalCodes: {
          enabled: postalCodesEnabled,
        },
      },
    };

    await dispatch(updateBusinessSettings({ businessID, settings }));
  };
}

export function setSchedule({ businessID, thankYou }) {
  return async function(dispatch) {
    const settings = {
      schedule: {
        thankYou,
      },
    };

    await dispatch(updateBusinessSettings({ businessID, settings }));
  };
}


// -- Payments --

export function setTips({ businessID, enabled }) {
  return async function(dispatch) {
    const settings = {
      payments: {
        tips: enabled,
      },
    };
    await dispatch(updateBusinessSettings({ businessID, settings }));
  };
}


// -- Facebook Page --

export function saveFacebookPage({ businessID, pageID, accessToken }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const response = await dispatch(Resources.request({
        url:    `${API_URL}/business/${businessID}/facebook`,
        method: 'POST',
        body:   {
          pageID,
          accessToken,
        },
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}

export function disconnectFacebookPage(businessID) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const response = await dispatch(Resources.request({
        url:    `${API_URL}/business/${businessID}/facebook`,
        method: 'DELETE',
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}


// -- Instagram Profile --

export function connectInstagramProfile({ businessID, pageID, accessToken }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const response = await dispatch(Resources.request({
        url:    `${API_URL}/business/${businessID}/instagram`,
        method: 'POST',
        body:   {
          pageID,
          accessToken,
        },
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}

export function disconnectInstagramProfile({ businessID }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const response = await dispatch(Resources.request({
        url:    `${API_URL}/business/${businessID}/instagram`,
        method: 'DELETE',
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}


// -- Google Location --

export function updateGooglePlace({ businessID, cid, place }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const google   = { cid, place };
      const response = await dispatch(Resources.request({
        url:    `${API_URL}/business/${businessID}`,
        method: 'PATCH',
        body:   { google },
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}

export function disconnectGooglePlace({ businessID }) {
  return updateGooglePlace({ businessID, cid: null, place: null });
}

export function saveGoogleLocation({ businessID, locationInfo }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const { googleLocation }    = locationInfo;
      const { offlineAccessCode } = locationInfo;

      const clientType = ENV.isCordova() ? 'cordova' : 'web';
      const accountID  = googleLocation.accountName.split('/')[1];
      const locationID = googleLocation.name.split('/')[1];
      const response   = await dispatch(Resources.request({
        url:    `${API_URL}/business/${businessID}/gmb`,
        method: 'POST',
        body:   {
          accountID,
          locationID,
          offlineAccessCode,
          clientType,
        },
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}

export function updateGoogleLocation({ businessID, googleLocation }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const google   = { location: googleLocation };
      const response = await dispatch(Resources.request({
        url:    `${API_URL}/business/${businessID}`,
        method: 'PATCH',
        body:   { google },
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}

export function disconnectGoogleLocation(businessID) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const response = await dispatch(Resources.request({
        url:    `${API_URL}/business/${businessID}/gmb`,
        method: 'DELETE',
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}


// -- Yelp Profile --

export function connectYelpProfile({ businessID, id }) {
  return async function(dispatch) {
    await dispatch(updateBusiness({ businessID, yelp: { id } }));
  };
}

export function disconnectYelpProfile({ businessID }) {
  return async function(dispatch) {
    await dispatch(updateBusiness({ businessID, yelp: { id: null } }));
  };
}

// -- Nextdoor Profile --

export function connectNextdoorProfile({ businessID, id }) {
  return async function(dispatch) {
    await dispatch(updateBusiness({ businessID, nextdoor: { id } }));
  };
}

export function disconnectNextdoorProfile({ businessID }) {
  return async function(dispatch) {
    await dispatch(updateBusiness({ businessID, nextdoor: { id: null } }));
  };
}


// -- TripAdvisor Profile --

export function connectTripAdvisorProfile({ businessID, id }) {
  return async function(dispatch) {
    await dispatch(updateBusiness({ businessID, tripAdvisor: { id } }));
  };
}

export function disconnectTripAdvisorProfile({ businessID }) {
  return async function(dispatch) {
    await dispatch(updateBusiness({ businessID, tripAdvisor: { id: null } }));
  };
}


// -- Team members --

// Read team members (users, providers, etc)
export function readTeam(businessID) {
  return function(dispatch) {
    const id = `${businessID}/team`;

    dispatch(Resources.readResources({
      url:           `${API_URL}/business/${businessID}/team`,
      resourceType:  'business',
      resources:     [ id ],
      transformData: data => [{ ...data, id }],
    }));
  };
}


export function readTwilioA2P(businessID) {
  return function(dispatch) {
    const id = `${businessID}/twilio_a2p`;

    dispatch(Resources.readResources({
      url:           `${API_URL}/business/${businessID}/twilio`,
      resourceType:  'business',
      resources:     [ id ],
      transformData: data => [{ data, id }],
    }));
  };
}

export function updateTwilioA2P({ businessID, body }) {
  return async function(dispatch) {
    const id = `${businessID}/twilio_a2p`;

    await dispatch(Resources.updateResources({
      url:                `${API_URL}/business/${businessID}/twilio`,
      body,
      resourceType:       'business',
      resources:          [ id ],
      transformData:      data => [{ data, id }],
      showDefaultErrorUI: false,
    }));
  };
}


export function provisionTwilioA2P({ businessID }) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      url:                `${API_URL}/business/${businessID}/twilio`,
      method:             'post',
      showDefaultErrorUI: false,
    }));
  };
}


// Provider is:
//
// name     — Provider name (required)
// email    — Email address (required, or phone number)
// phone    — Phone number (required, or email address)
// id       — Provider ID (required)
// role     - Role (required)
// title    - Job title (optional)
export function inviteProvider({ businessID, provider }) {
  return async function(dispatch) {
    const user = {
      name:     provider.name,
      email:    provider.email,
      provider: provider.id,
      phone:    provider.phone,
      role:     provider.role,
      title:    provider.title,
    };

    await dispatch(Resources.createResources({
      url:                `${API_URL}/business/${businessID}/team`,
      body:               user,
      resourceType:       'business',
      requestKey:         'newProvider',
      transformData:      () => [],
      showDefaultErrorUI: false,
    }));

    UI.toast(`Sent an invitation to ${provider.name}`);
    dispatch(readTeam(businessID));
    dispatch(loadBusiness(businessID));
  };
}


// Change change these properties:
//
// name     — User name
// email    — Email address
// phone    — Phone number
// title    - Job title
export function updateUser({ businessID, user, params = {} }) {
  return async function(dispatch) {
    await dispatch(Resources.updateResources({
      url:           `${API_URL}/business/${businessID}/team/${user.id}`,
      body:          user,
      resourceType:  'business',
      requestKey:    'updateUser',
      transformData: () => [],
      ...params,
    }));

    dispatch(readTeam(businessID));
  };
}


export function resendInvite({ businessID, user }) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      method: 'post',
      url:    `${API_URL}/business/${businessID}/team/${user.id}/invitation`,
    }));
    UI.toast(`Re-sent an invitation to ${user.name}`);
  };
}


export function removeUser({ businessID, user }) {
  return async function(dispatch) {
    await dispatch(Resources.deleteResources({
      url:           `${API_URL}/business/${businessID}/team/${user.id}`,
      resourceType:  'business',
      requestKey:    'deleteUser',
      transformData: () => [],
    }));

    dispatch(readTeam(businessID));
    dispatch(loadBusiness(businessID));
  };
}

// Appointment Reminders

export function setAppointmentReminders({ businessID, enabled, offset, dropOffWindow }) {
  return async function(dispatch) {
    const settings = {
      schedule: {
        appointmentReminder: {
          enabled,
          offset,
          dropOffWindow,
        },
      },
    };

    await dispatch(updateBusinessSettings({ businessID, settings }));
  };
}

// Service Reminders

export function setServiceReminders({ businessID, enabled }) {
  return async function(dispatch) {
    const settings = {
      schedule: {
        serviceReminder: {
          enabled,
        },
      },
    };

    await dispatch(updateBusinessSettings({ businessID, settings }));
  };
}


// -- Data sources --

// Read all data sources
export function readDataSources(businessID) {
  return async function(dispatch) {
    const id = `${businessID}/data_sources`;

    await dispatch(Resources.readResources({
      url:           `${API_URL}/business/${businessID}/data_sources`,
      resourceType:  'business',
      list:          `${businessID}/data_sources`,
      resources:     [ id ],
      requestKey:    'readDataSources',
      // Transform the data into a list of resources with an item for each data
      // source name. We need to include a resource for the list id itself so
      // its readStatus also gets updated.
      transformData: data => [{ id }].concat(mapDataSources({ id, data })),
    }));
  };
}

function mapDataSources({ id, data }) {
  // map { member: {}, qbo: {} }
  // to [
  //   { id: '${businessID}/data_sources/member', ...data },
  //   { id: '${businessID}/data_sources/qbo', ...data }
  // ]
  return Object.keys(data).map(name => ({ ...data[name], name, id: `${id}/${name}` }));
}

// Read single data source
export function readDataSource(businessID, name) {
  return async function(dispatch) {
    const id = `${businessID}/data_sources/${name}`;

    await dispatch(Resources.readResources({
      url:           `${API_URL}/business/${businessID}/data_sources/${name}`,
      resources:     [ id ],
      transformData: data => [{ ...data, id }],
    }));
  };
}

// Delete single data source
export function deleteDataSource({ businessID, name }) {
  return async function(dispatch) {
    const id = `${businessID}/data_sources/${name}`;

    await dispatch(Resources.deleteResources({
      url:          `${API_URL}/business/${businessID}/data_sources/${name}`,
      resourceType: 'business',
      requestKey:   'deleteDataSource',
      resources:    [ id ],
    }));

    await dispatch(readDataSources(businessID));
  };
}

// Replace data source
export function replaceDataSource({ businessID, name, ...body })  {
  return async function(dispatch) {
    const id = `${businessID}/data_sources/${name}`;
    await dispatch(Resources.replaceResources({
      url:           `${API_URL}/business/${businessID}/data_sources/${name}`,
      body,
      resourceType:  'business',
      resources:     [ id ],
      transformData: data => [{ ...data, id }],
    }));
  };
}


export function readDataSourceAuthorizationURL({ businessID, name }) {
  return async function(dispatch) {
    const response             = await dispatch(Resources.request({
      method: 'GET',
      url:    `${API_URL}/business/${businessID}/data_sources/${name}/authorization_url`,
    }));
    const { authorizationURL } = response.body;

    return authorizationURL;
  };
}


// -- Reviews

export function readReviews(businessID) {
  return function(dispatch) {
    const id = `${businessID}/reviews`;
    dispatch(Resources.readResources({
      url:           `${API_URL}/business/${businessID}/reviews`,
      resourceType:  'business',
      resources:     [ id ],
      transformData: data => [{ ...data, id }],
    }));
  };
}


// -- Hosted Number
export function recreateHostedNumberOrder({ businessID, orderSid, phone }) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      method: 'delete',
      url:    `${API_URL}/business/${businessID}/hosted_number/${orderSid}`,
    }));

    await dispatch(Resources.request({
      method: 'post',
      url:    `${API_URL}/business/${businessID}/hosted_number`,
      body:   {
        phone,
      },
    }));

    await dispatch(silentlyLoadBusiness(businessID));
  };
}


export function verificationCall({ businessID, orderSid, extension }) {
  return async function(dispatch) {
    const response = await dispatch(Resources.request({
      method: 'post',
      url:    `${API_URL}/business/${businessID}/hosted_number/${orderSid}/verification_call`,
      body:   {
        extension,
      },
    }));

    const { body } = response;
    return body;
  };
}


export function requestLOA({ businessID, orderSid, ...data }) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      method: 'post',
      url:    `${API_URL}/business/${businessID}/hosted_number/${orderSid}/loa`,
      body:   data,
    }));
  };
}

// - Notices

export function dismissNotice({ businessID, name }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const response = await dispatch(Resources.request({
        method: 'post',
        url:    `${API_URL}/business/${businessID}/notice/${name}/dismiss`,
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}


export function updateChecklistItem({ businessID, name, checked }) {
  return async function(dispatch, getState) {
    const business = Selectors.getBusiness(getState(), businessID);

    const actionCreators = createActionCreators('update', {
      resourceType: 'business',
      resources:    [ business ],
    });

    try {
      dispatch(actionCreators.pending());

      const response = await dispatch(Resources.request({
        method: 'post',
        url:    `${API_URL}/business/${businessID}/checklist/${name}`,
        body:   { checked },
      }));

      const { body }       = response;
      const { statusCode } = response;

      dispatch(actionCreators.succeeded({ resources: [ body ], statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}
