import * as Resources       from '../../../util/resources';
import { getUserID }        from '../selectors';
import createActionCreators from 'redux-resource-action-creators';
import ms                   from 'ms';


const userQueryString = 'include_organizations=1';
const profileURL      = `${API_URL}/me?${userQueryString}`;


export function createUserProfile(profile) {
  return async function(dispatch) {
    const actionCreators = createActionCreators('create', {
      resourceType: 'user',
      list:         'me',
      requestKey:   'me',
    });

    try {
      dispatch(actionCreators.pending());
      const response = await dispatch(Resources.request({
        url:          profileURL,
        method:       'POST',
        timeout:      ms('5s'),
        body:         profile,
        showProgress: true,
      }));

      const { body }       = response;
      const { statusCode } = response;
      const resources      = [ getProfile(body) ];
      dispatch(actionCreators.succeeded({ resources, statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}


export function loadUserProfile(params) {
  return async function(dispatch) {
    const actionCreators = createActionCreators('read', {
      resourceType: 'user',
      list:         'me',
      requestKey:   'me',
    });

    try {
      dispatch(actionCreators.pending());
      const response = await dispatch(Resources.request({
        url:    profileURL,
        method: 'GET',
        ...params,
      }));

      const { body }       = response;
      const { statusCode } = response;
      const resources      = [ getProfile(body) ];
      dispatch(actionCreators.succeeded({ resources, statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      if (statusCode === 404) {
        const resources = [ getAnonymousUser() ];
        dispatch(actionCreators.succeeded({ resources, statusCode }));
      } else {
        dispatch(actionCreators.failed({ statusCode }));
        throw error;
      }
    }
  };
}


// TODO does this action need to handle 404 too?
export function silentlyLoadUserProfile() {
  return async function(dispatch) {
    try {
      await dispatch(Resources.silentlyReadResources({
        url:           profileURL,
        resourceType:  'user',
        list:          'me',
        requestKey:    'me',
        transformData: user => [ getProfile(user) ],
      }));
    } catch (error) {
      // Silent operation
    }
  };
}


// Send updates to the server.
export function updateProfile(profile, params = {}) {
  return async function(dispatch) {
    await dispatch(Resources.updateResources({
      url:           `${API_URL}/me`,
      body:          profile,
      resourceType:  'user',
      resources:     [ profile ],
      transformData: user => [ getProfile(user) ],
      ...params,
    }));
  };
}


// Receives update from the server
export function userUpdated(profile) {
  return function(dispatch, getState) {
    const userID = getUserID(getState());
    if (userID === profile.id) {
      const actionCreators = createActionCreators('update', { resourceType: 'user' });
      dispatch(actionCreators.succeeded({
        resources: [ getProfile(profile) ],
      }));
    }

    // TODO this updates the memberOf for this user.  Identify any businesses
    // the user no longer belongs to, remove these resources from memory, and
    // any customer list.  And if necessary, redirect user to a different
    // business.
  };
}


// Only user accounts have a valid user ID.
function getProfile(user) {
  return {
    ...user,
    id:        'profile',
    anonymous: false,
    userID:    user.id,
  };
}


function getAnonymousUser() {
  return {
    id:        'profile',
    userID:    null,
    anonymous: true,
    memberOf:  [],
  };
}
