import * as DateFns from 'date-fns';
import ms           from 'ms';


export const minimumDate = '2000-01-01';

export function getRange(range) {
  switch (range) {
    case 'week':
      return week();
    case 'month':
      return month();
    case 'year':
      return year();
    default:
      throw new Error('invalid date range');
  }
}


export function week() {
  const range = 'week';
  const to    = new Date();
  const from  = new Date(to - ms('7d'));
  return asStrings({ range, from, to });
}


export function month() {
  const range = 'month';
  const to    = new Date();
  const from  = new Date(to - ms('30d'));
  return asStrings({ range, from, to });
}


export function year() {
  const range = 'year';
  const to    = new Date();
  const from  = new Date(to - ms('1y'));
  return asStrings({ range, from, to });
}


function asStrings(range) {
  const { from, to } = range;
  return {
    ...range,
    from: asString(from),
    to:   asString(to),
  };
}


export function asString(date) {
  return DateFns.format(date, 'yyyy-MM-dd');
}


export function asDates(range) {
  const { from, to } = range;
  return {
    ...range,
    from: asDate(from),
    to:   asDate(to),
  };
}


export function asDate(string) {
  // <input type="date"> works with dates in yyyy-mm-dd string format. But if we
  // parse datestrings in that format, new Date treats them as ISO strings with
  // a missing timezone, so it parses them in GMT. By replacing dashes with
  // slashes, we can force new Date to parse the date in the local timezone.
  return new Date(string.replace(/-/g, '/'));
}


export function* getMonthIDs(startDate, endDate) {
  let date = startDate;
  yield DateFns.format(date, 'yyyy-MM');
  while (!DateFns.isSameMonth(date, endDate) && DateFns.isBefore(date, endDate)) {
    date = DateFns.addMonths(date, 1);
    yield DateFns.format(date, 'yyyy-MM');
  }
}
