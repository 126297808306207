// TODO: Should this share some code with our useScript hook?
// e.g. use the same cache?
export default async function loadScript(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src   = url;

    script.onload  = () => resolve();
    script.onerror = error => reject(error);

    document.body.appendChild(script);
  });
}
