import { httpStatusCodes } from '../../util/redux_resource_http_status_codes';
import { resourceReducer } from 'redux-resource';
import { upsertResources } from 'redux-resource';


const resources = resourceReducer('business', { plugins: [ httpStatusCodes ] });


export default function reducer(business, action) {
  return reduce(resources(business, action), action);
}


//  Upload list and file upload mapping are handled like other resources,
//  so they're associated with the current business, and can be persisted in
//  session storage.
function reduce(business = null, action) {
  switch (action.type) {
    case 'UPDATE_UPLOAD_LIST': {
      const id           = `${action.businessID}/upload/list`;
      const resource     = { id, records: action.records };
      const newResources = upsertResources(business.resources, [ resource ]);
      return { ...business, resources: newResources };
    }

    case 'UPDATE_FILE_MAPPING': {
      const id           = `${action.businessID}/upload/file`;
      const resource     = { id, headers: action.headers, records: action.records };
      const newResources = upsertResources(business.resources, [ resource ]);
      return { ...business, resources: newResources };
    }

    default:
      return business;
  }
}
