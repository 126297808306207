import { lazy }   from 'react';
import renderPage from '../../components/page';

const DashboardPage       = lazy(async() => import(/* webpackChunkName: 'dashboard' */ './dashboard_page'));
const ReviewsPage         = lazy(async() => import(/* webpackChunkName: 'dashboard' */ './reviews_page'));
const ContactsPage        = lazy(async() => import(/* webpackChunkName: 'dashboard' */ './contacts_page'));
const LeadsPage           = lazy(async() => import(/* webpackChunkName: 'dashboard' */ './leads_page'));
const MessagesPage        = lazy(async() => import(/* webpackChunkName: 'dashboard' */ './messages_page'));
const ReviewsOverTimePage = lazy(async() => import(/* webpackChunkName: 'dashboard' */ './reviews_over_time_page'));
const PaymentsPage        = lazy(async() => import(/* webpackChunkName: 'dashboard' */ './payments_page'));


const tabComponent = {
  name:      'dashboard',
  component: null,
};

export default {
  path:      '/:businessID/dashboard',
  component: renderPage(DashboardPage),
  className: 'dashboard dashboard-page dashboard-colors',
  tabComponent,
  routes:    [
    {
      path:      'reviews',
      component: renderPage(ReviewsPage),
      className: 'dashboard dashboard-reviews-page dashboard-colors',
      tabComponent,
    },
    {
      path:      'contacts',
      component: renderPage(ContactsPage),
      className: 'dashboard dashboard-contacts-page dashboard-colors',
      tabComponent,
    },
    {
      path:      'leads',
      component: renderPage(LeadsPage),
      className: 'dashboard dashboard-leads-page dashboard-colors',
      tabComponent,
    },
    {
      path:      'messages',
      component: renderPage(MessagesPage),
      className: 'dashboard dashboard-messages-page dashboard-colors',
      tabComponent,
    },
    {
      path:      'reviews-over-time',
      component: renderPage(ReviewsOverTimePage),
      className: 'dashboard dashboard-reviews-over-time-page dashboard-colors',
      tabComponent,
    },
    {
      path:      'payments',
      component: renderPage(PaymentsPage),
      className: 'dashboard dashboard-payments-page dashboard-colors',
      tabComponent,
    },
  ],
};
