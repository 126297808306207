import * as BusinessSelectors  from '../../store/business/selectors';
import * as ENV                from '../../util/env';
import { getResources }        from 'redux-resource';
import { getResourceStatuses } from '../../util/get_resource_statuses';
import { getStatus }           from 'redux-resource';

// -- User profile --

export function getProfile(state) {
  return getResources(state.user, 'me')[0];
}

export function getProfileReadStatus(state) {
  return getStatus(state, 'user.requests.me.status');
}

export function getProfileStatuses(state) {
  return getResourceStatuses(state.user, 'profile');
}

export function getUserID(state) {
  const profile = getProfile(state);
  return profile?.userID || null;
}

export function getUserName(state) {
  const profile = getProfile(state);
  return profile?.name || null;
}

export function getFirstName(state) {
  const name  = getUserName(state) || getUserID(state);
  const parts = name.split(' ').map(part => part.trim());
  return parts[0] || null;
}

export function getUserPhone(state) {
  const profile = getProfile(state);
  return profile?.phone || null;
}

export function getUserEmail(state) {
  const profile = getProfile(state);
  return profile?.email || null;
}

export function getLocationIDs(state) {
  const locations   = getLocations({ state });
  const locationIDs = locations?.map(({ id }) => id);
  return locationIDs;
}

export function getNotificationSettings(state) {
  const profile = getProfile(state);
  if (profile)
    return profile?.notifications || null;
  else
    return null;
}

export function getMemberOf(state) {
  const profile = getProfile(state);
  return profile?.memberOf;
}


// -- Roles --

// Maps role name to display name
export const roleDisplayNames = {
  super:   'Account Manager',
  admin:   'Admin',
  manager: 'Manager',
  member:  'Member',
  guest:   'Guest',
};

// roleIncludes['admin'].isManager  => true
// roleIncludes['member'].isManager => false
const roleIncludes = {
  guest:   {},
  member:  {},
  manager: { isManager: true },
  admin:   { isManager: true, isAdmin: true },
  super:   { isManager: true, isAdmin: true, isSuper: true },
};


// Returns role this user has with the current business
export function getRole(state, businessID) {
  const profile    = getProfile(state);
  const business   = BusinessSelectors.getBusiness(state, businessID);
  const membership = profile?.memberOf?.find(item => item?.business?.id === businessID);
  return membership?.role || business?.role || null;
}

// Returns the display name for this role (e.g manager => Manager)
export function getRoleDisplayName(state, businessID) {
  const role        = getRole(state, businessID);
  const displayName = roleDisplayNames[role] || null;
  return displayName;
}

export function isManager(state, businessID) {
  const role       = getRole(state, businessID);
  const privileges = roleIncludes[role];
  return privileges?.isManager || false;
}

export function isAdmin(state, businessID) {
  const role       = getRole(state, businessID);
  const privileges = roleIncludes[role];
  return privileges?.isAdmin || false;
}

export function isSuper(state, businessID) {
  const role       = getRole(state, businessID);
  const privileges = roleIncludes[role];
  return privileges?.isSuper || false;
}

export function isAnonymous(state) {
  const profile = getProfile(state);
  return profile?.anonymous;
}

export function isEmailVerified(state) {
  const profile = getProfile(state);
  return profile?.emailVerified;
}

export function isSignupPending(state) {
  const profile = getProfile(state);
  const intent  = getIntent(state);
  return profile?.signup?.pending || intent === 'signup';
}

// Returns available roles as ordered map: role name -> display label
const availableRoles         = [ 'member', 'manager', 'admin' ];
const availableRolesWithName = availableRoles.reduce((accum, role) => ({ ...accum, [role]: roleDisplayNames[role] }), {});

export function getAvailableRoles() {
  return availableRolesWithName;
}

// Permissions
export function canChangeOnlineProfiles(state, businessID) {
  return isAdmin(state, businessID);
}


// Brands

// this function can accept the whole state, or just profile.memberOf. This
// allows us to use this logic in a hook, memoized against changes to memberOf,
// rather than the entire state
export function getBrands({ state, memberOf = getMemberOf(state) }) {
  return memberOf?.map(m => m.organization)
    .filter(Boolean)
    .sort((a, b) => a.name.localeCompare(b.name));
}

export function hasMultipleBrands(state) {
  const brands = getBrands({ state });
  return !!(brands?.length > 1);
}


// -- Locations --

// this function can accept the whole state, or just profile.memberOf. This
// allows us to use this logic in a hook, memoized against changes to memberOf,
// rather than the entire state
export function getLocations({ state, memberOf = getMemberOf(state) }) {
  return memberOf?.map(m => m.business)
    .filter(Boolean)
    .sort((a, b) => a.name.localeCompare(b.name));
}

export function getLocation(state, businessID) {
  const locations = getLocations({ state });
  return locations?.find(({ id }) => id === businessID);
}

export function getActiveLocations(state) {
  const locations = getLocations({ state });
  return locations?.filter(isLocationStatusActive);
}

export function isLocationActive(state, businessID) {
  const activeLocations = getActiveLocations(state);
  return !!activeLocations?.find(location => location.id === businessID);
}

const activeStatuses = [ 'active', 'test' ];

export function isLocationStatusActive({ status }) {
  return activeStatuses.includes(status);
}


export function getLocationsReadStatus(state) {
  return getStatus(state.user, 'meta[locations].readStatus');
}


// -- Authentication --

// True if the user is most certainly signed in:
// -  They have an access token, and
// -  We loaded their profile from the server
export function isSignedIn(state) {
  const jwtToken = getJWTToken(state);
  // At some point we receive push message to reload the user's profile,
  // while the user is still signed in, their profile exits, but the status
  // changes to pending.
  //
  // Unlike isSignedOut, here we cannot use read status to determine the user's
  // sign-in state.
  const profile = getProfile(state);
  return !!(jwtToken && profile);
}


// True if the user is most certainly signed out: they have no access token.
export function isSignedOut(state) {
  const accessToken = getAccessToken(state);
  return !accessToken;
}


// Used for persisting access token in local storage
export function getAccessToken(state) {
  return state.user?.accessToken;
}


export function getIntent(state) {
  return state.user?.intent;
}


export function getUtmCodes(state) {
  const user = getProfile(state);
  try {
    return JSON.parse(user.signup?.utmCodes);
  } catch (e) {
    return undefined;
  }
}


// Use this to get the JWT token
//
// Returns null if user not authenticated, or token expired
export function getJWTToken(state) {
  const accessToken = getAccessToken(state);
  const jwtToken    = accessToken?.jwtToken;
  const expiresIn   = accessToken?.expiresIn;
  const hasToken    = jwtToken && expiresIn > Date.now();
  return hasToken ? jwtToken : null;
}


export function isSalesforce(state) {
  const token = getAccessToken(state);
  if (token) {
    const { connection } = token;
    return !!connection && connection === 'salesforce';
  } else
    return false;
}

export function isImpersonating(state) {
  if (!isSalesforce(state))
    return false;

  const userID = getUserID(state);
  return userID;
}


export function isPasswordless(state) {
  const profile = getProfile(state);
  if (profile)
    return !profile.email;

  return null;
}


export function getCallSettings(state) {
  const profile             = getProfile(state);
  const userID              = getUserID(state);
  const isSalesforceUser    = isSalesforce(state);
  const isImpersonatingUser = (isSalesforceUser && userID);

  if ((profile && !isSalesforceUser) || (isSalesforceUser && isImpersonatingUser)) {
    return {
      incoming: !!profile.callSettings && profile.callSetting.incoming !== 'none',
      device:   profile.callOption,
    };
  } else
    return null;
}


export function getCanUserMakeCalls(state) {
  const profile            = getProfile(state);
  const callSettings       = getCallSettings(state);
  const device             = callSettings?.device;
  const canCallFromDesktop = !!(device === 'browser' && ENV.isWeb() && ENV.isWebRTCSupported());
  const canCallFromIOS     = ENV.isIOS();
  const canCallFromPhone   = !!(device === 'phone' && profile.phone);
  return (canCallFromDesktop || canCallFromPhone || canCallFromIOS);
}


// Badge counts

export function getBadgeCount(state) {
  const profile     = getProfile(state);
  const memberships = profile?.memberOf?.filter(({ business }) => !!business);
  const number      = (
    memberships?.map(({ unreadCount }) => (unreadCount ? Object.values(unreadCount) : 0))
      .reduce((accum, array) => accum.concat(array), [])
      .reduce((a, b) => a + b, 0)
  );
  return number ?? 0;
}


export function getContactsBadgeCount(state, businessID) {
  const profile    = getProfile(state);
  const membership = profile?.memberOf?.find(({ business }) => business?.id === businessID);
  return membership?.unreadCount?.customers ?? 0;
}


export function canSeeGetStarted(state, businessID) {
  return isAdmin(state, businessID);
}
