import * as UserSelectors              from '../../store/user/selectors';
import { getGoogleLocationIsVerified } from '../../pages/settings/google/display_helpers';
import { getResources }                from 'redux-resource';
import { getResourceStatuses }         from '../../util/get_resource_statuses';
import { getStatus }                   from 'redux-resource';
import { getStatuses }                 from '../../util/get_resource_statuses';
import ms                              from 'ms';

export const webchatInstalledNoticeName = 'webchat:installed';

// -- Business --

export function getBusiness(state, businessID) {
  return getResources(state.business, [ businessID ])[0];
}

export function getBusinessOrganization(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.organization;
}

export function getBusinessName(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.name;
}

export function getBusinessStatus(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.status;
}

export function getBusinessStatuses(state, businessID) {
  return getResourceStatuses(state.business, businessID);
}

export function getThankYouDelay(state, businessID) {
  const settings      = getSettings(state, businessID);
  const thankYouDelay = (settings?.schedule.thankYouDelay) || ms('15m');
  return thankYouDelay;
}

export function getThankYouRepeat(state, businessID) {
  const settings       = getSettings(state, businessID);
  const thankYouRepeat = (settings?.schedule.thankYouRepeat) || ms('60d');
  return thankYouRepeat;
}

export function getReadStatus(state, businessID) {
  return getStatus(state.business, `meta[${businessID}].readStatus`);
}

export function getContact(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.contact;
}

export function getSettings(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.settings;
}

export function getDefaults(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.defaults;
}

const emptyRooms = [];
export function getRooms(state, businessID) {
  const business = getBusiness(state, businessID);
  if (business && Array.isArray(business.rooms))
    return business.rooms;
  else
    return emptyRooms;
}

export function getIntegrationName(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.integration?.name;
}

export function hasNotice(state, businessID, noticeName) {
  const business = getBusiness(state, businessID);
  return business?.notices?.find(({ name }) => name === noticeName);
}

export function getReviewRequestsLimit(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.settings.schedule.thankYou?.limits?.limit;
}

export function hasMaxReviewRequests(state, businessID) {
  const business    = getBusiness(state, businessID);
  const limit       = getReviewRequestsLimit(state, businessID);
  const used        = business?.settings.schedule.thankYou?.limits?.used;
  const hasLimit    = limit > 0; // limit 0 is a valid value and means no limit
  const hasHitLimit = hasLimit && used >= limit;
  return hasHitLimit;
}

export function getCampaignsRecipientsLimit(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.campaigns.limits.limit;
}

export function hasReachedCampaignsRecipientsLimit(state, businessID) {
  const business = getBusiness(state, businessID);
  const limit    = getCampaignsRecipientsLimit(state, businessID);
  const used     = business?.campaigns.limits.used;

  return used >= limit;
}

// -- Providers --

//  Returns an array of providers:
//
//  id      - Provider ID
//  name    - Provider name
export function getProviders(state, businessID) {
  const business = getBusiness(state, businessID);
  if (business && Array.isArray(business.users))
    return business.users.map(({ name, provider }) => ({ id: provider.id, name }));
  else
    return null;
}

export function getUsers(state, businessID) {
  const business = getBusiness(state, businessID);
  if (business && Array.isArray(business.users))
    return business.users;
  else
    return null;
}


// Returns the provider record associated with current user (may be null)
//
// id     — Provide ID
// name   - Provider name
export function getCurrentProvider(state, businessID) {
  const profile = UserSelectors.getProfile(state);

  if (!profile)
    return null;

  const membership = profile.memberOf.find(({ business }) => business?.id === businessID);
  const provider   = membership?.provider;
  if (provider)
    return { ...provider, name: profile.name };
  else
    return null;
}

// -- Facebook Page --

export function getFacebookProfile(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.facebook;
}

export function getFacebookLocationBadgeCount(state, businessID) {
  const canChangeOnlineProfiles = UserSelectors.canChangeOnlineProfiles(state, businessID);
  if (!canChangeOnlineProfiles)
    return 0;

  const business = getBusiness(state, businessID);
  const facebook = business?.facebook;
  if (!facebook)
    return 0;

  const { pageID }    = facebook;
  const { status }    = facebook;
  const { messaging } = facebook;

  const notAuthorized     = status === 403;
  const messagingEnabled  = messaging?.enabled;
  const statusNeedsAction = pageID && (notAuthorized || !messagingEnabled);

  if (statusNeedsAction)
    return 1;
  else
    return 0;
}

// -- Instagram Profile --

export function getInstagramProfile(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.instagram;
}

// -- Google Location --

export function getGMBProfile(state, businessID) {
  const business = getBusiness(state, businessID);

  if (business?.google?.id)
    return business.google;
  else
    return null;
}


export function getIsGMBConnected(state, businessID) {
  const gmbProfile = getGMBProfile(state, businessID);
  return !!gmbProfile;
}


export function getGoogleLocationBadgeCount(state, businessID) {
  const canChangeOnlineProfiles = UserSelectors.canChangeOnlineProfiles(state, businessID);
  if (!canChangeOnlineProfiles)
    return 0;

  const business = getBusiness(state, businessID);
  const google   = business?.google;
  if (!google)
    return 0;

  const { id }        = google;
  const { location }  = google;
  const { status }    = google;
  const { messaging } = google;

  const hasGMBConnection = !!id;
  if (!hasGMBConnection)
    return 1;

  const notAuthorized     = status === 403;
  const notFound          = status === 404;
  const isVerified        = getGoogleLocationIsVerified(location);
  const messagingNotSetup = [ 'ineligible', 'inactive' ].includes(messaging?.status);
  const statusNeedsAction = notAuthorized || notFound || !isVerified || messagingNotSetup;

  if (statusNeedsAction)
    return 1;
  else
    return 0;
}

export function getGoogleProfile(state, businessID) {
  const business = getBusiness(state, businessID);

  if (business?.google?.cid)
    return business.google;
  else
    return null;
}

// --  Yelp page

export function getYelpProfile(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.yelp;
}

// --  Nextdoor page

export function getNextdoorProfile(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.nextdoor;
}

// --  TripAdvisor page

export function getTripAdvisorProfile(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.tripAdvisor;
}

// -- Team --

// Returns information about the current team: users and providers.
export function getTeam(state, businessID) {
  const id = `${businessID}/team`;
  return getResources(state.business, [ id ])[0];
}


export function getTeamReadStatus(state, businessID) {
  const id = `${businessID}/team`;
  return getStatus(state.business, `meta[${id}].readStatus`);
}

export function getTeamStatuses(state, businessID) {
  const id = `${businessID}/team`;
  return getResourceStatuses(state.business, id);
}

// -- Twilio A2P --
export function getTwilioA2PStatus(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.twilioA2P;
}

export function getTwilioA2P(state, businessID) {
  const id = `${businessID}/twilio_a2p`;
  return getResources(state.business, [ id ])[0]?.data;
}

export function getTwilioA2PStatuses(state, businessID) {
  const id = `${businessID}/twilio_a2p`;
  return getResourceStatuses(state.business, id);
}

export function canRegisterForA2P(state, businessID) {
  const connectedPhoneNumber = getConnectedPhoneNumber(state, businessID);
  const hasConnectedNumber   = !!connectedPhoneNumber;
  const verificationStatus   = getVerificationStatus(state, businessID);
  const isVerified           = (verificationStatus === 'verified');
  const hasSmsFeature        = getHasFeature(state, businessID, 'sms');

  return (hasConnectedNumber && isVerified && hasSmsFeature);
}

// -- Data sources --

// Returns information about data sources: implicit and configured.
export function getDataSources(state, businessID) {
  const id = `${businessID}/data_sources`;
  return getResources(state.business, id);
}


export function getDataSourcesReadStatus(state, businessID) {
  const id = `${businessID}/data_sources`;
  return getResourceStatuses(state.business, id);
}


// Returns information about a specific data source
export function getDataSource(state, businessID, sourceName) {
  const id = `${businessID}/data_sources/${sourceName}`;
  return getResources(state.business, [ id ])[0];
}


export function getDataSourceReadStatus(state, businessID, sourceName) {
  const id = `${businessID}/data_sources/${sourceName}`;
  return getResourceStatuses(state.business, id);
}


// -- Upload --
//
export function getCSVMapping(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.csvMapping ?? null;
}


// -- Reviews --

export function getReviews(state, businessID) {
  const id = `${businessID}/reviews`;
  return getResources(state.business, [ id ])[0];
}

export function getReviewsStatuses(state, businessID) {
  const id = `${businessID}/reviews`;

  return getStatuses(state.business, id);
}

// -- Calls --

export function getTwilioNumber(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.settings?.twilio?.number ?? null;
}

export function getHostedNumber(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.settings?.hostedNumber ?? null;
}


export function getConnectedPhoneNumber(state, businessID) {
  const hostedNumber = getHostedNumber(state, businessID);
  if (isHostedNumberComplete(state, businessID))
    return hostedNumber.number;
  else
    return getTwilioNumber(state, businessID);
}

export function isHostedNumberComplete(state, businessID) {
  const hostedNumber = getHostedNumber(state, businessID);
  return !!(hostedNumber?.number && hostedNumber.status === 'completed');
}

// -- Payments --

export function getPaymentsSettings(state, businessID) {
  const business = getBusiness(state, businessID);

  // broadly has enabled the feature for this business?
  const available = business?.features?.payments;

  const tipsEnabled       = business?.settings?.payments?.tips;
  const stripeConnected   = business?.settings?.payments?.mode === 'live';
  const stripeURL         = `https://webapp.broadly.com/stripe/connect/${businessID}`;
  const wisetackConnected = business?.settings?.payments?.wisetack?.setup;
  const fees              = business?.settings?.payments?.fees;

  return {
    available,
    tipsEnabled,
    stripeConnected,
    stripeURL,
    wisetackConnected,
    fees,
  };
}

// -- Classify --

export function getClassifySetting(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.settings?.classify;
}

// -- Web Chat --

export function getWebchatBadgeCount(state, businessID) {
  const business          = getBusiness(state, businessID);
  const hasWebchatFeature = getHasFeature(state, businessID, 'webchat');
  if (hasWebchatFeature)
    return business?.notices?.filter(isWebchatNotice).length ?? 0;
  else
    return 0;
}


function isWebchatNotice(notice) {
  return notice.name.includes('webchat:');
}


export function getSettingsBadgeCount(state, businessID) {
  const googleLocationBadgeCount   = getGoogleLocationBadgeCount(state, businessID);
  const facebookLocationBadgeCount = getFacebookLocationBadgeCount(state, businessID);
  const webchatBadgeCount          = getWebchatBadgeCount(state, businessID);
  return googleLocationBadgeCount + facebookLocationBadgeCount + webchatBadgeCount;
}


export function getBannerImage(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.bannerImageURL;
}

export function getChecklist(state, businessID) {
  const business = getBusiness(state, businessID);
  return business?.checklist;
}

export function getChecklistItem(state, businessID, name) {
  const checklist = getChecklist(state, businessID);
  return checklist?.[name];
}

export function getHasFeature(state, businessID, feature) {
  const business   = getBusiness(state, businessID);
  const features   = business?.features ?? {};
  const hasFeature = features[feature];
  return !!hasFeature;
}

// No UI is restricted unless the API returns business.restricted: true
export function getIsRestricted(state, businessID) {
  const business   = getBusiness(state, businessID);
  const restricted = business?.restricted ?? false;
  return restricted === true;
}

export function getIsFreemium(state, businessID) {
  const business   = getBusiness(state, businessID);
  const isFreemium = !!business?.isFreemium;
  return isFreemium;
}

export function getVerificationStatus(state, businessID) {
  const business     = getBusiness(state, businessID);
  const restricted   = getIsRestricted(state, businessID);
  const legacyStatus = restricted ? 'not-verified' : 'verified';
  return business?.verification?.status ?? legacyStatus;
}
