import { getServiceName } from '../../util/services';
import currency           from 'currency.js';
import getShortName       from '../../util/get_short_name';


const handlers = {
  IncomingMessage:      getMessageText,
  OutgoingMessage:      getMessageText,
  Transaction:          getTransactionText,
  Feedback:             getFeedbackText,
  FollowUpMessage:      getFollowUpMessageText,
  Ask:                  getAskText,
  Review:               getReviewText,
  Call:                 getCallText,
  InvoiceSent:          getInvoiceSentText,
  InvoicePaid:          getInvoicePaidText,
  InvoiceRefunded:      getInvoiceRefundedText,
  QuoteAccepted:        getQuoteAcceptedText,
  QuoteDeclined:        getQuoteDeclinedText,
  QuoteSent:            getQuoteSentText,
  QuoteDepositPaid:     getQuoteDepositPaid,
  QuoteDepositRefunded: getQuoteDepositRefunded,
  ConversationActivity: getConversationActivityText,
  CampaignUnsubscribed: () => 'Contact unsubscribed from text campaigns',
};

const conversationActivityToVerb = {
  assign:   'assigned',
  unassign: 'unassigned',
};

export function getText({ activity, ...params }) {
  const { type } = activity;
  const handler  = handlers[type];

  if (handler)
    return handler({ activity, ...params });
  else
    return null;
}

function getCallText({ activity }) {
  const { call } = activity;
  if (call.direction === 'inbound')
    return 'Incoming Call';
  else
    return 'Outgoing Call';
}


function getConversationActivityText({ activity }) {
  const pastTenseVerb  = conversationActivityToVerb[activity?.conversationActivity?.verb];
  const subjectUserId  = activity?.conversationActivity?.subject?.user?.id;
  const subjectName    = getShortName(activity?.conversationActivity?.subject?.user?.name);
  const objectUserId   = activity?.conversationActivity?.object?.user?.id;
  const objectUserName = getShortName(activity?.conversationActivity?.object?.user?.name);
  const objectName     = objectUserId === subjectUserId ? 'themselves' : objectUserName;

  const textParts = [ subjectName, pastTenseVerb, objectName ];

  return textParts.every(Boolean) ? textParts.join(' ') : null;
}


export function getMessageText({ activity }) {
  const { body, attachments } = activity.message;
  if (body)
    return body;
  else
    return getAttachmentTypeText(attachments);
}

function getAttachmentTypeText(attachments = []) {
  const lastAttachment = attachments[attachments.length - 1];
  const mimeType       = lastAttachment.versions.original.mimeType;
  const isImage        = /image\/.*/.test(mimeType);

  return isImage ? 'Image' : 'Attachment';
}

export function getTransactionText({ activity }) {
  const { providers } = activity.transaction;
  const { provider }  = activity.transaction;
  if (providers?.length)
    return `Service provided by ${providers.map(({ name }) => getShortName(name)).join(', ')}`;
  else if (provider)
    return `Service provided by ${getShortName(provider.name)}`;
  else
    return 'Service provided';
}

export function getFeedbackText({ activity }) {
  const { feedback } = activity;
  const leftFeedback = !!feedback.message && !!feedback.message.body;

  if (leftFeedback)
    return `"${feedback.message.body}"`;

  if (feedback.promoter)
    return 'Would recommend';
  else
    return 'Would not recommend';
}

export function getFollowUpMessageText({ activity }) {
  const { message } = activity;
  const { ask }     = message;

  return getAskTextByType(ask);
}


export function getAskText({ activity }) {
  const { ask }  = activity;
  const isCustom = ask.service === 'custom';
  switch (ask.type) {
    case 'referral':
      return 'Would you share on Facebook?';
    default:
      if (isCustom)
        return 'Contact sent to custom site';
      else
        return `Contact sent to ${getServiceName(ask.service)}`;
  }
}

function getAskTextByType(ask) {
  if (!ask)
    return null;

  switch (ask.type) {
    case 'review': {
      const isCustom = ask.service === 'custom';
      if (isCustom)
        return 'Requested a review';
      else
        return `Requested a review on ${getServiceName(ask.service)}`;
    }
    case 'classify':
      return 'Requested a review';
    case 'referral':
      return 'Would you share on Facebook?';
    case 'serviceReminder':
      return 'Sent a service reminder';
    case 'thankYou':
      return 'Thank you.';
    case 'appointmentReminder':
      return 'Sent appointment reminder';
    default:
      return 'Sent follow-up message';
  }
}


export function getReviewText({ activity }) {
  const { review } = activity;

  return `They wrote a review on ${getServiceName(review.service)}`;
}


export function getQuoteSentText({ activity }) {
  const { quote }  = activity;
  const { amount } = quote;

  return `Sent quote of ${currency(amount).format()}`;
}


export function getQuoteAcceptedText({ activity }) {
  const { quote }  = activity;
  const { amount } = quote;

  return `Accepted quote of ${currency(amount).format()}`;
}


export function getQuoteDeclinedText({ activity }) {
  const { quote }  = activity;
  const { amount } = quote;

  return `Declined quote of ${currency(amount).format()}`;
}


export function getQuoteDepositPaid({ activity }) {
  const { quote }   = activity;
  const { deposit } = quote;

  return `Quote deposit paid of ${currency(deposit.amount).format()}`;
}


export function getQuoteDepositRefunded({ activity }) {
  const { quote }   = activity;
  const { deposit } = quote;

  return `Quote deposit refunded of ${currency(deposit.amount).format()}`;
}


export function getInvoicePaidText({ activity }) {
  const amountPaid = getInvoicePaymentAmount({ activity });
  return `Paid you ${amountPaid}`;
}


export function getInvoiceSentText({ activity }) {
  const balance = getInvoiceBalanceAmount({ activity });
  return `Requested payment of ${balance}`;
}


export function getInvoiceRefundedText({ activity }) {
  const amountRefunded = getInvoiceRefundAmount({ activity });
  return `Refunded a payment of ${amountRefunded}`;
}


export function getInvoicePaidTitle() {
  return 'Completed payment';
}


export function getInvoiceSentTitle() {
  return 'Sent an invoice';
}


export function getInvoiceRefundedTitle() {
  return 'Refunded payment';
}


export function getInvoiceBalanceAmount({ activity }) {
  const { timestamp } = activity;
  const { invoice }   = activity;
  const { payments }  = invoice;
  const { amount }    = invoice;

  const previousPayments = payments?.filter(({ paidAt }) => paidAt < timestamp);
  const alreadyPaid      = previousPayments?.reduce(sumPayments, currency(0));
  const balance          = currency(amount).subtract(alreadyPaid);

  return balance.format();
}


function sumPayments(sum, { amount }) {
  return sum.add(amount);
}


export function getInvoicePaymentAmount({ activity }) {
  const { timestamp }                = activity;
  const { invoice }                  = activity;
  const { payment: activityPayment } = activity;
  const payments                     = invoice?.payments || [];

  if (activityPayment)
    return currency(activityPayment.amount).format();

  // Fallback to old activities
  // Get the payment right before the activity timestamp
  // There is no need to filter by payment status, all payments are computed
  // A paid payment can become refunded later
  const payment = payments
    .sort((a, b) => b.paidAt - a.paidAt)
    .find(p => p.paidAt <= timestamp) || payments[0];

  if (payment)
    return currency(payment.amount).format();
  else
    return null;
}


export function getInvoiceRefundAmount({ activity }) {
  const { timestamp }                = activity;
  const { invoice }                  = activity;
  const { payment: refundedPayment } = activity;
  const payments                     = invoice?.payments || [];

  if (refundedPayment)
    return currency(refundedPayment.amount).format();

  // Fallback to old activities
  // Get the payment right before the activity timestamp
  const refundedPayments = payments
    .filter(p => p.status === 'refunded')
    .sort((a, b) => b.refundedAt - a.refundedAt);

  const payment = refundedPayments.find(p => p.refundedAt <= timestamp) || refundedPayments[0];

  if (payment)
    return currency(payment.amount).format();
  else
    return null;
}
