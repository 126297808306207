import currency from 'currency.js';


export function getInvoiceStatus(invoice) {
  const { status } = invoice;

  if (isPartiallyPaid(invoice))
    return 'partial';
  else
    return status;
}


export function isPartiallyPaid(invoice) {
  const balance       = currency(invoice.balance);
  const amount        = currency(invoice.amount);
  const tip           = currency(invoice.tip);
  const totalToBePaid = amount.add(tip);

  return balance.value > 0 && balance.value < totalToBePaid.value;
}
