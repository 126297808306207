import { httpStatusCodes } from '../../util/redux_resource_http_status_codes';
import { resourceReducer } from 'redux-resource';


const resources = resourceReducer('user', { plugins: [ httpStatusCodes ] });


export default function reducer(user, action) {
  return reduce(resources(user, action), action);
}

function reduce(user = null, action) {
  switch (action.type) {
    case 'ACCESS_TOKEN': {
      const accessToken = action.payload;
      return { ...user, accessToken };
    }

    case 'INTENT': {
      const intent = action.payload;
      return { ...user, intent };
    }

    default:
      return user;
  }
}
