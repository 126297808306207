import * as Business from '../store/business';
import * as User     from '../store/user';
import decodeJWT     from './decode_jwt';
import store         from '../store';


export default function logEvent(eventType, eventProperties = {}) {
  if (shouldLogEvents())
    logPlanhatEvent({ eventType, eventProperties });
}


// Log events for active accounts.
// Also log events before we know the active account (e.g. sign in).
function shouldLogEvents() {
  const state        = store.getState();
  const isSalesforce = User.isSalesforce(state);

  if (isSalesforce)
    return false;

  const business = getCurrentBusiness();

  // No business until after sign in.
  if (business) {
    const isActive = (!business.status || business.status === 'active');
    return isActive;
  } else {
    // Do log all events before sign in.
    return true;
  }
}


function getCurrentBusiness() {
  const state    = store.getState();
  const id       = window.location.pathname.split('/')[1];
  const business = Business.getBusiness(state, id);

  if (business) {
    const name   = Business.getBusinessName(state, id);
    const status = Business.getBusinessStatus(state, id);

    return { id, name, status };
  } else
    return null;
}


function logPlanhatEvent({ eventType: action, eventProperties }) {
  const businessID = window.location.pathname.split('/')[1];
  const state      = store.getState();
  const jwtToken   = User.getJWTToken(state);

  if (businessID && jwtToken) {
    const decoded = decodeJWT(jwtToken);
    const userID  = decoded.sub.replace('auth0|', '');
    const profile = User.getProfile(state);
    const name    = profile?.name;

    const requestOptions = {
      method:  'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action,
        name,
        externalId:        userID,
        companyExternalId: businessID,
        eventProperties,
      }),
      redirect: 'follow',
    };

    const planhatUUID = '33728570-6d67-4ce5-8c80-60607422ce4e';

    window.fetch(`https://analytics.planhat.com/analytics/${planhatUUID}`, requestOptions);
  }
}
