import * as User         from '../../store/user';
import { lazy }          from 'react';
import DataSourcesRoutes from '../data_sources/routes';
import GoogleRoutes      from './google/routes';
import renderPage        from '../../components/page';


const SettingsTab              = lazy(() => import(/* webpackChunkName: 'settings' */ './settings_tab'));
const AppointmentRemindersPage = lazy(() => import(/* webpackChunkName: 'settings' */ './appointment_reminders_page'));
const BusinessProfilePage      = lazy(() => import(/* webpackChunkName: 'settings' */ './business_profile_page'));
const CallsPage                = lazy(() => import(/* webpackChunkName: 'settings' */ './calls_page'));
const WebchatPage              = lazy(() => import(/* webpackChunkName: 'settings' */ './webchat_page'));
const FacebookConnectPage      = lazy(() => import(/* webpackChunkName: 'settings' */ './facebook/facebook_page'));
const InstagramPage            = lazy(() => import(/* webpackChunkName: 'settings' */ './instagram/instagram_page'));
const SmsRegistrationPage      = lazy(() => import(/* webpackChunkName: 'settings' */ './sms_registration_page'));
const HostedNumberPage         = lazy(() => import(/* webpackChunkName: 'settings' */ './hosted_number_page'));
const MessagingPage            = lazy(() => import(/* webpackChunkName: 'settings' */ './messaging_page'));
const ReviewRequestsPage       = lazy(() => import(/* webpackChunkName: 'settings' */ './review_requests_page'));
const NextdoorPage             = lazy(() => import(/* webpackChunkName: 'settings' */ './online_profiles/nextdoor_page'));
const PaymentSettings          = lazy(() => import(/* webpackChunkName: 'settings' */ './payment_settings'));
const RepliesPage              = lazy(() => import(/* webpackChunkName: 'settings' */ './replies_page'));
const ServiceRemindersPage     = lazy(() => import(/* webpackChunkName: 'settings' */ './service_reminders_page'));
const TripAdvisorPage          = lazy(() => import(/* webpackChunkName: 'settings' */ './online_profiles/tripadvisor_page'));
const YelpPage                 = lazy(() => import(/* webpackChunkName: 'settings' */ './online_profiles/yelp_page'));


const tabComponent = {
  component: renderPage(SettingsTab),
  className: 'settings-tab',
  name:      'settings',
};

export default {
  name:      'Settings',
  path:      '/:businessID/settings',
  component: renderPage(SettingsTab, { tabPage: true }),
  className: 'settings-tab',
  tabComponent,
  routes:    [
    {
      path:      'business_profile',
      name:      'BusinessProfile',
      component: renderPage(BusinessProfilePage),
      className: 'business-profile-page',
      tabComponent,
      routes:    [
        GoogleRoutes({ tabComponent }),
        {
          name:      'FacebookPage',
          path:      'facebook',
          component: renderPage(FacebookConnectPage),
          className: 'facebook-page',
          tabComponent,
        },
        {
          name:      'InstagramPage',
          path:      'instagram',
          component: renderPage(InstagramPage),
          className: 'instagram-page',
          tabComponent,
        },
        {
          name:      'NextdoorPage',
          path:      'nextdoor',
          component: renderPage(NextdoorPage),
          className: 'online-profile-page',
          tabComponent,
        },
        {
          name:      'TripAdvisorPage',
          path:      'tripadvisor',
          component: renderPage(TripAdvisorPage),
          className: 'online-profile-page',
          tabComponent,
        },
        {
          name:      'YelpPage',
          path:      'yelp',
          component: renderPage(YelpPage),
          className: 'online-profile-page',
          tabComponent,
        },
      ],
    },
    {
      path:     'team',
      redirect: (route, resolve) => {
        const { businessID } = route.params;
        resolve(`/${businessID}/team`);
      },
      routes: [
        {
          path:     ':userID',
          redirect: (route, resolve) => {
            const { businessID } = route.params;
            const { userID }     = route.params;
            resolve(`/${businessID}/team/manage/${userID}`);
          },
        },
      ],
    },
    {
      path:     'invite',
      redirect: (route, resolve) => {
        const { businessID } = route.params;
        resolve(`/${businessID}/team/invite`);
      },
      routes: [
        {
          path:     ':providerID',
          redirect: (route, resolve) => {
            const { businessID } = route.params;
            const { providerID } = route.params;
            resolve(`/${businessID}/team/invite/${providerID}`);
          },
        },
      ],
    },
    {
      path:      'review_requests',
      name:      'SettingsReviewRequests',
      component: renderPage(ReviewRequestsPage),
      className: 'review-requests-page',
      allow:     User.isAdmin,
      tabComponent,
    },
    {
      path:      'messaging',
      name:      'SettingsMessaging',
      component: renderPage(MessagingPage),
      className: 'messaging-page',
      allow:     User.isAdmin,
      tabComponent,
      routes:    [
        {
          path:      'sms_registration',
          name:      'SmsRegistration',
          component: renderPage(SmsRegistrationPage),
          className: 'sms-registration-page',
          allow:     User.isAdmin,
          tabComponent,
        },
        {
          path:      'hosted_number',
          name:      'HostedNumber',
          component: renderPage(HostedNumberPage),
          className: 'hosted-number-page',
          allow:     User.isAdmin,
          tabComponent,
        },
      ],
    },
    {
      path:      'replies',
      name:      'SavedReplies',
      component: renderPage(RepliesPage),
      className: 'replies-page',
      tabComponent,
    },
    {
      path:      'calls',
      name:      'SettingsCalls',
      component: renderPage(CallsPage),
      className: 'calls-page',
      tabComponent,
    },
    {
      path:      'webchat',
      name:      'WebChat',
      component: renderPage(WebchatPage),
      className: 'webchat-page',
      allow:     User.isManager,
      tabComponent,
    },
    {
      path:     'invoices',
      redirect: (route, resolve) => {
        const { businessID } = route.params;
        resolve(`/${businessID}/payments`);
      },
    },
    DataSourcesRoutes,
    {
      path:      'appointments',
      name:      'Appointments',
      component: renderPage(AppointmentRemindersPage),
      className: 'appointments-page',
      tabComponent,
    },
    {
      path:      'service_reminders',
      name:      'ServiceReminders',
      component: renderPage(ServiceRemindersPage),
      className: 'service-reminders-page',
      tabComponent,
    },
    {
      path:      'payments',
      name:      'PaymentSettings',
      component: renderPage(PaymentSettings),
      className: 'payments-settings-page',
      allow:     User.isAdmin,
      tabComponent,
    },
  ],
};
