// Make HTTP status code to the specific message we can show the user.
const errorMessages = {
  500:     'Oops. Something went wrong. Please try again.',
  400:     'Sorry, this request could not be completed. Please try again later or contact support.',
  401:     'Sorry, you need to sign in again.',
  403:     'Sorry, you are not allowed to perform this operation.',
  offline: 'No network connection, please try again later.',
};

export default function getFriendlyErrorMessage(error) {
  const prohibitedContentError = error.body?.[0]?.name === 'ProhibitedContentError';

  if (window.navigator?.onLine === false)
    return errorMessages.offline;
  else if (prohibitedContentError)
    return 'We’re sorry, but the content you provided contains language that violates our community guidelines';
  else if (error.body?.message === 'Validation Failed')
    return Object.values(error.body.errors || {}).join(', ') || errorMessages[error.statusCode] || errorMessages[500];
  else
    return error.body?.message || errorMessages[error.statusCode] || errorMessages[500];
}
