import { lazy }   from 'react';
import renderPage from '../../components/page';

const ActivityPage  = lazy(() => import(/* webpackChunkName: 'campaigns' */ './activity_page'));
const AddPage       = lazy(() => import(/* webpackChunkName: 'campaigns' */ './add_page'));
const CampaignsTab  = lazy(() => import(/* webpackChunkName: 'campaigns' */ './campaigns_tab'));
const EditPage      = lazy(() => import(/* webpackChunkName: 'campaigns' */ './edit_page'));
const StatusPage    = lazy(() => import(/* webpackChunkName: 'campaigns' */ './status/status_page'));
const TemplatesPage = lazy(() => import(/* webpackChunkName: 'campaigns' */ './templates_page'));


const tabComponent = {
  component: renderPage(CampaignsTab),
  className: 'campaigns-tab',
  name:      'campaigns',
};

export default {
  name:      'EmailCampaigns',
  path:      '/:businessID/campaigns',
  component: renderPage(CampaignsTab, { tabPage: true }),
  className: 'campaigns-tab',
  tabComponent,
  routes:    [
    {
      name:      'AddCampaignPage',
      path:      'add',
      component: renderPage(AddPage),
      className: 'compose-campaign-page',
      tabComponent,
    },
    {
      name:      'EditCampaignPage',
      path:      'edit/:campaignID',
      component: renderPage(EditPage),
      className: 'compose-campaign-page',
      tabComponent,
    },
    {
      name:      'TemplatesPage',
      path:      'templates',
      component: renderPage(TemplatesPage),
      className: 'templates-page',
      tabComponent,
    },
    {
      name:      'ActivityPage',
      path:      'activity',
      component: renderPage(ActivityPage),
      className: 'activity-page',
      tabComponent,
    },
    {
      name:      'StatusPage',
      path:      ':campaignID',
      component: renderPage(StatusPage),
      className: 'status-page',
      tabComponent,
    },
  ],
};
