import './refer_business_promo_card.less';

import * as UI          from '../store/ui';
import { ChevronRight } from 'react-feather';
import Button           from '../components/button';
import React            from 'react';

export default function ReferBusinessPromoCard({ href }) {
  return (
    <Button href={href} role="tab" onClick={UI.closeMenu} className="refer-business-promo-card">
      <div className="refer-business-title">
        Earn $500
      </div>
      <div className="refer-business-description">
        Refer a new business to Broadly <ChevronRight className="right-arrow"/>
      </div>
    </Button>
  );
}
