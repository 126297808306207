import * as Auth     from '../../auth';
import * as Business from '../../store/business/actions';
import * as User     from '../../store/user';
import store         from '../../store';


export default {
  path:   '/qbo',
  routes: [
    {
      // Users get redirected to this route after our app has been disconnected
      // from their QBO company. They will be signed in with Intuit
      // automatically, but without having to connect to a QBO company again.
      path:   'disconnect',
      public: true,
      async redirect(route, _, reject) {
        const hasAccessToken = !!User.getAccessToken(store.getState());
        if (hasAccessToken)
          await store.dispatch(User.signOut({ returnTo: '/qbo/disconnect' }));
        else
          Auth.authorize({ returnTo: '/qbo/reconnect', intent: 'intuit-qbodisconnect' });
        reject();
      },
    },
    {
      // After disconnecting their QBO company and signing in with Intuit again
      // successfully, users will be redirected to the QBO settings page to
      // reconnect their QBO company as needed.
      path:   'reconnect',
      public: true,
      async redirect(route, resolve, reject) {
        const user     = User.getProfile(store.getState());
        const business = user?.memberOf?.[0]?.business;
        if (business) {
          const businessID = business.id;
          await store.dispatch(Business.deleteDataSource({ businessID, name: 'qbo' }));
          resolve(`/${businessID}/settings/data_sources/qbo`);
        } else
          reject();
      },
    },
  ],
};
