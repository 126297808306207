// Exports the F7 app object
import '../styles/index.less';

import * as ENV                      from '../util/env';
import { AlertMonitor }              from '../components/alert_modal';
import { Provider as StoreProvider } from 'react-redux';
import A2PModalMonitor               from '../components/a2p_modal';
import AccessToken                   from './access_token';
import AppErrorBoundary              from '../components/app_error_boundary';
import AppLayout                     from './app_layout';
import AppProvider                   from './app_provider';
import AppStart                      from './app_start';
import CallBar                       from '../call';
import DeepLinks                     from './deep_links';
import GTM                           from '../components/gtm';
import ImpersonateBar                from '../components/impersonate_bar';
import Keyboard                      from '../components/keyboard';
import Notification                  from '../components/notification';
import OfflineIndicator              from '../components/offline_indicator';
import PostHog                       from '../components/posthog';
import ProgressIndicator             from '../components/progress_indicator';
import Puppeteer                     from '../components/puppeteer';
import Push                          from '../push/push';
import React                         from 'react';
import Statusbar                     from '../components/statusbar';
import store                         from '../store';
import Toast                         from '../components/toast';
import WindowTitle                   from '../components/window_title';


const isPuppeteer = ENV.isPuppeteer();

export default function BroadlyApp() {
  return (
    <AppErrorBoundary>
      <StoreProvider store={store}>
        <AppProvider>
          {isPuppeteer && <Puppeteer/>}
          <AccessToken/>
          <DeepLinks/>
          <GTM/>
          <Keyboard/>
          <AppStart>
            <AppContent/>
          </AppStart>
        </AppProvider>
      </StoreProvider>
    </AppErrorBoundary>
  );
}


function AppContent() {
  return (
    <PostHog>
      <Push/>
      <ImpersonateBar/>
      <CallBar/>
      <AppLayout/>
      <WindowTitle/>
      <ProgressIndicator/>
      <Notification/>
      <AlertMonitor/>
      <A2PModalMonitor/>
      <Toast/>
      <OfflineIndicator/>
      <Statusbar/>
    </PostHog>
  );
}
