import { useIsSplitView } from '../hooks/use_media_query';
import Menu               from '../menu/menu';
import React              from 'react';
import useCurrentRoute    from '../hooks/use_current_route';
import useRouter          from '../hooks/use_router';


export default function RouteTab() {
  const router       = useRouter();
  const route        = useCurrentRoute();
  const tabComponent = route?.route?.tabComponent;

  if (router && route && tabComponent)
    return <RouteTabPanel {...{ router, route, tabComponent }}/>;
  else
    return null;
}


function RouteTabPanel({ router, route, tabComponent }) {
  const { component: CurrentTab, ...routeProps } = tabComponent;

  const isSplitView = useIsSplitView();

  return (
    <>
      <Menu tab={tabComponent.name}/>
      {CurrentTab && isSplitView && (
        <div className="tab-panel">
          <CurrentTab {...{ router, route, routeProps, ...route.params }}/>
        </div>
      )}
    </>
  );
}
