import * as Auth      from '../../../auth';
import * as Profile   from './profile';
import * as Resources from '../../../util/resources';
import * as Selectors from '../selectors';
import * as UI        from '../../../store/ui';
import bail           from '../../../util/bail';


// Sign in with an access token.
export function signIn({ accessToken, intent }) {
  return async function(dispatch) {
    dispatch({ type: 'RESET' });
    dispatch(setAccessToken(accessToken));
    dispatch(setIntent(intent));
    await dispatch(Profile.loadUserProfile());
  };
}


// Use this to sign user out of the app on this device.
export function signOut({ returnTo } = {}) {
  return async function(dispatch, getState) {
    const token = Selectors.getAccessToken(getState());
    if (token) {
      const deviceToken = token.jwtToken;

      await dispatch(removeDevice(deviceToken));

      // We have code watching for isSignedOut, give it a chance to run
      // (unsubscribe from push messages, flush analytics/errors, etc)
      // before bailing which will reload the window.
      setTimeout(function() {
        bail();
        Auth.logout({ returnTo }).catch(console.error);
      }, 200);
    }
  };
}

function removeDevice(deviceToken) {
  return async function(dispatch) {
    try {
      await dispatch(Resources.request({
        method: 'delete',
        url:    `${API_URL}/me/device_token`,
        body:   { deviceToken },
      }));
    } catch (error) {
      // ignore errors when trying to remove device token
    } finally {
      dispatch(setAccessToken(null));
    }
  };
}


export function setAccessToken(accessToken) {
  return { type: 'ACCESS_TOKEN', payload: accessToken };
}

export function setIntent(intent) {
  return { type: 'INTENT', payload: intent };
}

// Refresh access token
export function refreshToken() {
  return async function(dispatch, getState) {
    const token = Selectors.getAccessToken(getState());

    if (token?.refreshToken) {
      const accessToken = await Auth.refreshToken(token);

      if (accessToken?.revoked)
        dispatch(setAccessToken(null));
      else if (accessToken)
        dispatch(setAccessToken(accessToken));
    }
  };
}


// Sign in form uses this to authenticate.
//
// Dispatch action and wait for it to complete successfully (user signed in), or
// throw an error.  Error would include status code.
export function authenticate({ username, password }) {
  return async function(dispatch) {
    try {
      dispatch(UI.progressStart());
      const accessToken = await Auth.authenticate({ username, password });
      await dispatch(signIn({ accessToken }));
    } finally {
      dispatch(UI.progressEnd());
    }
  };
}


export function passwordless(phone) {
  return async function(dispatch) {
    dispatch(UI.progressStart());

    try {
      await Auth.passwordless(phone);
    } catch (error) {
      dispatch(UI.progressEnd());
      throw new Error('Oops. Something went wrong. Please try again.');
    }

    dispatch(UI.progressEnd());
  };
}
