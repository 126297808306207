import * as Business                  from '../store/business';
import * as ENV                       from '../util/env';
import * as User                      from '../store/user';
import { MenuLink }                   from './menu_link';
import { MenuLinks }                  from './menu_link';
import HasFeature                     from '../components/has_feature';
import React                          from 'react';

import { Send as CampaignsIcon }      from 'react-feather';
import { BookOpen as ContactsIcon }   from 'react-feather';
import { PieChart as DashboardIcon }  from 'react-feather';
import { Inbox as InboxIcon }         from 'react-feather';
import { CreditCard as PaymentsIcon } from 'react-feather';
import { ThumbsUp as ReviewsIcon }    from 'react-feather';
import { Settings as SettingsIcon }   from 'react-feather';
import { Users as TeamIcon }          from 'react-feather';
import AddLocationIcon                from '../assets/icons/add_location_icon.svg';
import ReferBusinessPromoCard         from './refer_business_promo_card';
import SparkleIcon                    from '../assets/icons/sparkle.svg';


export default function LocationLinks({ businessID, tab, isFreemium }) {
  const business = Business.useBusiness(businessID);
  if (business)
    return <LocationsLinksContent {...{ businessID, tab, isFreemium }}/>;
  else
    return null;
}


function LocationsLinksContent({ businessID, tab, isFreemium }) {
  const canSeeGetStarted       = User.useCanSeeGetStarted(businessID);
  const contactsBadgeCount     = User.useContactsBadgeCount(businessID);
  const isHostedNumberComplete = Business.useIsHostedNumberComplete(businessID);
  const paymentsSettings       = Business.usePaymentsSettings(businessID);
  const paymentsAvailable      = paymentsSettings?.available;
  const isManager              = User.useIsManager(businessID);
  const showPreferences        = isManager || !isManager && isHostedNumberComplete && ENV.isWeb();
  const showPayments           = !!paymentsAvailable;
  const showCampaigns          = !!isManager;
  const showAddLocation        = !isFreemium;
  const checklistCount         = Business.useCheckListCount(businessID);

  // using local state so showGetStarted remains true for the duration of the
  // session
  const [ showGetStarted ] = React.useState(canSeeGetStarted && checklistCount > 0);

  return (
    <MenuLinks>
      {showGetStarted && (
        <MenuLink
          icon={<SparkleIcon/>}
          label="Get Started"
          active={tab === 'getstarted'}
          href={getURL(businessID, 'getstarted')}
          badgeCount={checklistCount}
        />
      )}
      <MenuLink
        icon={<DashboardIcon/>}
        label="Dashboard"
        active={tab === 'dashboard'}
        href={getURL(businessID, 'dashboard')}
      />
      <MenuLink
        icon={<InboxIcon/>}
        badgeCount={contactsBadgeCount}
        label="Inbox"
        active={tab === 'inbox'}
        href={getURL(businessID, 'inbox')}
      />
      <MenuLink
        icon={<ContactsIcon/>}
        label="Contacts"
        active={tab === 'contacts'}
        href={getURL(businessID, 'contacts')}
      />
      {showCampaigns && (
        <HasFeature businessID={businessID} feature="campaigns">
          <MenuLink
            icon={<CampaignsIcon/>}
            label="Campaigns"
            active={tab === 'campaigns'}
            href={getURL(businessID, 'campaigns')}
            betaBadge={true}
          />
        </HasFeature>
      )}
      <MenuLink
        icon={<TeamIcon/>}
        label="Team"
        active={tab === 'team'}
        href={getURL(businessID, 'team')}
      />
      <MenuLink
        icon={<ReviewsIcon/>}
        label="Reviews"
        active={tab === 'reviews'}
        href={getURL(businessID, 'reviews')}
      />
      {showPayments && (
        <MenuLink
          icon={<PaymentsIcon/>}
          label="Quotes & Invoices"
          active={tab === 'payments'}
          href={getURL(businessID, 'payments')}
        />
      )}
      {showPreferences && (
        <MenuLink
          icon={<SettingsIcon/>}
          label="Settings"
          active={tab === 'settings'}
          href={getURL(businessID, 'settings')}
        />
      )}
      {showAddLocation && (
        <MenuLink
          icon={<AddLocationIcon/>}
          label="Add a new location"
          active={tab === 'addLocation'}
          href={getURL(businessID, 'add-location')}
        />
      )}
      <ReferBusinessPromoCard href={(getURL(businessID, 'referrals'))}/>
    </MenuLinks>
  );
}


export function getURL(businessID, path) {
  return `/${businessID}/${path}`;
}
