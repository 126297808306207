import * as Actions        from './actions';
import * as Selectors      from './selectors';
import { compose }         from 'redux';
import { useDispatch }     from 'react-redux';
import { useResourceList } from '../../util/webapp';
import { useSelector }     from 'react-redux';
import addSearchFields     from './add_search_fields';
import React               from 'react';


// Contacts Hooks


export function useContactList(businessID) {
  const { data, ...swr } = useResourceList(`/business/${businessID}/contacts`, {
    name:            'contacts',
    sizeOfFirstPage: 15000,
    sizeOfNthPage:   5000,
  });

  const dataWithSearchFields = React.useMemo(() => {
    return data?.map(addSearchFields);
  }, [ data ]);

  return { data: dataWithSearchFields, ...swr };
}


// Selectors

export function useContacts(businessID, filter) {
  return useSelector(state => Selectors.getContacts(state, businessID, filter));
}

export function useEmailContacts(businessID) {
  return useSelector(state => Selectors.getEmailContacts(state, businessID));
}

export function useContactsStatuses(businessID) {
  return useSelector(state => Selectors.getContactsStatuses(state, businessID));
}

export function useContact(contactID) {
  return useSelector(state => Selectors.getContact(state, contactID));
}

export function useContactStatuses(contactID) {
  return useSelector(state => Selectors.getContactStatuses(state, contactID));
}

export function useLeadsAddedDaysAgo(businessID, daysAgo) {
  return useSelector(state => Selectors.getLeadsAddedDaysAgo(state, businessID, daysAgo));
}


// Actions

export function useReadContacts(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.readContacts, () => ({ businessID })),
    [ dispatch, businessID ],
  );
}

export function useReadContact() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.readContact), [ dispatch ]);
}

export function useCreateContact() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.createContact), [ dispatch ]);
}

export function useUpdateContact() {
  return compose(useDispatch(), Actions.updateContact);
}

export function useUpdateContactFollowUpStatus() {
  return compose(useDispatch(), Actions.updateContactFollowUpStatus);
}

export function useUpdateContactBlockStatus() {
  return compose(useDispatch(), Actions.updateContactBlockStatus);
}

export function useUpdateContactPreferredChannel() {
  return compose(useDispatch(), Actions.updateContactPreferredChannel);
}

export function useDeleteContact() {
  return compose(useDispatch(), Actions.deleteContact);
}

export function useGetIntegrationUrl() {
  return compose(useDispatch(), Actions.getIntegrationURL);
}

export function useSendThankYouMessage() {
  return compose(useDispatch(), Actions.sendThankYouMessage);
}
