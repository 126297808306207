import * as Auth from '../auth';
import React     from 'react';
import useRouter from '../hooks/use_router';


// https://github.com/ionic-team/ionic-plugin-deeplinks

export default function DeepLinks() {
  if (window?.IonicDeeplink)
    return <IonicDeeplinks/>;
  else
    return null;
}

const loginRegex                 = /login\.broadly\.com/;
const routerNavigateErrorMessage = 'Framework7: it is not allowed to use router methods on global app router. Use router methods only on related View, e.g. app.views.main.router.navigate(...)';

function IonicDeeplinks() {
  const router                    = useRouter();
  const [ deepLink, setDeepLink ] = React.useState(null);

  React.useEffect(function() {
    window.IonicDeeplink.onDeepLink(function(data) {
      // don't think we need to parse out the hash anymore
      const url = new URL(data.url.replace('/#/', '/'));
      setDeepLink(url);
    });
  }, []);

  React.useEffect(function() {
    if (loginRegex.test(deepLink?.href))
      Auth.onDeepLink(deepLink);
    else if (router && deepLink) {
      try {
        router.navigate(getPathname(deepLink.pathname), { animate: false });
      } catch (e) {
        const errorMessage = e.message;
        if (errorMessage !== routerNavigateErrorMessage)
          throw e;
      }
    }
  }, [ router, deepLink ]);

  return null;
}

function getPathname(pathname) {
  // Android parses deeplinks like
  // "broadly://app.broadly.com/5f1f5c03a6f9720041510e43/inbox" as
  // "https://localhost//app.broadly.com/5f1f5c03a6f9720041510e43/inbox"
  // so we need to remove the first param if it matches that pattern
  return pathname.replace(/^\/\/app\.broadly\.com/, '');
}
