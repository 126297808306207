import './opt_in_banner.less';

import Button             from '../components/button';
import React              from 'react';
import Row                from '../components/row';

import { X as CloseIcon } from 'react-feather';

//  pop-up fatigue.
//  90% of Chrome permission requests are ignored.
//    -- https://twitter.com/lukew/status/922523903808770048
//
//  We have two issues here. A lot of people don't want to take action on the
//  permission request until they feel more comfortable in making a decision.
//  The browser's own permission UI is rather intrusive, and so they're
//  pressured into making a decision, good chance they'll deny the request.
//
//  Once denied, it's very hard to "un-deny". User won't be asked again, and
//  good luck finding the UI toggle.
//
//  Instead, we're going to show a banner at the top of the screen.  We can
//  style it to feel less intrusive.  And include a close button, so people can
//  dimiss it until they're ready to make a decision (temporarily, but as often
//  as they want).
//
//  Only when user clicks on the banner with the intent to enable desktop
//  notifications, do we have the browser ask for permission again.
//
//  This double opt-in to desktop notification UX pattern borrowed from Slack.


export default function OptInBanner({ optIn, optOut }) {
  return (
    <div className="app-banner">
      <Row center className="opt-in-banner">
        <div className="opt-in-message">
          Broadly would like your permission to <Button className="accept-button" onClick={optIn} label="enable notifications"/>.
        </div>
        <Button className="close-button" onClick={optOut} label="Close" renderNestedContent={false}>
          <div className="close-button-circle"><CloseIcon/></div>
        </Button>
      </Row>
    </div>
  );
}
