import * as Businesses    from '../store/business';
import * as Contacts      from '../store/contacts';
import * as UI            from '../store/ui';
import * as User          from '../store/user';
import { connect }        from 'react-redux';
import { Device }         from 'twilio-client';
import { getAccessToken } from './token';
import CallBar            from './call_bar';
import ms                 from 'ms';
import onChange           from '../util/on_change';
import React              from 'react';
import rollbar            from '../rollbar';
import store              from '../store';


let customerOnCall;
let businessOnCall;


class WebCallBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.device                = null;
    this.isSettingDevice       = false;
    this.deviceUpdater         = null;
    this.connection            = null;
    this.state                 = WebCallBar.defaultState;
    this.bindUpdateOfDevice    = this.bindUpdateOfDevice.bind(this);
    this.unbindUpdateOfDevice  = this.unbindUpdateOfDevice.bind(this);
    this.handleOnConnection    = this.handleOnConnection.bind(this);
    this.handleOnDisconnect    = this.handleOnDisconnect.bind(this);
    this.handleIncoming        = this.handleIncoming.bind(this);
    this.handleOnFinish        = this.handleOnFinish.bind(this);
    this.handleOnMute          = this.handleOnMute.bind(this);
    this.handleOnDecline       = this.handleOnDecline.bind(this);
    this.handleOnAccept        = this.handleOnAccept.bind(this);
    this.handleConnectingCall  = this.handleConnectingCall.bind(this);
    this.connectDeviceIfNeeded = this.connectDeviceIfNeeded.bind(this);
  }

  async componentDidMount() {
    const { device: deviceToUse } = this.props.callSettings;
    const useBrowserDevice        = deviceToUse === 'browser';

    if (useBrowserDevice)
      await this.connectDeviceIfNeeded();
    else
      document.addEventListener('connectingCall', this.handleConnectingCall);
  }

  async componentDidUpdate() {
    const { device: deviceToUse } = this.props.callSettings;
    const useBrowserDevice        = deviceToUse === 'browser';
    if (useBrowserDevice)
      await this.connectDeviceIfNeeded();
  }

  async connectDeviceIfNeeded() {
    if (!this.isSettingDevice) {
      this.isSettingDevice = true;
      this.device          = await getDevice();
      this.bindUpdateOfDevice();

      this.device.on('connect', this.handleOnConnection);
      this.device.on('cancel', this.handleOnDisconnect);
      this.device.on('disconnect', this.handleOnDisconnect);
      this.device.on('incoming', this.handleIncoming);
    }
  }

  bindUpdateOfDevice() {
    this.deviceUpdater = setInterval(() => {
      if (this.state.status === 'waiting')
        updateToken().catch(rollbar.error);
    }, ms('5min'));
  }

  handleOnConnection(connection) {
    this.connection = connection;

    this.connection.on('error', error => {
      rollbar.error('Twilio call connection error', error);
    });
    this.setState({
      status:    'onCall',
      type:      this.state.type || 'outgoing',
      startedAt: new Date(),
      customer:  this.state.customer || customerOnCall,
      business:  this.state.business || businessOnCall,
    });
    customerOnCall = null;
    businessOnCall = null;
  }

  handleOnDisconnect() {
    this.connection = null;
    if (this.state.customer && this.state.business)
      this.props.readContact({ customerID: this.state.customer.id, businessID: this.state.business.id });

    this.setState({ ...WebCallBar.defaultState });
  }

  async handleIncoming(connection) {
    const { status } = this.state;
    if (status !== 'waiting')
      return;

    this.connection         = connection;
    const businessID        = connection.customParameters.get('businessID');
    const customerID        = connection.customParameters.get('customerID');
    const isKnownCustomer   = businessID && customerID;
    const callerPhoneNumber = connection.parameters.From;
    const { readContact }   = this.props;
    const { readBusiness }  = this.props;

    if (isKnownCustomer) {
      const { getBusiness } = this.props;
      const { getContact }  = this.props;
      const business        = getBusiness(businessID);
      const customer        = getContact(customerID);

      const needToLoadData = !business && !customer;
      if (needToLoadData) {
        await readBusiness({ businessID });
        await readContact({ businessID, customerID });
      }
    }

    this.setState({
      type:   'incoming',
      status: 'ringing',
      isKnownCustomer,
      callerPhoneNumber,
      businessID,
      customerID,
    });
  }

  handleConnectingCall({ detail }) {
    const { customer } = detail;
    const { business } = detail;
    const startedAt    = new Date();

    this.setState({
      type:   'connectingCall',
      status: 'onCall',
      startedAt,
      customer,
      business,
    });

    onChange(store, state => Contacts.getContact(state, customer.id), (old, customerWithNewActivity, unsubscribe) => {
      if (!customerWithNewActivity)
        return;

      const lastActivity = customerWithNewActivity
        .activities
        .filter(({ type }) => type === 'Call')
        .pop();

      if (!lastActivity)
        return;

      const lastCallActivity = lastActivity.call;
      const isInProgress     = lastCallActivity.status === 'in-progress';
      if (isInProgress)
        return;

      const isStatusNewThanStartedAt = new Date(lastCallActivity.startedAt).getTime() > startedAt.getTime();
      if (isStatusNewThanStartedAt) {
        this.setState({
          ...WebCallBar.defaultState,
        });
        unsubscribe();
      }
    });
  }

  componentWillUnmount() {
    this.unbindUpdateOfDevice();
    this.unbindConnectingCall();
  }

  unbindUpdateOfDevice() {
    clearInterval(this.deviceUpdater);
  }

  unbindConnectingCall() {
    document.removeEventListener('connectingCall', this.handleConnectingCall);
  }

  handleOnFinish() {
    this.connection.disconnect();
  }

  handleOnMute() {
    const { isMuted } = this.state;
    const mute        = !isMuted;

    this.connection.mute(mute);
    this.setState({ isMuted: mute });
  }

  handleOnAccept({ business, customer }) {
    this.connection.accept();

    this.setState({
      status:    'onCall',
      startedAt: new Date(),
      business,
      customer,
    });
  }

  handleOnDecline() {
    this.connection.reject();
    this.connection = null;
    this.setState({
      ...WebCallBar.defaultState,
    });
  }

  render() {
    const { status } = this.state;
    if (status === 'waiting')
      return null;

    const { customer }          = this.state;
    const { business }          = this.state;
    const { startedAt }         = this.state;
    const { isMuted }           = this.state;
    const { callerPhoneNumber } = this.state;
    const { type }              = this.state;

    const callBarProps = {
      type,
      status,
      customer,
      business,
      startedAt,
      isMuted,
      callerPhoneNumber,
      handleOnFinish:  this.handleOnFinish,
      handleOnMute:    this.handleOnMute,
      handleOnAccept:  this.handleOnAccept,
      handleOnDecline: this.handleOnDecline,
    };

    return (
      <CallBar {...callBarProps}/>
    );
  }
}

WebCallBar.defaultState = {
  status:          'waiting', // ['waiting', 'onCall', 'ringing']
  type:            null, // ['incoming', 'outgoing']
  customer:        null,
  startedAt:       null,
  isMuted:         false,
  isKnownCustomer: false, // When is a incoming call we check if we know the customer
};


export async function callCustomer({ business, customer }) {
  store.dispatch(UI.progressStart());

  try {
    const { device: deviceToUse } = User.getCallSettings(store.getState());

    if (deviceToUse === 'phone') {
      const customerID = customer.id;

      await store.dispatch(Contacts.makeConnectingCall({ customerID, businessID: business.id }));
      const connectingCallEvent = new window.CustomEvent(
        'connectingCall',
        {
          detail:  { customer, business },
          bubbles: true,
        },
      );
      document.dispatchEvent(connectingCallEvent);
    } else {
      const device   = await getDevice();
      customerOnCall = customer;
      businessOnCall = business;

      await connectDeviceToCustomer({ device, customer });
    }
  } catch (error) {
    if (error.statusCode === 400) {
      // Typically just one error.
      const messages = error.body.errors.map(({ message }) => message).join(', ');
      UI.toast(messages);
    } else
      throw error;
  } finally {
    store.dispatch(UI.progressEnd());
  }
}

async function connectDeviceToCustomer({ device, customer }) {
  const connection = device.connect({ customerID: customer.id });
  return new Promise(resolve => device.on('connect', () => resolve(connection)));
}


export default connect(
  state => ({
    isSalesforce: User.isSalesforce(state),
    isSignedIn:   User.isSignedIn(state),
    callSettings: User.getCallSettings(state),
    getBusiness:  businessID => Businesses.getBusiness(state, businessID),
    getContact:   customerID => Contacts.getContact(state, customerID),
  }),
  dispatch => ({
    readBusiness: ({ businessID }) => dispatch(Businesses.silentlyLoadBusiness(businessID)),
    readContact:  ({ businessID, customerID }) => dispatch(Contacts.silentlyReadContact(customerID, businessID)),
  }),
)(WebCallBar);


const device = new Device();
let devicePromise;
export async function getDevice() {
  if (devicePromise)
    return await devicePromise;

  devicePromise = updateToken();
  return await devicePromise;
}


export async function updateToken() {
  try {
    const token = await getAccessToken();
    device.setup(token, { enableRingingState: true, logLevel: 5 });

    if (device.status() === 'ready')
      return device;
    else
      return await new Promise(resolve => device.on('ready', () => resolve(device)));
  } catch (error) {
    throw new Error(`Error updating Twilio Voice access token: ${error.message} ${error.code}`);
  }
}
