import * as Auth               from '../auth';
import * as ENV                from '../util/env';
import * as User               from '../store/user';
import lockOrientationInMobile from './lock_orientation_in_mobile';
import logEvent                from '../util/log_event';
import ms                      from 'ms';
import React                   from 'react';
import store                   from '../store';


export default function AppStart({ children }) {
  const [ ready, setReady ] = React.useState(false);
  const [ error, setError ] = React.useState(null);

  React.useEffect(function onMount() {
    async function start() {
      try {
        await fixIonicLocationForIOS();
        await lockOrientationInMobile();
        await authenticate();
        await hideSplashscreen();
        setReady(true);
      } catch (err) {
        logEvent('ViewStartupErrorPage');
        hideSplashscreen();
        setError(err);
      }
    }

    start();
  }, [ setReady ]);

  if (error)
    throw error;
  else if (ready)
    return children;
  else
    return null;
}



// Starting with ionic-webview 5.x, document.location is ionic://localhost on
// iOS. Framework7 3.x uses the following code to get the "document URL":
// document.location.href.split(document.location.origin)[1];
// https://github.com/framework7io/framework7/blob/bb3d9bd77d18f98e3bce25eb8bafb9304bb6f055/src/core/modules/router/router-class.js#L1190
//
// With 'ionic://localhost', the above line evaluates to '' and this breaks
// Framework7 routing. To fix this, we need to ensure that the location ends
// with a slash.
function fixIonicLocationForIOS() {
  const isCordovaIOS = ENV.isCordova() && ENV.isIOS();
  if (isCordovaIOS) {
    const framework7Href = document.location.href.split(document.location.origin)[1];
    if (!framework7Href)
      window.history.replaceState({}, null, '/');
  }
}


async function authenticate() {
  if (Auth.needsSalseforceAuthentication())
    Auth.authenticateWithSalesforce();
  else {
    // Get access token from OAuth flow, or SMS link
    if (Auth.isOAuthCallback())
      await onOAuthCallback();

    if (Auth.isPasswordlessAuthentication())
      await onPasswordlessAuthentication();

    // Make sure user authenticated by loading their profile
    // This is the ultimate check: valid access token + user account exists
    const hasAccessToken = !!User.getAccessToken(store.getState());
    if (hasAccessToken)
      await store.dispatch(User.loadUserProfile());
  }
}

// Handles OAuth callback, either with token or code
async function onOAuthCallback() {
  const { accessToken, returnTo, intent, utmCodes } = await Auth.getOAuthResult();

  await store.dispatch(User.signIn({ accessToken, intent, utmCodes }));

  window.history.replaceState({}, null, returnTo);
}

// Handled passwordless authentication, SMS link contains username + password
async function onPasswordlessAuthentication() {
  const { username, password } = Auth.getPasswordlessCredentials();
  const redirectTo             = getQueryStringValue('redirectTo') || '/';
  try {
    await store.dispatch(User.authenticate({ username, password }));
    window.history.replaceState({}, null, redirectTo);
  } catch (error) {
    // could not authenticate render normal page
  }
}

function getQueryStringValue(key) {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
}

async function hideSplashscreen() {
  await waitForResources();
  hideSplashscreenForWeb();
  hideSplashscreenForCordova();
}

// Web app uses an inline SVG element so it renders before the browser got to
// process any CSS or JS.
function hideSplashscreenForWeb() {
  const launchScreen = document.querySelector('.b-launch-screen');
  if (launchScreen)
    launchScreen.style.display = 'none';
}

// Cordova uses a plugin to show the splash screen (on iOS this is known as
// launch screen, and will show when the app starts, before any other assets are
// loaded).
function hideSplashscreenForCordova() {
  if (window.navigator.splashscreen)
    window.navigator.splashscreen.hide();
  fixAndroidStatusBar();
}

function fixAndroidStatusBar() {
  // These should be set in config.xml, but Android + PGB seems to ignore
  // config.xml
  // - If statusbar overlays webview (the default), then opening the keyboard
  //   will not resize the view port, and the keyboard obscures the message box
  // - The statusbar color is set to white, to blend with the app
  // - Apparently the default style is not the default, so we have to set it
  //   explicitly
  const { StatusBar, device } = window;
  if (device && device.platform === 'Android' && StatusBar) {
    StatusBar.hide();
    StatusBar.overlaysWebView(false);
    // wait a bit to webview change, and so show statusbar
    setTimeout(() => {
      StatusBar.backgroundColorByName('white');
      StatusBar.styleDefault();
      StatusBar.show();
    }, 100);
  }
}

// Listeners
document.addEventListener('deviceready', function() {
  fixAndroidStatusBar();
}, false);

// Update lastActive + profile when resume
document.addEventListener('resume', function() {
  store.dispatch(User.silentlyLoadUserProfile());
}, false);



// Wait for resources (CSS, fonts, etc) to load before we get anything started.
// We need these before we can hide the splashscreen, or so the sign in screen.
async function waitForResources() {
  if (document.readyState === 'complete')
    return;

  await new Promise(function(resolve) {
    window.addEventListener('load', resolve);
    setTimeout(resolve, ms('3s'));
  });
}
