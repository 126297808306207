import * as UI           from '../store/ui';
import Button            from '../components/button';
import classnames        from 'classnames';
import NotificationBadge from '../components/notification_badge';
import React             from 'react';


export function MenuLink({
  icon,
  label,
  active,
  className,
  href,
  badgeCount,
  betaBadge = false,
  ...props
}) {
  const classNames = classnames(className, 'menu-link', { active });
  return (
    <Button renderNestedContent={false} className={classNames} href={href} aria-label={label} role="tab" onClick={UI.closeMenu} {...props}>
      <div className="icon-and-label">
        <span className="icon">{icon}</span>
        <span className="label">{label}</span>
      </div>
      {betaBadge && (<span className="badge-beta">beta</span>)}
      <NotificationBadge count={badgeCount}/>
    </Button>
  );
}


export function MenuLinks({ children }) {
  return (
    <div className="menu-links">
      <div className="menu-links-content">
        {children}
      </div>
    </div>
  );
}
