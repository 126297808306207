import React from 'react';


// useSelected
// - wrapper for useSingleSelected and useMultipleSelected hooks
// - returns [ selected, onSelect ] props for use with SelectDropdown
// - if the initial value is an array, the returned props will create a dropdown
//   with multiple selection.
// - otherwise, the returned props will create a dropdown with single selection.

export default function useSelected(initialSelected) {
  const singleSelected   = useSingleSelected(initialSelected);
  const multipleSelected = useMultipleSelected(initialSelected);

  if (Array.isArray(initialSelected))
    return multipleSelected;
  else
    return singleSelected;
}


// useSingleSelected
// - hook for options with only one selection allowed
// - dropdown will blink and close when an item is selected
export function useSingleSelected(initialSelected = '') {
  const [ selected, setSelected ] = React.useState(initialSelected);

  const onSelect = React.useCallback(event => {
    setSelected(event.target.value);
  }, [ setSelected ]);

  return [ selected, onSelect, setSelected ];
}


// useMultipleSelected
// hook for options with multiple selections allowed
// dropdown will stay open when an item is selected
export function useMultipleSelected(initialSelected = []) {
  const [ selected, setSelected ] = React.useState(initialSelected);

  const onSelect = React.useCallback(event => {
    // don't auto-close dropdown for multiple selection dropdowns
    event.preventDefault();
    const { value } = event.target;

    setSelected(previous => {
      if (previous.includes(value))
        return previous.filter(item => item !== value);
      else
        return [ ...previous, value ];
    });
  }, [ setSelected ]);

  return [ selected, onSelect, setSelected ];
}


export function isOptionSelected(option, selected) {
  const { value } = option;

  return value && Array.isArray(selected)
    ? selected.includes(value)
    : selected === value;
}
