import './menu_button.less';

import * as UI from '../store/ui';
import Button  from '../components/button';
import React   from 'react';


export default function MenuButton() {
  const isOpen = UI.useMenuIsOpen();

  React.useEffect(function listenForEvents() {
    function clickListener(event) {
      const { target }           = event;
      const menuPanel            = document.querySelector('.menu-panel');
      const menuButton           = document.querySelector('.menu-button');
      const clickedOutsidePanel  = !menuPanel.contains(target);
      const clickedOutsideButton = !menuButton.contains(target);
      if (clickedOutsidePanel && clickedOutsideButton)
        UI.closeMenu();
    }

    function keyListener(event) {
      const { key }  = event;
      const isEscape = (key === 'Escape');
      if (isEscape)
        UI.closeMenu();
    }

    function addListeners() {
      document.body.addEventListener('click', clickListener);
      document.body.addEventListener('keydown', keyListener);
    }

    function removeListeners() {
      document.body.removeEventListener('click', clickListener);
      document.body.removeEventListener('keydown', keyListener);
    }

    if (isOpen)
      addListeners();
    else
      removeListeners();

    return removeListeners;
  }, [ isOpen ]);

  return (
    <Button
      aria-expanded={isOpen}
      label="Main Menu"
      className="menu-button"
      onClick={UI.toggleMenu}
      icon={<div className="menu-icon"/>}
    />
  );
}
