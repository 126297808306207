
export default {
  path:     '/sign_in',
  redirect: '/login',

  routes: [
    {
      path: 'passwordless',
      redirect(route, resolve) {
        resolve(`/login/passwordless?token=${route.query.token}&username=${route.query.username}`);
      },
    },
  ],
};
