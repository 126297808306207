// Push messaging using Twilio Sync: https://www.twilio.com/docs/sync

import * as Resources from '../util/resources';
import * as UI        from '../store/ui';
import * as User      from '../store/user';
import ms             from 'ms';
import onDataMessage  from './on_data_message';
import React          from 'react';
import retry          from '../util/retry';
import store          from '../store';
import SyncClient     from 'twilio-sync';


let syncClient;
let syncStream;
let shouldProcessNotifications;

export default function Twilio({ firebaseEnabled }) {
  const isSignedOut = User.useIsSignedOut();

  React.useEffect(function() {
    start();
  }, []);

  React.useEffect(function() {
    shouldProcessNotifications = !firebaseEnabled;
  }, [ firebaseEnabled ]);

  React.useEffect(function() {
    if (isSignedOut)
      stop();
  }, [ isSignedOut ]);

  return null;
}


async function start() {
  const sync = await requestSyncFromAPI();

  if (!sync)
    return;

  const { streamID, token } = sync;

  syncClient = new SyncClient(token);
  syncStream = await syncClient.stream(streamID);

  syncStream.on('messagePublished', ({ message: { data: { notification, data } } }) => {
    if (notification && shouldProcessNotifications)
      UI.notify(notification);

    if (data)
      onDataMessage(data);
  });

  syncClient.on('tokenAboutToExpire', async() => {
    console.info('Twilio Sync token about to expire, refreshing'); // eslint-disable-line no-console

    const newSync = await requestSyncFromAPI();
    // No sync means user signed out.
    // start() will be called again when there's a new
    // access token available.
    if (newSync) {
      const { token: newToken } = newSync;
      syncClient.updateToken(newToken);
    }
  });

  syncClient.on('connectionError', async error => {
    // Recover from terminal errors.
    if (error.terminal) {
      console.error('Twilio Sync connectionError fired, error is terminal, restarting');
      await restart();
    }
  });

  async function restart() {
    await stop();
    await start();
  }
}


async function stop() {
  if (syncStream)
    syncStream.removeAllListeners();

  if (syncClient)
    await syncClient.shutdown();

  syncClient = null;
  syncStream = null;
}


async function requestSyncFromAPI() {
  return await retry(async() => {
    try {
      // Resources.silentRequest() will refresh the access token
      // if it's expired. This is important for keeping the
      // Twilio Sync client alive when the app is not being used
      // for long periods of time.
      // See https://github.com/broadly/broadly/issues/4162
      // and https://github.com/broadly/app/pull/1989.
      const { body } = await store.dispatch(Resources.silentRequest({
        url:     `${API_URL}/me/twilio_sync`,
        timeout: ms('10s'),
      }));

      if (!body.streamID) // e.g. in tests
        return null;

      return {
        streamID: body.streamID,
        token:    body.token,
      };
    } catch (error) {
      const { statusCode } = error;
      const isUnavailable  = statusCode === 422; // e.g. Salesforce user
      if (statusCode === 0) { // Timeout
        return await requestSyncFromAPI();
      } else if (isUnavailable)
        return null;
      else
        throw new Error(`Twilio Sync endpoint returned ${statusCode}`);
    }
  });
}
