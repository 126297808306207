// This file defines all the reducers that make up the React store.
//
// Some are redux-resource (https://redux-resource.js.org), and should only
// support resource actions.
//
// For the rest, see their corresponding reducer file.



import { combineReducers } from 'redux';
import business            from './business/reducer';
import campaigns           from './campaigns/reducer';
import conversations       from './conversations/reducer';
import customers           from './contacts/reducer';
import dashboard           from './dashboard/reducer';
import feedback            from './feedback/reducer';
import groups              from './groups/reducer';
import invoices            from './invoices/reducer';
import messagesQueue       from './messages_queue/reducer';
import messageTemplates    from './message_templates/reducer';
import payouts             from './payouts/reducer';
import reports             from './reports/reducer';
import templates           from './campaign_templates/reducer';
import ui                  from './ui/reducer';
import user                from './user/reducer';


const combined = combineReducers({
  business,
  campaigns,
  templates,
  groups,
  conversations,
  customers,
  feedback,
  invoices,
  messagesQueue,
  messageTemplates,
  dashboard,
  reports,
  payouts,
  ui,
  user,
});


export default function reducer(state, action) {
  return combined(topLevel(state, action), action);
}


// This reducer exists for the sole reason of wiping clean the entire Redux
// store: whenever user signs in or signs out, we need a total state reset.
function topLevel(state, action) {
  switch (action.type) {
    case 'RESET':
      return {};

    default:
      return state;
  }
}
