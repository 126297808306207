import * as User  from '../../store/user';
import { lazy }   from 'react';
import renderPage from '../../components/page';

const ContactsPage   = lazy(() => import(/* webpackChunkName: 'contacts' */ './contacts_page'));
const AddFilePage    = lazy(() => import(/* webpackChunkName: 'contacts' */ './add/file_page'));
const AddOnePage     = lazy(() => import(/* webpackChunkName: 'contacts' */ './add/one_page'));
const AddPage        = lazy(() => import(/* webpackChunkName: 'contacts' */ './add/add_page'));
const EditPage       = lazy(() => import(/* webpackChunkName: 'contacts' */ './edit_page'));
const InfoPage       = lazy(() => import(/* webpackChunkName: 'contacts' */ './info_page'));
const KioskModePage  = lazy(() => import(/* webpackChunkName: 'contacts' */ './add/kiosk_mode_page'));
const NewQuotePage   = lazy(() => import(/* webpackChunkName: 'payments' */ '../payments/new_quote_page'));
const NewInvoicePage = lazy(() => import(/* webpackChunkName: 'contacts' */ '../payments/new_invoice_page'));


const tabComponent = {
  component: renderPage(ContactsPage),
  className: 'contacts-page',
  name:      'contacts',
};

export default {
  path:      '/:businessID/contacts',
  component: renderPage(ContactsPage, { tabPage: true }),
  className: 'contacts-page',
  name:      'ContactsList',
  tabComponent,
  routes:    [
    {
      path:      'add',
      component: renderPage(AddPage),
      className: 'add-page',
      name:      'AddPage',
      tabComponent,
      routes:    [
        {
          path:      'one',
          name:      'AddContact',
          component: renderPage(AddOnePage),
          className: 'add-one-page',
          tabComponent,
        },
        {
          path:      'kiosk',
          name:      'KioskMode',
          component: renderPage(KioskModePage),
          className: 'kiosk-mode-page',
        },
        {
          path:      'file',
          name:      'AddFromFile',
          component: renderPage(AddFilePage),
          className: 'add-file-page',
          allow:     User.isManager,
          tabComponent,
        },
      ],
    },
    {
      path:      ':contactID',
      component: renderPage(InfoPage),
      className: 'contact-info-page',
      name:      'ContactInfo',
      tabComponent,
      routes:    [
        {
          path:      'edit',
          component: renderPage(EditPage),
          className: 'edit-contact-page',
          name:      'ContactInfo',
          tabComponent,
        },
        {
          path:      'quote',
          component: renderPage(NewQuotePage),
          className: 'new-quote-page',
          name:      'ContactQuote',
          tabComponent,
        },
        {
          path:      'invoice',
          component: renderPage(NewInvoicePage),
          className: 'new-invoice-page',
          name:      'ContactInvoice',
          tabComponent,
        },
      ],
    },
  ],
};
