import * as Actions    from './actions';
import * as Selectors  from './selectors';
import { compose }     from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import React           from 'react';


// Selectors

export function useNotification() {
  return useSelector(state => Selectors.getNotification(state));
}


export function useToast() {
  return useSelector(state => Selectors.getToast(state));
}


export function useAlert() {
  return useSelector(state => Selectors.getAlert(state));
}


export function useIsInProgress() {
  return useSelector(state => Selectors.isInProgress(state));
}


export function useMenuIsOpen() {
  return useSelector(state => Selectors.getMenuIsOpen(state));
}


// Actions

// Show the progress spinner while the condition is truthy.
export function useProgress(showProgress) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (showProgress) {
      dispatch(Actions.progressStart());
      return () => {
        dispatch(Actions.progressEnd());
      };
    } else
      return () => {};
  }, [ showProgress, dispatch ]);
}


export function useHideAlert() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.hideAlert), [ dispatch ]);
}

export function useSetNotification() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.setNotification), [ dispatch ]);
}

const emptyNotification = { isOpen: false };
export function useUnsetNotification() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.setNotification, () => emptyNotification), [ dispatch ]);
}
