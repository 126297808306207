// vestigial routes config for metrics, just redirects to other tabs now

export default {
  path:     '/:businessID/metrics',
  redirect: (route, resolve) => {
    const { businessID } = route.params;
    resolve(`/${businessID}/reviews`);
  },
  routes: [
    {
      path:     'invoices',
      redirect: (route, resolve) => {
        const { businessID } = route.params;
        resolve(`/${businessID}/payments`);
      },
      routes: [
        {
          path:     ':invoiceID',
          redirect: (route, resolve) => {
            const { businessID } = route.params;
            const { invoiceID }  = route.params;
            resolve(`/${businessID}/payments/invoices/${invoiceID}`);
          },
        },
      ],
    },
    {
      path:     'private-feedback',
      redirect: (route, resolve) => {
        const { businessID } = route.params;
        resolve(`/${businessID}/reviews?filters=private`);
      },
    },
    {
      path:     'reviews',
      redirect: (route, resolve) => {
        const { businessID } = route.params;
        resolve(`/${businessID}/reviews`);
      },
    },
    {
      path:     'scorecard',
      redirect: (route, resolve) => {
        const { businessID } = route.params;
        resolve(`/${businessID}/team`);
      },
    },
  ],
};
