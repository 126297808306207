import * as User        from '../store/user';
import { setPerson }    from '../rollbar';
import decodeJWT        from '../util/decode_jwt';
import React            from 'react';
import useEventListener from '../hooks/use_event_listener';


// Access token is persisted to local storage, so you can authenticate once per
// device and remain signed in on multiple windows.
//
// But also use the same account and sign out of all the windows as once,
// see checkAccessToken.
//
// Tests store access token in window, so local to a single browser page.
//
// Order of operations is important: the first thing we do is load access token
// from local storage, and then listen for changes to the access token.

export default function AccessToken() {
  const [ loaded, setLoaded ] = React.useState(false);
  return (
    <>
      <LoadToken {...{ setLoaded }}/>
      {loaded && <OnTokenChange/>}
      {loaded && <OnStorageChange/>}
    </>
  );
}


function LoadToken({ setLoaded }) {
  const setAccessToken = User.useSetAccessToken();

  React.useEffect(function loadAccessTokenOnMount() {
    setAccessToken(loadAccessToken());
    setLoaded(true);
  }, [ setAccessToken, setLoaded ]);

  return null;
}


function loadAccessToken() {
  try {
    return JSON.parse(window.localStorage.getItem('accessToken')) || window.accessToken;
  } catch (error) {
    return null;
  }
}


function OnTokenChange() {
  const accessToken = User.useAccessToken();

  React.useEffect(function onChangeAccessToken() {
    if (accessToken) {
      // Only keep access token around when is not Salesforce).
      const isSalesforceAccessToken = accessToken.connection === 'salesforce';
      if (!isSalesforceAccessToken) {
        window.localStorage.setItem('accessToken', JSON.stringify(accessToken));
        const person = getPersonFromAccessToken(accessToken);
        setPerson(person);
      }
    } else {
      window.localStorage.removeItem('accessToken');
      setPerson(null);
    }
  }, [ accessToken ]);

  return null;
}


// If the user signs out from one window, they must be signed out immediately
// from all other windows.
//
// Consider:
// - User A signs into the app, and uses tabs X and Y
// - User A, now in tab X, signs out of the app
// - User B, in tab X, signs into the app
// - User B switches to tab Y
// - The tab Y may show information entered by or belonging to user A
// - For example, upload list may still contain records added by user A
// - Add customer form may have user A as the assisting team member, etc
//
// How this works:
// - When the user signs into the app, we set a key in local storage with their
//   authentication token
// - When the user signs out of the app, we remove the key from local storage
// - Each change triggers a storage event for all other windows from same domain
// - If another window changed the value, either the user signed out, or signed
//   in to a different account
// - Either case, we sign out from the current window, to reset the state/UI
// - And redirect back to /, user will need to sign in again
function OnStorageChange() {
  const signOut = User.useSignOut();

  function signOutWhenTokenReset(event) {
    const isTokenEvent   = (event.key === 'accessToken');
    const isTokenRemoved = isTokenEvent && !event.newValue;
    if (isTokenRemoved)
      signOut();
  }

  useEventListener('storage', signOutWhenTokenReset);

  return null;
}


export const connectionTypes = {
  quickbooks: 'quickbooks-online',
  google:     'google-oauth2',
};


// Returns { id, email, username } or null
export function getPersonFromAccessToken(accessToken) {
  try {
    const { jwtToken }   = accessToken;
    const decoded        = decodeJWT(jwtToken);
    const connectionType = decoded.sub?.split('|')?.[0];
    return {
      id:         decoded.sub,
      email:      decoded.email,
      username:   decoded.name,
      connection: connectionType,
    };
  } catch (error) {
    return null;
  }
}
