// Utility methods for dealing with resources (HTTPS requests to back-end API)
//
//
// Resources.request
//  - Perform XHR request: can be used directly, used by CRUD operations
//  - Will refresh stale access token, but if access token was revoked, the user
//    will be signed out from app
//  - If request successful, resolves to document object (null if response is
//    not a JSON document)
//  - Will retry operation if failed due to network error, or 5xx from server,
//    but only if request is idempotent (so not POST)
//  - Will show toast message with appropriate error message, except 400 which
//    is handled by client code (eg show validation error in form), and 401
//    which signs user out
//  - Will also log various errors to Rollbar, so caller doesn't need to worry
//    about that
//
// Resources.readResources
//  - Influenced by redux-resource
//  - Use Resources.request to read resources from the server
//  - Resolve if successful (status code 2xx/3xx, no value)
//  - Retry and error handling, as described above
//  - Stores resource in Redux store
//  - Stores meta-data about request
//  - You can also operate on a list of resources, see:
//    https://redux-resource.js.org/docs/guides/lists.html
//  - readStatus goes idle -> pending -> succeeded/failed
//  - Currently does not support aborting requests
//  - See https://redux-resource.js.org/docs/guides/reading-resources.html
//
// Resources.listResources
//  - Specialized readResource for paginating lists
//
// Resources.createResources
//  - Similar but for creating a new resource (POST)
//  - You may need to use request if you don't have an ID when creating
//    new resource, see:
//    https://redux-resource.js.org/docs/guides/named-requests.html
//  - See https://redux-resource.js.org/docs/guides/creating-resources.html
//
// Resources.updateResources
//  - Similar but for updating a resource (PATCH)
//  - See https://redux-resource.js.org/docs/guides/updating-resources.html
//
// Resources.deleteResources
//  - Similar but deleting a resource (DELETE)
//  - Removes resource from Redux store
//  - See https://redux-resource.js.org/docs/guides/deleting-resources.html


import * as errors             from './errors';
import * as UI                 from '../store/ui';
import * as User               from '../store/user';
import { actionTypes }         from 'redux-resource';
import assert                  from 'assert';
import createActionCreators    from 'redux-resource-action-creators';
import getFriendlyErrorMessage from './get_friendly_error_message';
import ms                      from 'ms';
import xhr                     from 'xhr';



// Create resources on server and store them in Redux.
//
// url           - URL of the resource
// body          - Document body object
// resourceType  - The name of the resource (eg business)
// resources     - Resources to create (eg [id1, id2])
// list          - When operating on a list of resources
// request       - Request name (if you don't have an ID)
// transformData - Transform response object into new resources
//
// Other options, see crudResource
export function createResources(params) {
  return crudResource('create', {
    method: 'POST',
    ...params,
  });
}


// Read resources from server and store them in Redux.
//
// url           - URL of the resource
// resourceType  - The name of the resource (eg business)
// resources     - Resources to read (eg [id1, id2])
// list          - When operating on a list of resources
// transformData - Transform response object into new resources
//
// Other options, see crudResource
export function readResources(params) {
  return crudResource('read', {
    method: 'GET',
    ...params,
  });
}


// Like readResources(), but without any UI changes.
export function silentlyReadResources(params) {
  return async function(dispatch) {
    const actionCreators = createActionCreators('read', params);
    try {
      dispatch(actionCreators.pending());
      const response       = await dispatch(silentRequest({
        method:  'GET',
        url:     params.url,
        timeout: params.timeout,
      }));
      const { body }       = response;
      const resources      = params.transformData ? params.transformData(body) : body;
      const { statusCode } = response;
      dispatch(actionCreators.succeeded({ resources, statusCode }));
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
    }
  };
}


// Updates resources on server and store results in Redux.
//
// url           - URL of the resource
// body          - Document body
// resourceType  - The name of the resource (eg business)
// resources     - Resources to create (eg [id1, id2])
// list          - When operating on a list of resources
// transformData - Transform response object into new resources
//
// Other options, see crudResource
export function updateResources(params) {
  return crudResource('update', {
    method: 'PATCH',
    ...params,
  });
}


// Deletes resources from server and from Redux.
//
// url           - URL of the resource
// resourceType  - The name of the resource (eg business)
// resources     - Resources to delete (eg [id1, id2])
//
// Other options, see crudResource
export function deleteResources(params) {
  return crudResource('delete', {
    method: 'DELETE',
    transformData() {
      return params.resources;
    },
    ...params,
  });
}


// Replace resources from server and from Redux.
//
// url           - URL of the resource
// body          - Document body
// resourceType  - The name of the resource (eg business)
// resources     - Resources to create (eg [id1, id2])
// list          - When operating on a list of resources
// transformData - Transform response object into new resources
//
// Other options, see crudResource
export function replaceResources(params) {
  return crudResource('update', {
    method: 'PUT',
    ...params,
  });
}


// Additional options include:
// timeout — Request timeout in ms
function crudResource(crudAction, params) {
  return async function(dispatch) {
    const actionCreators = createActionCreators(crudAction, params);
    try {
      dispatch(actionCreators.pending());
      const response = await dispatch(request({
        url:                params.url,
        method:             params.method,
        body:               params.body,
        timeout:            params.timeout,
        showDefaultErrorUI: params.showDefaultErrorUI,
        showProgress:       params.showProgress,
      }));

      const { body }       = response;
      const { statusCode } = response;
      const resources      = params.transformData ? params.transformData(body) : body;
      dispatch(actionCreators.succeeded({ resources, statusCode }));
      return resources;
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };
}


// Reads resources from server using pagination.
//
// Loads the first page quickly (100 records)
// and then continues to paginate at 1,000 at a time.
//
// Progress spinner is shown only while we load
// the first page.
//
// While paginating, the resource status will be pending
// and the status code will be 200.
export function listResources(params) {
  return async function(dispatch) {
    async function getPage({ cursor, onData }) {
      const url = new URL(params.url);
      if (cursor) {
        url.searchParams.set('cursor', cursor);
        url.searchParams.set('limit', 1000);
      } else
        url.searchParams.set('limit', 100);

      const response = await dispatch(request({
        url:                url.toString(),
        method:             'GET',
        timeout:            params.timeout,
        showDefaultErrorUI: params.showDefaultErrorUI,
        showProgress:       params.showProgress,
      }));

      const { body }     = response;
      const { pageInfo } = body;
      const resources    = params.transformData ? params.transformData(body) : body;
      await onData(resources);
      if (pageInfo?.nextCursor)
        await getPage({ cursor: pageInfo.nextCursor, onData });
    }

    const actionCreators = createActionCreators('read', params);
    try {
      dispatch(paginating(true));
      dispatch(actionCreators.pending());

      const allResources = [];

      await getPage({
        onData: resources => {
          allResources.push(...resources);
          // Redux Resource won't update the store with new resources if status
          // isn't succeeded.
          dispatch(actionCreators.succeeded({ resources: allResources }));
          dispatch(actionCreators.pending({ statusCode: 200 }));
        },
      });

      dispatch(actionCreators.succeeded({ resources: allResources, statusCode: 200 }));
      dispatch(paginating(false));
      return allResources;
    } catch (error) {
      const statusCode = error.statusCode || 500;
      dispatch(actionCreators.failed({ statusCode }));
      throw error;
    }
  };


  function paginating(isPaginating) {
    const { resourceType }     = params;
    const { list: businessID } = params;

    return {
      type: actionTypes.UPDATE_RESOURCES,
      meta: {
        [resourceType]: {
          paginating: {
            [businessID]: isPaginating,
          },
        },
      },
    };
  }
}


// Use this to make XHR request to the server.  Does not touch Redux.
//
// method - HTTP method
// url    - URL of the resource
// body   - Body to send to server (optional)
//
// Resolves with document object if request successful.
//
// Otherwise, rejects with an error that will include the status code.
//
// If status code is 401, signs user out.
//
export function request(params) {
  return async function(dispatch, getState) {
    if (params.showProgress)
      dispatch(UI.progressStart());

    try {
      await withAccessToken({ dispatch, getState });
      const response = await retry(() => makeXHRRequest({ ...params, getState }));
      return response;
    } catch (error) {
      errors.sendToRollbar(error);

      // For example, user lost access to device, does a password reset.  This
      // should immediately log everyone out from all devices.
      if (error.statusCode === 401)
        dispatch(User.signOut());
      else if (params.showDefaultErrorUI !== false)
        dispatch(errors.showToastError(error));

      error.friendlyMessage = getFriendlyErrorMessage(error);
      throw error;
    } finally {
      if (params.showProgress)
        dispatch(UI.progressEnd());
    }
  };
}

// Makes a XHR with the user access token.
export function silentRequest(params) {
  return async function(dispatch, getState) {
    try {
      await withAccessToken({ dispatch, getState });
      const response = await retry(() => makeXHRRequest({ ...params, getState }));
      return response;
    } catch (error) {
      errors.sendToRollbar(error);
      if (error.statusCode === 401)
        dispatch(User.signOut());

      throw error;
    }
  };
}


// Make sure we have a fresh access token before making API request.
export async function withAccessToken({ dispatch, getState }) {
  if (!User.getJWTToken(getState())) {
    await dispatch(User.refreshToken());

    if (!User.getJWTToken(getState())) {
      const error      = new Error('Cannot refresh access token');
      error.statusCode = 401;
      throw error;
    }
  }
}


// If network error or server responded with 5xx, we can retry the request,
// maybe get lucky second time around.  Only idempotent requests need apply.
async function retry(fn) {
  try {
    return await fn();
  } catch (error) {
    if (errors.shouldRetry(error))
      return await fn();
    else
      throw error;
  }
}


//  Wraps around https://github.com/naugtur/xhr:
//  - Returns a promise (no callbacks!)
//  - Authenticates the request, but you must pass `getState`
//  - If statusCode >= 400, reject with an error
//  - Includes statusCode, method, and body in error
//  - Default timeout is 30 seconds
function makeXHRRequest({ url, method = 'GET', body, timeout, getState }) {
  return new Promise(function(resolve, reject) {
    xhr({
      url:        getAbsoluteURL(url),
      method,
      beforeSend: xhrRequest => {
        setAuthorizationHeader({ getState, xhrRequest });
      },
      body,
      json:    true,
      timeout: timeout || ms('30s'),
    }, function(error, response) {
      if (error || response.statusCode === 0) {
        const reason            = error ? (error.code || error.message) : 'aborted';
        const requestError      = new Error(`${url} => ${reason}`);
        requestError.stack      = error.stack;
        requestError.statusCode = 0;
        requestError.method     = method.toUpperCase();
        reject(requestError);
      } else if (response.statusCode >= 400) {
        const statusError      = new Error(`${url} => ${response.statusCode}`);
        statusError.statusCode = response.statusCode;
        statusError.method     = method.toUpperCase();
        statusError.body       = response.body;
        reject(statusError);
      } else
        resolve(response);
    });
  });
}


function setAuthorizationHeader({ getState, xhrRequest }) {
  const { jwtToken } = User.getAccessToken(getState());
  assert(jwtToken, 'No JWT token');
  xhrRequest.setRequestHeader('Authorization', `Bearer ${jwtToken}`);
}


function getAbsoluteURL(url) {
  if (url[0] === '/')
    return `https://${getAPI()}${url}`;
  else
    return url;
}

function getAPI() {
  if (typeof api === 'undefined')
    return window.location.hostname;
  else
    return api; // eslint-disable-line no-undef
}
