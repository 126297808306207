// iPhoneXDetector
//
// Framework7 plugin to detect screen sizes of newer iPhone X-style devices


// It's difficult to find a definitive list of viewport sizes, particularly
// for Display Zoom mode. Fortunately, Display Zoom usually mimics the next
// smaller device size, so one of the other devices should match.
export const iPhoneXModels = [
  { name: 'X',                        width: 375, height: 812 },
  { name: 'XR',                       width: 414, height: 896 },
  { name: 'XS',                       width: 375, height: 812 },
  { name: 'XS Max',                   width: 414, height: 896 },
  { name: '11',                       width: 414, height: 896 },
  { name: '11 Pro',                   width: 375, height: 812 },
  { name: '11 Pro Display Zoom',      width: 320, height: 693 },
  { name: '11 Pro Max',               width: 414, height: 896 },
  { name: '11 Pro Max Display Zoom',  width: 375, height: 812 },
  { name: '12 mini',                  width: 375, height: 812 },
  { name: '12 mini Display Zoom',     width: 320, height: 693 },
  { name: '12',                       width: 390, height: 844 },
  { name: '12 Display Zoom',          width: 320, height: 693 },
  { name: '12 Pro',                   width: 390, height: 844 },
  { name: '12 Pro Display Zoom',      width: 320, height: 693 },
  { name: '12 Pro Max',               width: 428, height: 926 },
  { name: '12 Pro Max Display Zoom',  width: 375, height: 812 },
  { name: '13 mini',                  width: 375, height: 812 },
  { name: '13',                       width: 390, height: 844 },
  { name: '13 Pro',                   width: 390, height: 844 },
  { name: '13 Pro Max',               width: 428, height: 926 },
  { name: '14',                       width: 390, height: 844 },
  { name: '14 Plus',                  width: 428, height: 926 },
  { name: '14 Pro',                   width: 393, height: 852 },
  { name: '14 Pro Max',               width: 430, height: 932 },
];


const iPhoneXDetector = {
  name: 'iphone-x-detector',
  create() {
    const html = document.querySelector('html');
    if (!html)
      return;

    const viewportWidth  = window.screen.width;
    const viewportHeight = window.screen.height;

    function matchesViewportSize({ width, height }) {
      return width === viewportWidth && height === viewportHeight;
    }

    // this.device.iphone is defined for us by Framework7, using user-agent
    // sniffing. But this.device.iphonex doesn't account for all applicable
    // screen sizes, so we do that check ourselves using the list above.
    this.device.iphonex = this.device.iphone && iPhoneXModels.some(matchesViewportSize);

    // Framework7 adds its html classes before this plugin is invoked, so we
    // have to add this classname ourselves.
    if (this.device.iphonex)
      html.classList.add('device-iphone-x');
  },
};

export default iPhoneXDetector;
