// Push messaging on the browser using Twilio Sync and Firebase.
//
// Twilio Sync uses web sockets, so always available in the browsers we target.
//
// Firebase gives us native notifications in desktop and mobile browsers.
// Firebase doesn't work on all browsers, and also needs permission from the
// user.
//
// So we connect with Twilio Sync first and then upgrade to Firebase if
// possible.

import * as User  from '../store/user';
import Firebase   from './firebase';
import React      from 'react';
import Twilio     from './twilio';
import useBoolean from '../hooks/use_boolean';


export default function Web() {
  const [ firebaseEnabled, enableFirebase, disableFirebase ] = useBoolean(false);

  return (
    <>
      <Twilio {...{ firebaseEnabled }}/>
      <Firebase {...{ enableFirebase, disableFirebase }}/>
      <BadgeCount/>
    </>
  );
}


// Badge in window title handled by WindowTitle component.
function BadgeCount() {
  const count = User.useBadgeCount();

  React.useEffect(function() {
    // https://web.dev/badging-api
    if (window.navigator.setAppBadge)
      window.navigator.setAppBadge(count);
  }, [ count ]);

  return null;
}
