import * as ActivityText from '../../pages/inbox/activity';


export default function addSearchFields(customer) {
  const lastActivity       = getLastActivityText(customer.lastActivity);
  const phone              = getPhoneNumberDigits(customer);
  const [ given, ...rest ] = customer.name.full.split([ ' ', ',' ]);
  const surname            = rest.join(' ');

  return {
    ...customer,
    name: {
      full: customer.name.full,
      given,
      surname,
    },
    lastActivity: customer.lastActivity && {
      ...customer.lastActivity,
      text: lastActivity,
    },
    contact: {
      ...customer.contact,
      phone: {
        ...customer.contact.phone,
        text: phone,
      },
    },
    sorting: getSortingString(customer.name.full),
  };
}


const letterRegexp = /^([a-zA-Z])/;

// Returns a string suitable for sorting the list of
// customers alphabetically, with non-alphabetical strings
// going last.
//
// The first character (0-1) allows to sort non-letters at
// the end of the list.
//
// The second character is the index letter.
//
// 'Tammy Diaz'  → '0Ttammy diaz'
// 'Alicia Ruiz' → '0Aalicia ruiz'
// '& Name'      → '1#&name'
//
function getSortingString(string) {
  const alpha  = string.match(letterRegexp);
  const prefix = alpha ? `0${alpha[0].toUpperCase()}` : '1#';
  return `${prefix}${string.toLowerCase()}`;
}


function getPhoneNumberDigits({ contact }) {
  const number = contact?.phone?.number;

  if (number)
    return number.replace(/\D/g, '');
  else
    return null;
}


function getValueIfExists(activity, key) {
  const keys = key.split('.');

  return keys.reduce((acc, entry) => {
    if (!acc[entry])
      return null;

    return acc[entry];
  }, activity);
}


function removeLineBreaks(str) {
  if (str)
    return str.replace(/(\r\n\t|\n|\r\t)/gm, ' ');
  else
    return null;
}

function getLastActivityText(activity) {
  if (!activity)
    return null;

  switch (activity.type) {
    case 'IncomingMessage':
    case 'OutgoingMessage': {
      return removeLineBreaks(getValueIfExists(activity, 'message.body'));
    }
    case 'Feedback':
      return removeLineBreaks(getValueIfExists(activity, 'feedback.body'));
    case 'Review':
      return removeLineBreaks(activity.review?.body);
    case 'ReviewResponse':
      return removeLineBreaks(activity.reviewResponse?.body);
    case 'InvoiceSent':
      return ActivityText.getInvoiceSentText({ activity });
    case 'InvoicePaid':
      return ActivityText.getInvoicePaidText({ activity });
    case 'InvoiceRefunded':
      return ActivityText.getInvoiceRefundedText({ activity });
    case 'QuoteSent':
      return ActivityText.getQuoteSentText({ activity });
    case 'QuoteAccepted':
      return ActivityText.getQuoteAcceptedText({ activity });
    case 'QuoteDeclined':
      return ActivityText.getQuoteDeclinedText({ activity });
    case 'QuoteDepositPaid':
      return ActivityText.getQuoteDepositPaid({ activity });
    case 'QuoteDepositRefunded':
      return ActivityText.getQuoteDepositRefunded({ activity });
    default:
      return null;
  }
}
