import { lazy }   from 'react';
import renderPage from '../../components/page';


const ReferralPage = lazy(() => import(/* webpackChunkName: 'contacts' */ './referral_page'));


const tabComponent = {
  component: null,
};


export default {
  path:      '/:businessID/referrals',
  component: renderPage(ReferralPage),
  className: 'referral-page',
  name:      'Referrals',
  tabComponent,
};
