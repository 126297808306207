// Authentication logic for Webapp and Salesforce users.

import * as ENV     from '../util/env';
import * as User    from '../store/user';
import { openURL }  from './cordova';
import { WebAuth }  from 'auth0-js';
import assert       from 'assert';
import Auth0Cordova from '@auth0/cordova';
import ms           from 'ms';
import PKCE         from './pkce';
import QS           from 'qs';
import retry        from 'async-retry';
import rollbar      from '../rollbar';
import store        from '../store';
import xhr          from 'xhr';


const webClientID     = 'NJ8IC9E3UESDwWhUEE4IVBYeFluqnV3Q';
const cordovaClientID = 'mywYcYFicd3EnL5A8x6xkJeUpKQbHpr7';

// Used for authenticating with Salesforce.
const adminClientID = 'ZnzG3XvNRNABhCqBy3mC24cYR02SSlG5';

const domain   = 'login.broadly.com';
const audience = 'https://webapp.broadly.com';

const webAuth = new WebAuth({
  domain,
  clientID: webClientID,
});

const allowedCallbackURIs = [
  '/qbo/reconnect',
];

const cordovaAuth = new Auth0Cordova({
  domain,
  clientId:          cordovaClientID,
  packageIdentifier: 'broadly', // found in config.xml
});

const cordovaRedirectURI = 'broadly://login.broadly.com/cordova/broadly/callback';
cordovaAuth.redirectUri  = cordovaRedirectURI;


// called by the DeepLinks component when returning from the auth0 login page
export function onDeepLink(url) {
  Auth0Cordova.onRedirectUri(url.href);
}


export async function authorize(params = {}) {
  const { verifier, challenge } = PKCE();
  window.localStorage.setItem('verifier', verifier);

  const options = {
    scope:               'openid profile email offline_access',
    responseType:        'code',
    codeChallenge:       challenge,
    codeChallengeMethod: 'S256',
  };

  if (ENV.isCordova() && !ENV.isPuppeteer())
    return authorizeCordova(options);
  else
    return authorizeWeb({ options, ...params });
}


// Initiate auth0 authorization process when in cordova.
// It opens a in-app browser window where the user signins.
// Once done, it signs in the user in the app, provided there were no errors.
async function authorizeCordova(params) {
  return new Promise(function(resolve, reject) {
    cordovaAuth.authorize({ audience, ...params }, function(err, authResult) {
      if (err)
        reject(err);

      const { accessToken } = extractAccessToken(authResult);
      store.dispatch(User.signIn({ accessToken }));
      resolve();
    });
  });
}



function authorizeWeb({ returnTo, intent, email, password, options }) {
  const state = QS.stringify({ returnTo, intent }, { skipNulls: true });

  // The only way to encode data and decode it in auth0 login page is through
  // the redirectUri.
  const redirectURI = getWebRedirectURI(returnTo, { intent, email, password });

  webAuth.authorize({
    redirectUri: redirectURI,
    state,
    ...options,
  });
}


export async function logout({ returnTo }) {
  if (!ENV.isCordova())
    logoutWeb({ returnTo });
}


// When in Cordova we can't just logout here.
// We need to logout inside the same external browser
// window we used to login (which is what the auth0 cordova sdk uses).
export function logoutCordova() {
  const returnTo = 'broadly://broadly.auth0.com/cordova/broadly/callback';
  const url      = `https://broadly.auth0.com/v2/logout?client_id=${cordovaClientID}&returnTo=${returnTo}`;
  openURL(url);
}


async function logoutWeb({ returnTo }) {
  webAuth.logout({
    clientID: webClientID,
    returnTo: `${window.location.origin}${returnTo || ''}`,
  });
}


function getWebRedirectURI(returnTo = '/', params = {}) {
  const url = allowedCallbackURIs.includes(returnTo)
    ? new URL(`${window.location.origin}${returnTo}`)
    : new URL(window.location.origin);
  for (const [ key, value ] of Object.entries(params)) {
    if (value)
      url.searchParams.set(key, value);
  }

  return url.toString();
}

// Extracts code and state from the query string.
function getOAuthCodeParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const code         = searchParams.get('code');
  const state        = searchParams.get('state');
  return { code, state };
}

export function isOAuthCallback() {
  return hasAuthorizationCode() || hasAccessToken();
}

// Auth0 can call us back with an authorization code or an access token.
// If it's an authorization code, we need to exchange it for an access token.
// If it's an access token, we just need to store it.
export async function getOAuthResult() {
  assert(isOAuthCallback());
  if (hasAccessToken())
    return extractAccessTokenFromQueryString();

  return await exchangeAuthorizationCode();
}


// Check whether there's an authorization code in the query string.
// Code can be success when user finishes verifying their email.
// If this is the case, then it's not aprt of the authorization flow.
function hasAuthorizationCode() {
  const { code } = getOAuthCodeParams();
  return !!code && code !== 'success';
}

// Check whether there's an access token in the hash
// This only happens when authenticating with salesforce,
// and with passwordless strategy.
function hasAccessToken() {
  const authResult = parseHashAsQueryString();
  return !!authResult.access_token;
}


// Puts together an accessToken entity from the authResult,
// either from web based auth or from cordova
// (it comes in camelCase format)
function extractAccessToken(authResult, stateEncoded) {
  const jwtToken = authResult?.access_token || authResult?.accessToken;

  if (jwtToken) {
    const state       = QS.parse(stateEncoded || authResult.state);
    const accessToken = {
      jwtToken,
      refreshToken: authResult.refresh_token || authResult.refreshToken,
      expiresIn:    Date.now() + ms('1h'),
      connection:   state.connection,
    };

    const returnTo = state.returnTo;
    const intent   = new URLSearchParams(window.location.search).get('intent');
    return {
      accessToken,
      returnTo,
      intent,
    };
  } else
    return {};
}


function extractAccessTokenFromQueryString() {
  const authResult  = parseHashAsQueryString();
  const accessToken = extractAccessToken(authResult);
  return accessToken;
}


// Exchanges the authorization code for an access token and refresh token.
async function exchangeAuthorizationCode() {
  const { code, state } = getOAuthCodeParams();
  const verifier        = window.localStorage.getItem('verifier');

  const redirectURI = ENV.isCordova() ? cordovaRedirectURI : getWebRedirectURI();

  const params = {
    client_id:     getClientID(),
    grant_type:    'authorization_code',
    code_verifier: verifier,
    code,
    redirect_uri:  redirectURI,
  };

  const authResult = await getOAuthToken(params);
  window.localStorage.removeItem('verifier');
  return extractAccessToken(authResult, state);
}

// Only used to passwordless sign in.
export async function authenticate({ username, password }) {
  const response = await getOAuthToken({
    grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
    audience:   'https://webapp.broadly.com',
    realm:      'webapp',
    scope:      'openid profile email offline_access',
    username,
    password,
  });

  const jwtToken = response.access_token;
  assert(jwtToken, 'Auth0 returns 200 and no access_token');
  const refreshToken = response.refresh_token; // eslint-disable-line no-shadow
  assert(refreshToken, 'Auth0 returns 200 and no refresh_token');
  const expiresIn = Date.now() + ms('15m');

  return { jwtToken, refreshToken, expiresIn };
}


export async function refreshToken(accessToken) {
  try {
    const response = await getOAuthToken({
      grant_type:    'refresh_token',
      refresh_token: accessToken.refreshToken,
    });

    const jwtToken = response.access_token;
    assert(jwtToken, 'Auth0 returns 200 and no access_token');
    return {
      jwtToken,
      refreshToken: accessToken.refreshToken,
      expiresIn:    Date.now() + ms('1h'),
    };
  } catch (error) {
    const isRevoked = (error.statusCode === 401);
    if (isRevoked)
      return { revoked: true };
    else
      throw error;
  }
}


// Kicks off authentication with Salesforce.
// Allows to impersonate a specific user by passing the user ID.
export function authenticateWithSalesforce({ userID } = {}) {
  const connection  = 'salesforce';
  const returnTo    = window.location.href;
  const state       = QS.stringify({ connection, returnTo, userID });
  const query       = {
    client_id:     adminClientID,
    connection,
    redirect_uri:  window.location.origin,
    state,
    response_type: 'token',
    audience:      'https://webapp.broadly.com',
    scope:         'openid api profile email offline_access',
  };
  const queryString = QS.stringify(query);
  const url         = `https://login.broadly.com/authorize?${queryString}`;
  window.location   = url;
}



export function needsSalseforceAuthentication() {
  const referer        = document.referrer && new URL(document.referrer);
  const usesSalesforce = referer && (isSalesforceReferer(referer) || isBroadlyAdminReferer(referer));
  return usesSalesforce && !isOAuthCallback();
}



function isSalesforceReferer(referer) {
  const { hostname } = referer;

  if (hostname) {
    const isVisualForce       = hostname.endsWith('.visual.force.com');
    const isBroadlySalesforce = hostname === 'broadly.my.salesforce.com';

    return isVisualForce || isBroadlySalesforce;
  } else
    return false;
}


function isBroadlyAdminReferer(referer) {
  const { hostname }   = referer;
  const isAdminReferer = hostname === 'admin.broadly.com' || hostname === 'admin.broadly.localhost';
  return isAdminReferer;
}


function parseHashAsQueryString() {
  return QS.parse(window.location.hash?.substring(1));
}


function getClientID() {
  return ENV.isCordova()
    ? cordovaClientID
    : webClientID;
}


const defaultRetryOptions = {};
const testRetryOptions    = { minTimeout: 0, retries: 1 };
const isTest              = (process.env.NODE_ENV === 'test');
const retryOptions        = isTest ? testRetryOptions : defaultRetryOptions;


async function getOAuthToken(params) {
  const response = await retry(async function() {
    return await new Promise(function(resolve, reject) {
      xhr({
        url:    'https://login.broadly.com/oauth/token',
        method: 'POST',
        body:   {
          client_id: getClientID(),
          ...params,
        },
        json:    true,
        timeout: ms('5s'),
      }, function(error, res) {
        if (error) {
          const authError      = new Error(`Authentication Error: ${error.message || error.code || error}`);
          authError.statusCode = error.statusCode;
          authError.name       = 'AuthenticationError';
          reject(authError);
        } else
          resolve(res);
      });
    });
  }, retryOptions);

  const { body } = response;
  if (response.statusCode === 200)
    return body;
  else if (body && body.error === 'invalid_grant') {
    // OAuth spec says if refresh token is invalid, expired or revoked,
    // then the status code would be 400, and the error code would be
    // 'invalid_grant': https://tools.ietf.org/html/rfc6749#section-5.2
    //
    // Auth0 responds with status code 403, but the correct error code.
    const accessError      = new Error(body.error);
    accessError.statusCode = 401;
    throw accessError;
  } else {
    const message          = body
      ? (body.message || JSON.stringify(body))
      : response.statusCode;
    const statusError      = new Error(message);
    statusError.statusCode = response.statusCode;
    throw statusError;
  }
}


export function isPasswordlessAuthentication() {
  const { pathname }       = window.location;
  const isPasswordlessPath = pathname.startsWith('/signin/passwordless');

  if (!isPasswordlessPath)
    return false;

  const { username, password } = getPasswordlessCredentials();
  const hasNeededQueryString   = !!username && !!password;

  return hasNeededQueryString;
}


export function getPasswordlessCredentials() {
  const params   = new URLSearchParams(window.location.search);
  const username = params.get('username');
  const password = params.get('token');

  return {
    username,
    password,
  };
}


export function passwordless(phone) {
  return new Promise(function(resolve, reject) {
    xhr({
      url:    `${API_URL}/passwordless`,
      method: 'POST',
      body:   {
        phone,
      },
      json: true,
    }, function(error, response) {
      if (error) {
        rollbar.error(error);
        reject(error);
      } else if (response.statusCode === 200)
        resolve();
      else
        reject();
    });
  });
}
