import { lazy }   from 'react';
import renderPage from '../../components/page';

const SettingsTab   = lazy(() => import(/* webpackChunkName: 'settings' */ '../settings/settings_tab'));
const MainPage      = lazy(() => import(/* webpackChunkName: 'settings' */ './data_sources_page'));
const ConfigurePage = lazy(() => import(/* webpackChunkName: 'settings' */ './configure_page'));


const tabComponent = {
  component: renderPage(SettingsTab),
  name:      'settings',
  className: 'settings-tab',
};

export default {
  path:      'data_sources',
  component: renderPage(MainPage),
  className: 'data-sources-page',
  name:      'DataSources',
  tabComponent,
  routes:    [
    {
      path:      ':name',
      name:      'DataSourcesConfigure',
      component: renderPage(ConfigurePage),
      className: 'data-sources-configure-page',
      tabComponent,
    },
  ],
};
