import './signup_background.less';

import BroadlyLogo from '../../assets/broadly_logo.svg';
import React       from 'react';
import Sparkle     from '../../components/sparkle';


export default function SignupBackground() {
  return (
    <Sparkle invert className="signup-background">
      <BroadlyLogo/>
    </Sparkle>
  );
}
