import * as Hooks     from './hooks';
import * as Selectors from './selectors';


export function useIsGoogleLocationComplete(businessID) {
  const business = Hooks.useBusiness(businessID);
  return !!business?.google?.id;
}

export function useIsDataSourceComplete(businessID) {
  const integrationName = Hooks.useIntegrationName(businessID);
  return !!integrationName;
}

export function useIsSmsRegistrationComplete(businessID) {
  const twilioA2PStatus = Hooks.useTwilioA2PStatus(businessID);
  return twilioA2PStatus?.status === 'completed';
}

export function useIsWebChatComplete(businessID) {
  const business                  = Hooks.useBusiness(businessID);
  const hasWebchatInstalledNotice = Hooks.useHasNotice(businessID, Selectors.webchatInstalledNoticeName);
  // need to ensure business has loaded before we can trust this value
  return !!business && !hasWebchatInstalledNotice;
}

export const checklistNames = {
  getStartedGoogleLocation:  'getstarted:googlelocation',
  getStartedIntegration:     'getstarted:integration',
  getStartedWebchat:         'getstarted:webchat',
  getStartedSmsRegistration: 'getstarted:twilioA2P',
};

export function useCheckListCount(businessID) {
  const googleLocationItem  = Hooks.useChecklistItem(businessID, checklistNames.getStartedGoogleLocation);
  const integrationItem     = Hooks.useChecklistItem(businessID, checklistNames.getStartedIntegration);
  const webchatItem         = Hooks.useChecklistItem(businessID, checklistNames.getStartedWebchat);
  const smsRegistrationItem = Hooks.useChecklistItem(businessID, checklistNames.getStartedSmsRegistration);
  const hasWebchatFeature   = Hooks.useHasFeature(businessID, 'webchat');
  const canRegisterForA2P   = Hooks.useCanRegisterForA2P(businessID);

  const isGoogleLocationComplete  = useIsGoogleLocationComplete(businessID);
  const isDataSourceComplete      = useIsDataSourceComplete(businessID);
  const isWebChatComplete         = useIsWebChatComplete(businessID);
  const isSmsRegistrationComplete = useIsSmsRegistrationComplete(businessID);

  const items = [
    isGoogleLocationComplete || googleLocationItem?.checked,
    isDataSourceComplete || integrationItem?.checked,
    !canRegisterForA2P || isSmsRegistrationComplete || smsRegistrationItem?.checked,
    !hasWebchatFeature || isWebChatComplete || webchatItem?.checked,
  ];

  const complete = items.filter(Boolean);

  return items.length - complete.length;
}
