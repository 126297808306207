import { lazy }     from 'react';
import GoogleRoutes from '../settings/google/routes';
import renderPage   from '../../components/page';

const ReviewsPage = lazy(() => import(/* webpackChunkName: 'reviews' */ './reviews_page'));


const tabComponent = {
  component: renderPage(ReviewsPage),
  className: 'reviews-page',
  name:      'reviews',
};

export default {
  name:      'Reviews',
  path:      '/:businessID/reviews',
  component: renderPage(ReviewsPage, { tabPage: true }),
  className: 'reviews-page',
  tabComponent,
  routes:    [
    GoogleRoutes({ tabComponent }),
  ],
};
