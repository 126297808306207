//  Push messaging consists of three components:
//
//  1.  On the server, we use the FCM HTTP API to push new messages
//  2.  Service Worker receives these messages, and it will show a notification
//      message when app is in background; data messages are forwarded here, as
//      are notification messages received when app in foreground
//  3.  This component will either show notification message in-app (since app
//      is in foreground), or act on data message
//
// Hybrid apps use the https://github.com/phonegap/phonegap-plugin-push API.

import * as Business   from '../store/business';
import * as User       from '../store/user';
import { isCordova }   from '../util/env';
import Cordova         from './cordova';
import React           from 'react';
import useCurrentRoute from '../hooks/use_current_route';
import useRouteParams  from '../hooks/use_route_params';
import Web             from './web';


// eslint-disable-next-line multiline-comment-style
/*
Push component hierarchy:

<Push>
  <Cordova/>
</Push>

-or-

<Push>
  <Web>
    <Twilio/>
    <Firebase/>
  </Web>
</Push>
*/


export default function Push() {
  const route           = useCurrentRoute();
  const { businessID }  = useRouteParams();
  const profile         = User.useProfile();
  const isAnonymous     = User.useIsAnonymous();
  const isPendingSignup = User.useIsSignupPending();
  const business        = Business.useBusiness(businessID);

  const shouldInitialize = shouldInitializePush({ route, businessID, profile, business, isAnonymous, isPendingSignup });

  if (shouldInitialize)
    return <InitializePlatform/>;
  else
    return null;
}


function InitializePlatform() {
  if (isCordova())
    return <Cordova/>;
  else
    return <Web/>;
}


export function shouldInitializePush({ route, businessID, profile, business, isAnonymous, isPendingSignup }) {
  const isSignupRoute   = route?.path.startsWith('/signup');
  const isBusinessRoute = !!businessID;

  const profileReady     = !!profile;
  const businessReady    = !!(business || !isBusinessRoute);
  const routeReady       = !!route;
  const isReady          = routeReady && profileReady && businessReady;
  const shouldInitialize = isReady && !(isSignupRoute || isAnonymous || isPendingSignup);

  return shouldInitialize;
}

// Note on sign out behavior:
// When user signs out of the app, we want to stop showing them push
// notifications. Emphasis on the "showing".
//
// We can ask the server to remove the device token, that way we're not sending
// push messages … if the request went through to the server, it updated the
// database, etc.  Distributed systems are hard.
//
// We can tell the browser to discard the device token, that's immediate and
// 100% likely to prevent push notifications from showing up.
//
// Note: if the user "signed out" by deleting their storage, then they'll still
// get push notification.
//
// Each platform component (Cordova/Twilio/Firebase) renders an OnSignedOut
// component which makes the appropriate shutdown calls when the user signs out.
