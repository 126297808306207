import './app_layout.less';

import * as Business     from '../store/business';
import * as UI           from '../store/ui';
import * as User         from '../store/user';
import { isPuppeteer }   from '../util/env';
import { useAppContext } from './app_provider';
import { View }          from 'framework7-react';
import bail              from '../util/bail';
import classnames        from 'classnames';
import logEvent          from '../util/log_event';
import LoginRoutes       from '../pages/login/routes';
import React             from 'react';
import RouteBackground   from './route_background';
import routes            from './routes';
import RouteTab          from './route_tab';
import SignupRoutes      from '../pages/signup/routes';
import store             from '../store';


// Avoid many issues in tests.
const animate = !isPuppeteer();


export default function AppLayout() {
  const { setRouter, setRoute } = useAppContext();
  const isMenuOpen              = UI.useMenuIsOpen();

  const mainViewParams = {
    // TODO don't need this when running in Cordova.
    pushState:           true,
    pushStateSeparator:  '',
    pushStateRoot:       window.location.origin,
    url:                 window.location.origin,
    animate,
    animateWithJS:       false,
    stackPages:          false,
    preloadPreviousPage: false,
    reloadCurrent:       true,
    routesBeforeEnter:   [
      checkAnonymous,
      checkAuthenticated,
      checkSignupStatus,
      handleGoogleOAuthCallback,
      handleExternalLinks,
      handleBusinessRoute(checkLocationAccess),
    ],
    routes,
    on: {
      viewInit() {
        setRouter(this.router);
      },
      routeChange(currentRoute) {
        setRoute(currentRoute);
        logPageViewEvent(currentRoute);
      },
    },
  };

  const className = classnames('app-layout', {
    'menu-open': isMenuOpen,
  });

  return (
    <div className={className}>
      <RouteBackground/>
      <RouteTab/>
      <View className="pages-panel" main {...mainViewParams}/>
    </div>
  );
}

/* eslint-disable multiline-comment-style */
/*
MainView Layouts

 default layout
+-----------+-------------------+----------------------------------------------+
| menu      | tab               | page                                         |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
+-----------+-------------------+----------------------------------------------+

 only-tab layout - used for the top-level route in a section. this affects
 behavior of the mobile breakpoint, so we can slide the tab out of view when the
 user clicks a child page.
+-----------+-------------------+----------------------------------------------+
| menu      | tab               | (empty page)                                 |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
|           |                   |                                              |
+-----------+-------------------+----------------------------------------------+

 no-tab layout - used for pages with no top-level tab
+-----------+------------------------------------------------------------------+
| menu      | page                                                             |
|           |                                                                  |
|           |                                                                  |
|           |                                                                  |
|           |                                                                  |
|           |                                                                  |
|           |                                                                  |
|           |                                                                  |
+-----------+------------------------------------------------------------------+

full-page layout - used for pages that fill the screen, no tab or menu
+------------------------------------------------------------------------------+
| full screen page                                                             |
|                                                                              |
|                                                                              |
|                                                                              |
|                                                                              |
|                                                                              |
|                                                                              |
|                                                                              |
+------------------------------------------------------------------------------+

*/
/* eslint-enable multiline-comment-style */


function logPageViewEvent(route) {
  if (route.name) {
    const eventName  = `View${route.name}`;
    const { params } = route;
    logEvent(eventName, params);
  }
}

/* eslint-disable max-params */

// middleware to apply a handler to routes with a businessID param
function handleBusinessRoute(handler) {
  return function(to, from, resolve, reject) {
    const { businessID } = to.params;
    if (businessID)
      handler.call(this, to, from, resolve, reject); // this is router
    else
      resolve();
  };
}


// checkLocationAccess
// - if they’re a regular user, they can access the location if they have a role
//   and the location is active
// - if they’re a salesforce admin not impersonating a user, they can access any
//   location
// - if they’re a salesforce admin impersonating a user, they’re subject to the
//   same restrictions as that user
function checkLocationAccess(to, from, resolve, reject) {
  const { businessID }   = to.params;
  const state            = store.getState();
  const roles            = User.getRole(state, businessID);
  const hasRole          = (roles?.length > 0);
  const isActiveLocation = User.isLocationActive(state, businessID);
  const userAccess       = hasRole && isActiveLocation;
  const isSalesforce     = User.isSalesforce(state);
  const isImpersonating  = User.isImpersonating(state);
  const salesforceAccess = isSalesforce && !isImpersonating;

  if (userAccess || salesforceAccess) {
    // load the business, if not yet loaded
    store.dispatch(Business.setBusinessID(businessID));
    resolve();
  } else {
    reject();
    redirectToFirstActiveLocation(this); // this is router
  }
}


function redirectToFirstActiveLocation(router) {
  const activeLocations = User.getActiveLocations(store.getState());
  const firstLocation   = activeLocations?.[0];

  if (firstLocation)
    router.navigate(`/${firstLocation.id}/inbox`, { reloadAll: true });
  else
    bail();
}


function checkAuthenticated(to, from, resolve, reject) {
  const isSignedIn = User.isSignedIn(store.getState());

  if (isSignedIn) {
    resolve();
    return;
  }

  const isPublicRoute = to.route.public;

  if (isPublicRoute)
    resolve();
  else {
    reject();
    // `this` is the Router
    this.navigate(LoginRoutes.path, { props: { returnTo: to.url } });
  }
}

const anonymousPath = '/account/unknown';

function checkAnonymous(to, from, resolve, reject) {
  const isAnonymous     = User.isAnonymous(store.getState());
  const isSignupPending = User.isSignupPending(store.getState());

  const shouldRedirectToAnonymousPath = isAnonymous && !isSignupPending;
  if (shouldRedirectToAnonymousPath && to.path !== anonymousPath) {
    reject();
    this.navigate(anonymousPath); // `this` is the Router
  } else
    resolve();
}


function handleGoogleOAuthCallback(to, from, resolve, reject) {
  const isCallbackRoute = to?.path.includes('oauth2callback');

  if (isCallbackRoute)
    reject();
  else
    resolve();
}


function handleExternalLinks(to, from, resolve, reject) {
  // this flag is being set in the Link component
  const { isOpeningExternalLink } = window;

  if (isOpeningExternalLink)
    reject();
  else
    resolve();
}


function checkSignupStatus(to, from, resolve, reject) {
  const isSignupPending = User.isSignupPending(store.getState());
  const isSignupPath    = to.path.startsWith(SignupRoutes.path);

  if (isSignupPending && !isSignupPath) {
    reject();
    this.navigate(SignupRoutes.path); // `this` is the Router
  } else
    resolve();
}

/* eslint-enable max-params */
