import * as Resources       from '../../util/resources';
import * as Selectors       from './selectors';
import { actionTypes }      from 'redux-resource';
import createActionCreators from 'redux-resource-action-creators';


export const remote = {
  LOAD_REVIEWS: silentlyLoadFeedback,
};


export function readFeedback(businessID) {
  return async function(dispatch, getState) {
    // This action is throttled so we can only request one page at a time.
    const status = Selectors.getFeedbackReadStatus(getState(), businessID);
    if (status.pending)
      return;

    const url = new URL(`${API_URL}/business/${businessID}/feedback`);
    url.searchParams.set('include', 'review');
    url.searchParams.set('limit', '50');

    const cursor = Selectors.getNextCursor(getState(), businessID);
    if (cursor)
      url.searchParams.set('cursor', cursor);

    dispatch(Resources.readResources({
      url:           url.toString(),
      resourceType:  'feedback',
      list:          businessID,
      requestKey:    `read-${businessID}`,
      transformData: ({ feedback, pageInfo }) => {
        // Store feedback pageInfo as redux metadata, indexed by businessID
        dispatch(updatePageInfo(businessID, pageInfo));

        const existing = Selectors.getFeedback(getState(), businessID);
        return [ ...existing, ...feedback ];
      },
    }));
  };
}


export function updatePageInfo(businessID, pageInfo) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      meta: {
        feedback: {
          pageInfo: {
            [businessID]: pageInfo,
          },
        },
      },
    });
  };
}


export function resetFeedback(businessID) {
  return async function(dispatch, getState) {
    const feedback = Selectors.getFeedback(getState(), businessID).map(({ id }) => id);
    dispatch({
      type:      actionTypes.DELETE_RESOURCES,
      resources: { feedback },
    });
    dispatch(updatePageInfo(businessID, { nextCursor: null }));
  };
}


export function respondToReview({ businessID, reviewID, body }) {
  return async function(dispatch, getState) {
    const respondResponse = await dispatch(Resources.request({
      url:    `${API_URL}/review/${reviewID}/respond`,
      method: 'POST',
      body:   { body },
    }));

    const { id, response } = respondResponse.body;
    const currentReview    = Selectors.getFeedbackItem(getState(), `review:${id}`);
    const updatedReview    = {
      ...currentReview,
      response,
    };

    const updateReviewAction = createActionCreators('update', {
      resourceType: 'feedback',
      resources:    [ updatedReview ],
      list:         businessID,
    });

    dispatch(updateReviewAction.succeeded());
  };
}


export function silentlyLoadFeedback(businessID) {
  return async function(dispatch) {
    dispatch(Resources.silentlyReadResources({
      url:           `${API_URL}/business/${businessID}/feedback?include=review&limit=10`,
      resourceType:  'feedback',
      list:          businessID,
      requestKey:    `read-${businessID}`,
      transformData: ({ feedback }) => [ ...feedback ],
    }));
  };
}
