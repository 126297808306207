export default function getShortName(name) {
  if (!name)
    return null;

  const [ firstName, lastName ] = name.split(' ');
  const lastNameIsShortEnough   = lastName && lastName.length === 1;
  const initial                 = lastName?.slice(0, 1);
  const isValidInitial          = initial && /[a-zA-Z0-9]/.test(initial);

  if (lastNameIsShortEnough)
    return `${firstName} ${initial}`;
  else if (isValidInitial)
    return `${firstName} ${initial}.`;
  else
    return firstName;
}
