// Provides haptic feedback on iOS.
//
// https://github.com/EddyVerbruggen/cordova-plugin-taptic-engine


// Supported types are "success", "warning", and "error".
export function notification(type) {
  window.TapticEngine?.notification({ type });
}


export function selection(fn) {
  if (fn) {
    return () => {
      selection();
      fn();
    };
  } else {
    window.TapticEngine?.selection();
    return null;
  }
}
