import * as Actions    from './actions';
import * as Selectors  from './selectors';
import { compose }     from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import React           from 'react';


// -- Business Hooks --

export function useBusiness(businessID) {
  return useSelector(state => Selectors.getBusiness(state, businessID));
}

export function useBusinessStatuses(businessID) {
  return useSelector(state => Selectors.getBusinessStatuses(state, businessID));
}

export function useTeam(businessID) {
  return useSelector(state => Selectors.getTeam(state, businessID));
}

export function useTeamStatuses(businessID) {
  return useSelector(state => Selectors.getTeamStatuses(state, businessID));
}

export function useCurrentProvider(businessID) {
  return useSelector(state => Selectors.getCurrentProvider(state, businessID));
}

export function useProviders(businessID) {
  return useSelector(state => Selectors.getProviders(state, businessID));
}

export function useBannerImage(businessID) {
  return useSelector(state => Selectors.getBannerImage(state, businessID));
}

export function useClassifySetting(businessID) {
  return useSelector(state => Selectors.getClassifySetting(state, businessID));
}

export function useIsHostedNumberComplete(businessID) {
  return useSelector(state => Selectors.isHostedNumberComplete(state, businessID));
}

export function useConnectedPhoneNumber(businessID) {
  return useSelector(state => Selectors.getConnectedPhoneNumber(state, businessID));
}

export function usePaymentsSettings(businessID) {
  return useSelector(state => Selectors.getPaymentsSettings(state, businessID));
}

export function useGoogleLocationBadgeCount(businessID) {
  return useSelector(state => Selectors.getGoogleLocationBadgeCount(state, businessID));
}

export function useFacebookLocationBadgeCount(businessID) {
  return useSelector(state => Selectors.getFacebookLocationBadgeCount(state, businessID));
}

export function useWebchatBadgeCount(businessID) {
  return useSelector(state => Selectors.getWebchatBadgeCount(state, businessID));
}

export function useSettingsBadgeCount(businessID) {
  return useSelector(state => Selectors.getSettingsBadgeCount(state, businessID));
}

export function useGMBProfile(businessID) {
  return useSelector(state => Selectors.getGMBProfile(state, businessID));
}

export function useGoogleProfile(businessID) {
  return useSelector(state => Selectors.getGoogleProfile(state, businessID));
}

export function useIsGMBConnected(businessID) {
  return useSelector(state => Selectors.getIsGMBConnected(state, businessID));
}

export function useNextdoorProfile(businessID) {
  return useSelector(state => Selectors.getNextdoorProfile(state, businessID));
}

export function useTripAdvisorProfile(businessID) {
  return useSelector(state => Selectors.getTripAdvisorProfile(state, businessID));
}

export function useYelpProfile(businessID) {
  return useSelector(state => Selectors.getYelpProfile(state, businessID));
}

export function useRooms(businessID) {
  return useSelector(state => Selectors.getRooms(state, businessID));
}

export function useUsers(businessID) {
  return useSelector(state => Selectors.getUsers(state, businessID));
}

export function useHasMaxReviewRequests(businessID) {
  return useSelector(state => Selectors.hasMaxReviewRequests(state, businessID));
}

export function useReviewRequestsLimit(businessID) {
  return useSelector(state => Selectors.getReviewRequestsLimit(state, businessID));
}

export function useBusinessName(businessID) {
  return useSelector(state => Selectors.getBusinessName(state, businessID));
}

export function useHasReachedCampaignsRecipientsLimit(businessID) {
  return useSelector(state => Selectors.hasReachedCampaignsRecipientsLimit(state, businessID));
}

export function useCampaignsRecipientsLimit(businessID) {
  return useSelector(state => Selectors.getCampaignsRecipientsLimit(state, businessID));
}

export function useHasNotice(businessID, noticeName) {
  return useSelector(state => Selectors.hasNotice(state, businessID, noticeName));
}

export function useIntegrationName(businessID) {
  return useSelector(state => Selectors.getIntegrationName(state, businessID));
}

const integrationsWithAppointmentReminders = [
  'mitchellManager',
  'roWriter',
  'shopMonkey',
  'shopWare',
  'tekmetric',
];

export function useHasAppointmentReminders(businessID) {
  const integrationName         = useIntegrationName(businessID);
  const hasAppointmentReminders = integrationsWithAppointmentReminders.includes(integrationName);
  return hasAppointmentReminders;
}

const integrationsWithServiceReminders = [
  'mitchellManager',
  'roWriter',
  'shopMonkey',
  'shopWare',
  'tekmetric',
];

export function useHasServiceReminders(businessID) {
  const integrationName     = useIntegrationName(businessID);
  const hasServiceReminders = integrationsWithServiceReminders.includes(integrationName);
  return hasServiceReminders;
}


export function useSettings(businessID) {
  return useSelector(state => Selectors.getSettings(state, businessID));
}

export function useBusinessOrganization(businessID) {
  return useSelector(state => Selectors.getBusinessOrganization(state, businessID));
}

export function useContact(businessID) {
  return useSelector(state => Selectors.getContact(state, businessID));
}

export function useDefaults(businessID) {
  return useSelector(state => Selectors.getDefaults(state, businessID));
}

export function useDataSources(businessID) {
  return useSelector(state => Selectors.getDataSources(state, businessID));
}

export function useDataSourcesStatuses(businessID) {
  return useSelector(state => Selectors.getDataSourcesReadStatus(state, businessID));
}

export function useThankYouDelay(businessID) {
  return useSelector(state => Selectors.getThankYouDelay(state, businessID));
}

export function useThankYouRepeat(businessID) {
  return useSelector(state => Selectors.getThankYouRepeat(state, businessID));
}

export function useHostedNumber(businessID) {
  return useSelector(state => Selectors.getHostedNumber(state, businessID));
}

export function useTwilioNumber(businessID) {
  return useSelector(state => Selectors.getTwilioNumber(state, businessID));
}

export function useTwilioA2PStatus(businessID) {
  return useSelector(state => Selectors.getTwilioA2PStatus(state, businessID));
}

export function useTwilioA2P(businessID) {
  return useSelector(state => Selectors.getTwilioA2P(state, businessID));
}

export function useTwilioA2PStatuses(businessID) {
  return useSelector(state => Selectors.getTwilioA2PStatuses(state, businessID));
}

export function useCanRegisterForA2P(businessID) {
  return useSelector(state => Selectors.canRegisterForA2P(state, businessID));
}

export function useIsNPS(businessID) {
  const classify = useClassifySetting(businessID);
  return (classify === 'nps');
}

export function useChecklist(businessID) {
  return useSelector(state => Selectors.getChecklist(state, businessID));
}

export function useChecklistItem(businessID, key) {
  return useSelector(state => Selectors.getChecklistItem(state, businessID, key));
}

export function useHasFeature(businessID, feature) {
  return useSelector(state => Selectors.getHasFeature(state, businessID, feature));
}

export function useIsRestricted(businessID) {
  return useSelector(state => Selectors.getIsRestricted(state, businessID));
}

export function useIsFreemium(businessID) {
  return useSelector(state => Selectors.getIsFreemium(state, businessID));
}

export function useIsVerified(businessID) {
  const status = useSelector(state => Selectors.getVerificationStatus(state, businessID));
  return (status === 'verified');
}

export function useIsPartiallyVerified(businessID) {
  const status = useSelector(state => Selectors.getVerificationStatus(state, businessID));
  return (status === 'partially-verified');
}

export function useIsNotVerified(businessID) {
  const status = useSelector(state => Selectors.getVerificationStatus(state, businessID));
  return (status === 'not-verified');
}


// Actions

export function useLoadBusiness(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.loadBusiness, () => businessID),
    [ dispatch, businessID ],
  );
}

export function useReadTeam(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.readTeam, () => businessID),
    [ dispatch, businessID ],
  );
}

export function useInviteProvider(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, provider => Actions.inviteProvider({ businessID, provider })),
    [ dispatch, businessID ],
  );
}

export function useReadDataSources(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.readDataSources, () => businessID),
    [ dispatch, businessID ],
  );
}

export function useUpdateBusiness(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.updateBusiness, business => ({ businessID, ...business })),
    [ dispatch, businessID ],
  );
}

export function useUpdateUser(businessID, params) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.updateUser, user => ({ businessID, user, params })),
    [ dispatch, businessID, params ],
  );
}

export function useRemoveUser(businessID, params) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.removeUser, user => ({ businessID, user, params })),
    [ dispatch, businessID, params ],
  );
}

export function useResendInvite(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.resendInvite, user => ({ businessID, user })),
    [ dispatch, businessID ],
  );
}

export function useSetTips(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.setTips, enabled => ({ businessID, enabled })),
    [ dispatch, businessID ],
  );
}

export function useDismissNotice(businessID, name) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.dismissNotice, () => ({ businessID, name })),
    [ dispatch, businessID, name ],
  );
}

export function useSetAppointmentReminders(businessID) {
  return compose(
    useDispatch(),
    Actions.setAppointmentReminders,
    ({ enabled, offset, dropOffWindow }) => ({ businessID, enabled, offset, dropOffWindow }),
  );
}

export function useSetServiceReminders(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.setServiceReminders, ({ enabled }) => ({ businessID, enabled })),
    [ dispatch, businessID ],
  );
}

export function useSetSmsFollowUp(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.setSmsFollowUp, enabled => ({ businessID, enabled })),
    [ dispatch, businessID ],
  );
}

export function useSetClassify(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.setClassify, classifyOption => ({ businessID, classifyOption })),
    [ dispatch, businessID ],
  );
}

export function useSetThankYouRepeat(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.setThankYouRepeat, repeat => ({ businessID, repeat })),
    [ dispatch, businessID ],
  );
}

export function useSetSchedule(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.setSchedule, params => ({ businessID, ...params })),
    [ dispatch, businessID ],
  );
}

export function useUpdateBusinessSettings(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.updateBusinessSettings, settings => ({ businessID, settings })),
    [ dispatch, businessID ],
  );
}

export function useSetChat(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.setChat, params => ({ businessID, ...params })),
    [ dispatch, businessID ],
  );
}

export function useDeleteDataSource(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.deleteDataSource, name => ({ businessID, name })),
    [ dispatch, businessID ],
  );
}

export function useGetDataSourceAuthorizationURL(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.readDataSourceAuthorizationURL, name => ({ businessID, name })),
    [ dispatch, businessID ],
  );
}

export function useSaveGoogleLocation(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.saveGoogleLocation, locationInfo => ({ businessID, locationInfo })),
    [ dispatch, businessID ],
  );
}

export function useDisconnectLocation(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.disconnectGoogleLocation, () => businessID),
    [ dispatch, businessID ],
  );
}

export function useSaveGooglePlace(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.updateGooglePlace, ({ cid, place }) => ({ businessID, cid, place })),
    [ dispatch, businessID ],
  );
}

export function useDisconnectPlace(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.disconnectGooglePlace, () => ({ businessID })),
    [ dispatch, businessID ],
  );
}

export function useUpdateChecklistItem(businessID) {
  const dispatch = useDispatch();
  return React.useMemo(
    () => compose(dispatch, Actions.updateChecklistItem, (name, checked) => ({ businessID, name, checked })),
    [ dispatch, businessID ],
  );
}
