import * as Actions        from './actions';
import * as Selectors      from '../../store/conversations/selectors';
import { compose }         from 'redux';
import { useDispatch }     from 'react-redux';
import { useResourceList } from '../../util/webapp';
import { useSelector }     from 'react-redux';
import addSearchFields     from './add_search_fields';
import React               from 'react';


// Selectors

export function useConversations(businessID) {
  const { data, ...swr } = useResourceList(`/business/${businessID}/conversations`, {
    name:            'conversations',
    sizeOfFirstPage: 100,
    sizeOfNthPage:   1000,
  });

  const dataWithSearchFields = React.useMemo(() => {
    return data?.sort(sortByLastActive).map(addSearchFields);
  }, [ data ]);

  return { data: dataWithSearchFields, ...swr };
}


function sortByLastActive(a, b) {
  if (a.lastActive < b.lastActive)
    return 1;
  else if (a.lastActive > b.lastActive)
    return -1;
  else
    return 0;
}


export function useConversation(conversationID) {
  return useSelector(state => Selectors.getConversation(state, conversationID));
}


export function useConversationStatuses(conversationID) {
  return useSelector(state => Selectors.getConversationStatuses(state, conversationID));
}


export function useActivities(conversationID) {
  return useSelector(state => Selectors.getActivities(state, conversationID));
}


// Actions

export function useReadConversation({ businessID, conversationID }) {
  return compose(useDispatch(), Actions.readConversation, () => ({ businessID, conversationID }));
}


export function useFollowConversation({ businessID, conversationID }) {
  return compose(useDispatch(), Actions.followConversation, () => ({ businessID, conversationID }));
}


export function useUnfollowConversation({ businessID, conversationID }) {
  return compose(useDispatch(), Actions.unfollowConversation, () => ({ businessID, conversationID }));
}


export function useMarkConversationAsRead() {
  return compose(useDispatch(), Actions.markConversationAsRead);
}


export function useMarkConversationAsUnread() {
  return compose(useDispatch(), Actions.markConversationAsUnread);
}


export function useAssignTeamMember({ businessID, conversationID }) {
  return compose(useDispatch(), Actions.assignTeamMember, userID => ({ businessID, conversationID, userID }));
}


export function useUpdateConversationNote() {
  return compose(useDispatch(), Actions.updateConversationNote);
}

export function useSnoozeConversation() {
  return compose(useDispatch(), Actions.snoozeConversation);
}
