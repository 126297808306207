import * as Business           from '../business';
import { getListReadStatus }   from '../../util/get_resource_statuses';
import { getResources }        from 'redux-resource';
import { getResourceStatuses } from '../../util/get_resource_statuses';
import { getStatus }           from 'redux-resource';
import { isAfter }             from 'date-fns';
import { subDays }             from 'date-fns';


export function getContacts(state, businessID, filter = () => true) {
  const customers = getResources(state.customers, businessID);
  const team      = Business.getTeam(state, businessID);

  if (team)
    return customers.map(addNamesToUsers).filter(filter);
  else
    return customers.filter(filter);

  function addNamesToUsers(customer) {
    const assignees    = customer?.conversation?.assignees ?? [];
    const participants = customer?.conversation?.participants ?? [];

    return {
      ...customer,
      conversation: {
        ...customer.conversation,
        participants: addUserNames(participants),
        assignees:    addUserNames(assignees),
      },
    };

    function addUserNames(list) {
      return list.map(item => {
        return {
          ...item,
          user: {
            id:   item.user.id,
            name: getUserName(item.user.id),
          },
        };
      });
    }

    function getUserName(userID) {
      const teamUser = team.users.find(u => u.id === userID);
      return teamUser?.name;
    }
  }
}

export function getEmailContacts(state, businessID) {
  const contacts          = getContacts(state, businessID);
  const contactsWithEmail = contacts.filter(customer => hasEmail(customer));
  return contactsWithEmail;
}

export function hasEmail(customer) {
  return customer.contact?.email?.address;
}


export function hasCampaignEmail(customer) {
  return customer.contact?.email?.address && customer.campaigns?.subscribed;
}


export function hasPhone(customer) {
  return customer.contact?.phone?.number;
}


export function hasCampaignPhone(customer) {
  return customer.contact?.phone?.number && customer.campaigns?.subscribed;
}



function getContactsReadStatusCode(state, businessID) {
  return state.customers.requests[`read-${businessID}`]?.statusCode;
}


export function getContactsStatuses(state, businessID) {
  return {
    createStatus:   {},
    deleteStatus:   {},
    readStatus:     getListReadStatus(state, 'customers', businessID),
    readStatusCode: getContactsReadStatusCode(state, businessID),
    updateStatus:   {},
  };
}

export function getContact(state, customerID) {
  return getResources(state.customers, [ customerID ])[0];
}

export function getContactReadStatus(state, customerID) {
  return getStatus(state.customers, `meta[${customerID}].readStatus`);
}

export function getContactsCreateStatus(state, businessID) {
  return getStatus(state.customers, `requests.create-${businessID}.status`);
}

export function getContactUpdateStatus(state, customerID) {
  return getStatus(state.customers, `meta[${customerID}].updateStatus`);
}

export function getRecentContacts(state, businessID) {
  const customers = getContacts(state, businessID);
  const sorted    = customers.sort(byMostRecentlyActive);

  return sorted;
}


export function getFollowingContacts(state, businessID, currentUserID) {
  const customers = getContacts(state, businessID);
  const sorted    = customers
    .filter(isAssignedToCurrentUser)
    .sort(byMostRecentlyActive);

  return sorted;

  function isAssignedToCurrentUser(customer) {
    const participants = customer?.conversation?.participants ?? [];
    return participants.some(participant => participant.user.id === currentUserID);
  }
}


export function getContactStatuses(state, customerID) {
  return getResourceStatuses(state.customers, customerID);
}

export function getLeadsAddedDaysAgo(state, businessID, daysAgo) {
  const customers = getContacts(state, businessID);
  const now       = new Date();
  const dateAgo   = subDays(now, daysAgo);
  return customers
    .filter(customer => customer.lead)
    .filter(customer => isAfter(new Date(customer.createdAt), dateAgo));
}

function byMostRecentlyActive(a, b) {
  if (a.lastActive < b.lastActive)
    return 1;
  else if (a.lastActive > b.lastActive)
    return -1;
  else
    return 0;
}
