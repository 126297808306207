import { getResources }        from 'redux-resource';
import { getResourceStatuses } from '../../util/get_resource_statuses';
import { getStatus }           from 'redux-resource';


export function getConversations(state, businessID) {
  return getResources(state.conversations, businessID);
}

export function getConversation(state, conversationID) {
  return getResources(state.conversations, [ conversationID ])[0];
}

export function getConversationReadStatus(state, conversationID) {
  return getStatus(state.conversations, `meta[${conversationID}].readStatus`);
}

export function getConversationsCreateStatus(state, businessID) {
  return getStatus(state.conversations, `requests.create-${businessID}.status`);
}

export function getConversationUpdateStatus(state, conversationID) {
  return getStatus(state.conversations, `meta[${conversationID}].updateStatus`);
}

export function getActivities(state, conversationID) {
  const [ conversation ] = getResources(state.conversations, [ conversationID ]);
  return conversation?.activities;
}

export function getConversationStatuses(state, conversationID) {
  return getResourceStatuses(state.conversations, conversationID);
}
