import { lazy }   from 'react';
import renderPage from '../../components/page';

const ScratchpadTab     = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './scratchpad_tab'));
const ButtonsPage       = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './buttons'));
const BadgesPage        = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './badges'));
const CardsPage         = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './cards'));
const DropdownsPage     = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './dropdowns'));
const FormsPage         = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './forms'));
const ModalsPage        = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './modals'));
const LayoutPage        = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './layout'));
const SpinnersPage      = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './spinners'));
const SuspendPage       = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './suspend'));
const SmilePage         = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './smile'));
const TablesPage        = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './tables'));
const MiscPage          = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './misc'));
const PageContentPage   = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './page_content_example'));
const PullToRefreshPage = lazy(() => import(/* webpackChunkName: 'scratchpad' */ './pull_to_refresh_page'));


const tabComponent = {
  component: renderPage(ScratchpadTab),
  className: 'scratchpad-tab',
  name:      'scratchpad',
};

export default {
  path:      '/scratchpad',
  component: renderPage(ScratchpadTab, { tabPage: true }),
  className: 'scratchpad-tab',
  name:      'Scratchpad',
  tabComponent,
  routes:    [
    {
      path:      'buttons',
      component: renderPage(ButtonsPage),
      className: 'scratchpad scratchpad-buttons',
      name:      'Buttons',
      tabComponent,
    },
    {
      path:      'badges',
      component: renderPage(BadgesPage),
      className: 'scratchpad scratchpad-badges',
      name:      'Badges',
      tabComponent,
    },
    {
      path:      'cards',
      component: renderPage(CardsPage),
      className: 'scratchpad scratchpad-cards',
      name:      'Cards',
      tabComponent,
    },
    {
      path:      'dropdowns',
      component: renderPage(DropdownsPage),
      className: 'scratchpad scratchpad-dropdowns',
      name:      'Dropdowns',
      tabComponent,
    },
    {
      path:      'forms',
      component: renderPage(FormsPage),
      className: 'scratchpad scratchpad-forms',
      name:      'Forms',
      tabComponent,
    },
    {
      path:      'modals',
      component: renderPage(ModalsPage),
      className: 'scratchpad scratchpad-modals',
      name:      'Modals',
      tabComponent,
    },
    {
      path:      'layout',
      component: renderPage(LayoutPage),
      className: 'scratchpad scratchpad-layout',
      name:      'Layout',
      tabComponent,
      routes:    [
        {
          path:      'page_content',
          component: renderPage(PageContentPage),
          className: 'scratchpad',
          name:      'Layout',
          tabComponent,
        },
      ],
    },
    {
      path:      'pull-to-refresh',
      component: renderPage(PullToRefreshPage),
      className: 'scratchpad scratchpad-pull-to-refresh',
      name:      'Pull to refresh',
      tabComponent,
    },
    {
      path:      'smile',
      component: renderPage(SmilePage),
      className: 'scratchpad scratchpad-smile',
      name:      'Smile',
    },
    {
      path:      'spinners',
      component: renderPage(SpinnersPage),
      className: 'scratchpad scratchpad-spinners',
      name:      'Spinners',
      tabComponent,
    },
    {
      path:      'suspend',
      component: renderPage(SuspendPage),
      className: 'scratchpad scratchpad-suspend',
      name:      'Suspend',
      tabComponent,
    },
    {
      path:      'tables',
      component: renderPage(TablesPage),
      className: 'scratchpad scratchpad-tables',
      name:      'Tables',
      tabComponent,
    },
    {
      path:      'misc',
      component: renderPage(MiscPage),
      className: 'scratchpad scratchpad-misc',
      name:      'Misc',
      tabComponent,
    },
  ],
};
