import * as Auth        from '../../auth';
import * as User        from '../../store/user';
import { lazy }         from 'react';
import renderPage       from '../../components/page';
import SignupBackground from '../signup/signup_background';
import store            from '../../store';


const LoginPage = lazy(() => import(/* webpackChunkName: 'login' */ './login_page'));


export default {
  path:       '/login',
  component:  renderPage(LoginPage),
  className:  'login-page',
  public:     true,
  background: SignupBackground,

  routes: [
    {
      path:   'intuit',
      public: true,
      async redirect(route, _, reject) {
        const hasAccessToken = !!User.getAccessToken(store.getState());
        if (hasAccessToken)
          await store.dispatch(User.signOut({ returnTo: '/login/intuit' }));
        else
          Auth.authorize({ returnTo: '/', intent: 'intuit' });
        reject();
      },
    },
    {
      path:   'passwordless',
      public: true,
      async redirect(route, _, reject) {
        const hasAccessToken = !!User.getAccessToken(store.getState());
        if (hasAccessToken)
          store.dispatch(User.signOut());

        const { token: password, username: email } = route.query;
        Auth.authorize({ returnTo: '/', email, password });
        reject();
      },
    },
  ],
};
