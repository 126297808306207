import * as UI       from '../store/ui';
import * as User     from '../store/user';
import onDataMessage from './on_data_message';
import React         from 'react';
import rollbar       from '../rollbar';
import useNavigation from '../hooks/use_navigation';


export default function Cordova() {
  const isSupported = ('PushNotification' in window);

  if (isSupported)
    return <InitializeCordova/>;
  else
    return <NotSupported/>;
}


function InitializeCordova() {
  const [ push, setPush ] = React.useState(null);

  return (
    <>
      {!push && (
        <InitializePushNotification {...{ setPush }}/>
      )}
      {push && (
        <>
          <AddListeners {...{ push }}/>
          <BadgeCount {...{ push }}/>
          <OnSignedOut {...{ push }}/>
        </>
      )}
    </>
  );
}


function InitializePushNotification({ setPush }) {
  React.useEffect(function() {
    const push = window.PushNotification.init({
      android: {
        clearNotifications: true,
        sound:              true,
        vibrate:            true,
      },
      ios: {
        alert: true,
        badge: true,
        sound: true,
      },
    });
    setPush(push);
  }, [ setPush ]);

  return null;
}


function AddListeners({ push }) {
  const { navigateTo } = useNavigation();

  React.useEffect(function() {
    push.on('notification', function({ title, message, additionalData }) {
      // Through FCM we send title + body, Firebase receives it as such, but
      // phonegap-plugin-push receives it as title + message.
      if (title && message) {
        const url = additionalData.url || additionalData.category;

        if (additionalData.foreground) {
          // App is in foreground. Let's show in-app notification.
          UI.notify({ title, body: message, url });
        } else if (url) {
          // App was in background, but push notification was clicked.
          // Let's not display in-app notification, but honor clicked URL.
          try {
            const path = new URL(url).pathname;
            navigateTo(path);
          } catch (error) {
            rollbar.error(error);
          }
        }
      }

      if (additionalData.action)
        onDataMessage(additionalData);
    });

    // Initialize once per application, but we lose all event handlers if we
    // previously called unregister
    push.on('registration', function(data) {
      const deviceToken = data.registrationId;
      User.setDeviceToken(deviceToken);
    });

    push.on('error', function(error) {
      rollbar.error(error);
    });
  }, [ push, navigateTo ]);

  return null;
}


function BadgeCount({ push }) {
  const count = User.useBadgeCount();

  React.useEffect(function() {
    push.setApplicationIconBadgeNumber(noop, noop, count);
  }, [ push, count ]);

  return null;
}

function noop() {}


function OnSignedOut({ push }) {
  const isSignedOut = User.useIsSignedOut();

  React.useEffect(function() {
    if (isSignedOut)
      push.unregister();
  }, [ push, isSignedOut ]);

  return null;
}


// do we still need to log this to rollbar?
function NotSupported() {
  React.useEffect(function() {
    // iOS 10.2 seems to not support them.
    // https://github.com/broadly/broadly/issues/3026
    rollbar.error(new Error('Push Notifications not supported on Cordova'));
  }, []);
  return null;
}
