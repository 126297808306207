import * as User  from '../../store/user';
import { lazy }   from 'react';
import renderPage from '../../components/page';
import store      from '../../store';

const BrandsPage    = lazy(() => import(/* webpackChunkName: 'reports' */ './brands_page'));
const LocationsPage = lazy(() => import(/* webpackChunkName: 'reports' */ './locations_page'));
const TeamPage      = lazy(() => import(/* webpackChunkName: 'reports' */ './team_page'));
const ReviewsPage   = lazy(() => import(/* webpackChunkName: 'reports' */ './reviews_page'));


export default {
  path: '/reports',
  redirect(route, resolve) {
    // const hasMultipleBrands = User.hasMultipleBrands(store.getState());
    // if (hasMultipleBrands)
    //   resolve('/reports/brands');
    // else
    //   resolve('/reports/locations');
    resolve('/reports/locations');
    // ^ just locations for initial release
  },
  tabComponent: {
    name:      'reports',
    component: null,
  },
  routes: [
    {
      path: 'brands',
      // eslint-disable-next-line max-params
      beforeEnter(routeTo, routeFrom, resolve, reject) {
        const hasMultipleBrands = User.hasMultipleBrands(store.getState());
        if (hasMultipleBrands)
          resolve();
        else
          reject();
      },
      component:    renderPage(BrandsPage),
      className:    'reports-page reports-brands-page brands-accent-color dashboard-colors',
      tabComponent: {
        name:      'reports-brands',
        component: null,
      },
    },
    {
      path:         'locations',
      component:    renderPage(LocationsPage),
      className:    'reports-page reports-locations-page locations-accent-color dashboard-colors',
      tabComponent: {
        name:      'reports-locations',
        component: null,
      },
    },
    {
      path:         'team',
      component:    renderPage(TeamPage),
      className:    'reports-page reports-team-page team-accent-color dashboard-colors',
      tabComponent: {
        name:      'reports-team',
        component: null,
      },
    },
    {
      path:         'reviews',
      component:    renderPage(ReviewsPage),
      className:    'reports-page reports-reviews-page reviews-accent-color dashboard-colors',
      tabComponent: {
        name:      'reports-reviews',
        component: null,
      },
    },
  ],
};
