// Exports Firebase.messaging() when available, otherwise null

import '@firebase/messaging';
import firebase from '@firebase/app';

let app;
let messaging;


const firebaseOptions = {
  projectId:         'spatial-encoder-364',
  appId:             '1:1063437822842:web:833eb26029a8b7c69a44c3',
  apiKey:            'AIzaSyDcxKLsyx-fQ9KjkIVqvWwQIvsinZWG4r0',
  messagingSenderId: '1063437822842',
};


export default function getFirebaseMessaging() {
  if (typeof app === 'undefined')
    app = firebase.initializeApp(firebaseOptions);

  // Firebase only available in some environments, where it's not supported,
  // app.messaging() throws an exception, so return null instead
  if (typeof messaging === 'undefined')
    messaging = typeof self !== 'undefined' && firebase.messaging.isSupported() ? app.messaging() : null;

  return messaging;
}
