//  Redux store is defined in terms of selectors, action creators, and reducers.
//  So really nothing interesting to see in this file.
//
//  You're probably looking to:
//
//  - Get data from the store, see: <model>/selectors.js
//  - Do something interesting, see: <model>/actions.js
//  - Add new action, see: <model>/reducer.js


import { applyMiddleware } from 'redux';
import { compose }         from 'redux';
import { createStore }     from 'redux';
import reducer             from './reducer';
import ReduxThunk          from 'redux-thunk';


//  Redux DevTools is amazing and will change your life forever.
//
//  To install this Chrome extension:
//  https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
  reducer,
  {},
  composeEnhancers(
    applyMiddleware(ReduxThunk),
  ),
);
export default store;
