import * as ContactsSelectors      from '../contacts/selectors';
import * as ConversationsSelectors from './selectors';
import * as Resources              from '../../util/resources';
import addSearchFields             from './add_search_fields';
import assert                      from 'assert';
import contactToResource           from '../contacts/contact_to_resource';
import createActionCreators        from 'redux-resource-action-creators';


// Use this to read a single conversation: to add additional information
// (messages, activity history)
export function readConversation({ conversationID, businessID }) {
  return async function(dispatch, getState) {
    const readStatus = ConversationsSelectors.getConversationReadStatus(getState(), conversationID);
    // You're a good person, you only dispatched this action once.
    //
    // Guess what? Some push message arrived and asked to reload the customer
    // record. Oh, and that happens every time we dispatch sendMessage, because
    // it dispatches readContact for its own good, but also there would be a
    // push message about it.
    //
    // If you think of code in terms of "all the things that could happen at
    // once", this is one of those things very likely to happen at once. AKA:
    // https://en.wikipedia.org/wiki/Reentrancy_(computing)
    //
    // Nothing will break horribly, if two API requests run at once. But you'll
    // see two requests in the log, you'll wonder why, you'll investigate why,
    // and waste the day learning that "things that could, also would."
    //
    // There's no huge benefit, from two API requests running at once. The
    // second request could be alerting us some new information is available (eg
    // update from another user). The thing to remember: there's no strict
    // ordering of responses. The first request might complete after the second
    // request, and because the last response wins, we sill end up with older
    // data.
    //
    // If we really did care about getting the most recent data, we wouldn't
    // leave it to chance (two requests in parallel), but implement some smart
    // queuing mechanism. Maybe we'll write such utility function some day.
    //
    // Meanwhile, this is a good opportunity to brush up on:
    // https://en.wikipedia.org/wiki/Fallacies_of_distributed_computing
    //
    // In this case, pay attention to 2). Latency is not zero, it's variable and
    // it's randomly variable, hence no strict ordering of response. I know
    // there's some logical distance from "not zero" to "no strict ordering",
    // but it is causal.
    //
    // Also, pay attention to 9). In fact, distributed systems were invented by
    // cats to torment their humans. Not a fallacy.
    if (!readStatus.pending) {
      await dispatch(Resources.readResources({
        url:           `${API_URL}/conversations/${conversationID}`,
        resourceType:  'conversations',
        resources:     [ conversationID ],
        list:          businessID,
        transformData: conversationToResources,
      }));
    }
  };
}


// Silently update a conversation from server.
export function silentlyReadConversation({ conversationID, businessID }) {
  assert(businessID);
  return async function(dispatch, getState) {
    const readStatus = ConversationsSelectors.getConversationReadStatus(getState(), conversationID);

    if (!readStatus.pending) {
      await dispatch(Resources.silentlyReadResources({
        url:           `${API_URL}/conversations/${conversationID}`,
        resourceType:  'conversations',
        resources:     [ conversationID ],
        list:          businessID,
        transformData: conversationToResources,
      }));

      const conversation = ConversationsSelectors.getConversation(getState(), conversationID);
      if (conversation)
        await dispatch(setCustomerFromConversation({ conversation, businessID }));
    }
  };
}

function setCustomerFromConversation({ conversation, businessID }) {
  return function(dispatch) {
    const customer = {
      ...conversation.customers[0],
      conversation: {
        id: conversation.id,
      },
    };

    const updateCustomerAction = createActionCreators('update', {
      resourceType: 'customers',
      resources:    [ contactToResource(customer) ],
      list:         businessID,
    });

    dispatch(updateCustomerAction.succeeded());
  };
}


// Silently update a conversation from server using a contact
export function silentlyReadConversationFromContactID({ contactID, businessID }) {
  return async function(dispatch, getState) {
    const contact        = ContactsSelectors.getContact(getState(), contactID);
    const conversationID = contact?.conversation?.id;

    if (!conversationID)
      return;

    const readStatus = ConversationsSelectors.getConversationReadStatus(getState(), conversationID);

    if (!readStatus.pending) {
      await dispatch(Resources.silentlyReadResources({
        url:           `${API_URL}/conversations/${conversationID}`,
        resourceType:  'conversations',
        resources:     [ conversationID ],
        list:          businessID,
        transformData: conversationToResources,
      }));
    }
  };
}


export function setConversationUnreadCount({ conversation, unreadCount }) {
  return function(dispatch) {
    const updatedConversation = {
      ...conversation,
      unreadCount,
    };

    const updateConversationAction = createActionCreators('update', {
      resourceType: 'conversations',
      resources:    [ updatedConversation ],
    });
    dispatch(updateConversationAction.succeeded());
  };
}


export function markConversationAsRead({ conversation, timestamp }) {
  return async function(dispatch) {
    const conversationID = conversation.id;

    dispatch(setConversationUnreadCount({ conversation, unreadCount: 0 }));

    await dispatch(Resources.request({
      url:    `${API_URL}/conversations/${conversationID}/read`,
      method: 'POST',
      body:   { timestamp },
    }));
  };
}


export function markConversationAsUnread({ conversation, timestamp }) {
  return async function(dispatch) {
    const conversationID = conversation.id;

    dispatch(setConversationUnreadCount({ conversation, unreadCount: 1 }));

    await dispatch(Resources.request({
      url:    `${API_URL}/conversations/${conversationID}/unread`,
      method: 'POST',
      body:   { timestamp },
    }));
  };
}


export function assignTeamMember({ conversationID, userID, businessID }) {
  const assignees = userID ? [{ id: userID }] : [];
  return async function(dispatch) {
    await dispatch(Resources.request({
      url:    `${API_URL}/conversations/${conversationID}`,
      method: 'PATCH',
      body:   { assignees },
    }));
    await dispatch(silentlyReadConversation({ conversationID, businessID }));
  };
}


export function followConversation({ conversationID, businessID }) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      url:    `${API_URL}/conversations/${conversationID}/follow`,
      method: 'POST',
    }));
    await dispatch(silentlyReadConversation({ conversationID, businessID }));
  };
}


export function unfollowConversation({ conversationID, businessID }) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      url:    `${API_URL}/conversations/${conversationID}/unfollow`,
      method: 'POST',
    }));
    await dispatch(silentlyReadConversation({ conversationID, businessID }));
  };
}


export function snoozeConversation({ businessID, conversationID, snoozedUntil }) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      url:    `${API_URL}/conversations/${conversationID}`,
      method: 'PATCH',
      body:   { snooze: { snoozedUntil } },
    }));
    await dispatch(silentlyReadConversation({ conversationID, businessID }));
  };
}


export function updateConversationNote({ conversationID, businessID, body }) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      url:    `${API_URL}/conversations/${conversationID}`,
      method: 'PATCH',
      body:   { note: { body } },
    }));
    await dispatch(silentlyReadConversation({ conversationID, businessID }));
  };
}

export function conversationDeleted({ conversationID }) {
  return function(dispatch) {
    const actionCreators = createActionCreators('delete', {
      resourceType: 'conversations',
      resources:    [ conversationID ],
    });
    dispatch(actionCreators.succeeded());
  };
}


function conversationToResources(conversation) {
  return [ toResource(conversation) ];
}


function toResource(conversation) {
  if (!conversation.lastActivity && conversation.activities) {
    const lastActivity = conversation.activities[conversation.activities.length - 1];
    return addSearchFields({ ...conversation, lastActivity });
  } else
    return addSearchFields(conversation);
}
