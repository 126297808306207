import React from 'react';


export default function useMediaQuery(query) {
  const mediaQuery              = getMediaQuery(query);
  const [ matches, setMatches ] = React.useState(mediaQuery.matches);

  React.useEffect(function updateOnChange() {
    function onChange() {
      setMatches(mediaQuery.matches);
    }

    mediaQuery.addEventListener('change', onChange);

    return () => {
      mediaQuery.removeEventListener('change', onChange);
    };
  });

  return matches;
}


export function useIsMobile() {
  return useMediaQuery('(max-width: 640px)');
}


export function useIsSplitView() {
  return useMediaQuery('(min-width: 760px)');
}


const queries = new Map();

function getMediaQuery(query) {
  if (!queries.get(query)) {
    const mediaQuery = window.matchMedia(query);
    queries.set(query, mediaQuery);
  }

  return queries.get(query);
}
