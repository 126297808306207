export function isProduction() {
  return process.env.NODE_ENV === 'production';
}


export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}


export function isPuppeteer() {
  return window.isPuppeteer;
}


export function isCordova() {
  return typeof window !== 'undefined' && !!window.cordova;
}


export function isAndroid() {
  if (isCordova() && window.device?.platform)
    return window.device.platform === 'Android';
  else
    return /android/i.test(window.navigator.userAgent);
}


export function isIOS() {
  if (isCordova() && window.device?.platform)
    return window.device.platform === 'iOS';
  else
    return /iPad|iPhone|iPod/.test(window.navigator.userAgent);
}


export function isWeb() {
  return !isCordova() && typeof window !== 'undefined' && window.location.protocol === 'https:';
}


export function isMac() {
  return !isCordova() && window?.navigator?.platform?.match(/Mac/);
}


export function isPC() {
  return !isCordova() && !isMac();
}


export function isWebRTCSupported() {
  return [
    'RTCPeerConnection',
    'webkitRTCPeerConnection',
    'mozRTCPeerConnection',
    'RTCIceGatherer',
  ].some(property => !!window[property]);
}
