import React from 'react';

// useBoolean
//
// returns [ booleanValue, setToTrueMethod, setToFalseMethod, toggleMethod ]
//
// example usage:
//
// const [ modalIsOpen, openModal, closeModal ] = useBoolean()
// <Button onClick={openModal}/>
// <Modal closeModal={closeModal} .../>
//

export default function useBoolean(initialState = false) {
  const [ value, setValue ] = React.useState(initialState);

  const setTrue  = React.useCallback(function setTrue() {
    setValue(true);
  }, []);
  const setFalse = React.useCallback(function setFalse() {
    setValue(false);
  }, []);
  const toggle   = React.useCallback(function toggle() {
    setValue(prevState => !prevState);
  }, []);
  const reset    = React.useCallback(function reset() {
    setValue(initialState);
  }, [ initialState ]);

  return [ value, setTrue, setFalse, toggle, reset ];
}
