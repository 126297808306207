import * as Business             from '../business';
import * as ContactsSelectors    from './selectors';
import * as ConversationsActions from '../conversations/actions';
import * as errors               from '../../util/errors';
import * as FileEntry            from '../../util/file_entry';
import * as Resources            from '../../util/resources';
import * as UI                   from '../../store/ui';
import * as User                 from '../../store/user';
import assert                    from '../../assert';
import createActionCreators      from 'redux-resource-action-creators';
import logEvent                  from '../../util/log_event';
import ms                        from 'ms';
import rollbar                   from '../../rollbar';
import toResource                from './contact_to_resource';
import Uppy                      from '@uppy/core';
import XHRUpload                 from '@uppy/xhr-upload';


export const remote = {
  UPDATE_CUSTOMER: contactUpdated,
  DELETE_CUSTOMER: contactDeleted,
  RECEIVE_MESSAGE: messageReceived,
};


export function readContacts({ businessID }) {
  return async function(dispatch) {
    try {
      await dispatch(Resources.listResources({
        url:                `${API_URL}/business/${businessID}/customers?activity=0`,
        resourceType:       'customers',
        list:               businessID,
        mergeListIds:       false,
        requestKey:         `read-${businessID}`,
        showDefaultErrorUI: false,
        transformData:      ({ customers = [] }) => customers.map(toResource),
      }));
    } catch (error) {
      dispatch(errors.showToastError(error));
      throw error;
    }
  };
}


// Use this to read a single customer: to add additional information (messages,
// activity history)
export function readContact(contactID, businessID) {
  assert(businessID);
  return async function(dispatch, getState) {
    const readStatus = ContactsSelectors.getContactReadStatus(getState(), contactID);
    // You're a good person, you only dispatched this action once.
    //
    // Guess what? Some push message arrived and asked to reload the customer
    // record. Oh, and that happens every time we dispatch sendMessage, because
    // it dispatches readContact for its own good, but also there would be a
    // push message about it.
    //
    // If you think of code in terms of "all the things that could happen at
    // once", this is one of those things very likely to happen at once.
    // AKA:  https://en.wikipedia.org/wiki/Reentrancy_(computing)
    //
    // Nothing will break horribly, if two API requests run at once.  But you'll
    // see two requests in the log, you'll wonder why, you'll investiage why,
    // and waste the day learning that "things that could, also would."
    //
    // There's no huge benefit, from two API requests running at once.  The
    // second request could be alerting us some new information is available (eg
    // update from another user).  The thing to remember: there's no strict
    // ordering of responses.  The first request might complete after the second
    // request, and because the last response wins, we will end up with older
    // data.
    //
    // If we really did care about getting the most recent data, we wouldn't
    // leave it to chance (two requests in parallel), but implement some smart
    // queuing mechanism.  Maybe we'll write such utility function some day.
    //
    // Meanwhile, this is a good opportunity to brush up on:
    // https://en.wikipedia.org/wiki/Fallacies_of_distributed_computing
    //
    // In this case, pay attention to 2).  Latency is not zero, it's variable
    // and it's randomly variable, hence no strict ordering of response.  I know
    // there's some logical distance from "not zero" to "no strict ordering",
    // but it is causal.
    //
    // Also, pay attention to 9).  In fact, distributed systems were invented by
    // cats to torment their humans.  Not a fallacy.
    if (!readStatus.pending) {
      await dispatch(Resources.readResources({
        url:           `${API_URL}/customer/${contactID}`,
        resourceType:  'customers',
        resources:     [ contactID ],
        list:          businessID,
        transformData: contactToResources,
      }));
    }
  };
}


// Silently update a customer from server.
export function silentlyReadContact(customerID, businessID) {
  assert(businessID);
  return async function(dispatch, getState) {
    const readStatus = ContactsSelectors.getContactReadStatus(getState(), customerID);

    if (!readStatus.pending) {
      const url = `${API_URL}/customer/${customerID}`;
      await dispatch(Resources.silentlyReadResources({
        url,
        resourceType:  'customers',
        resources:     [ customerID ],
        list:          businessID,
        transformData: contactToResources,
      }));
    }
  };
}


// provider is the provider that added the customer
// customer.provider is the assisting team member
export function createContact({ businessID, customer, callback, defaultThankYouDelay, provider }) {
  return async function(dispatch) {
    const customerToAdd = customer.sendThankYou
      ? addTransaction({ customer, defaultThankYouDelay, provider })
      : getContactWithProvider({ customer, provider });

    const resources = await dispatch(Resources.createResources({
      url:                `${API_URL}/business/${businessID}/customer`,
      body:               customerToAdd,
      resourceType:       'customers',
      requestKey:         'createContacts',
      list:               businessID,
      transformData:      data => [ toResource(data) ],
      showDefaultErrorUI: false,
    }));

    const contactProvider = customer.provider ? customer.provider : provider;
    logAddedContactEvent({ customer, provider: contactProvider });

    if (callback)
      callback();
    else
      UI.toast('Contact added!');

    return resources[0];
  };
}


function getContactWithProvider({ customer, provider }) {
  return { ...customer, provider };
}


function logAddedContactEvent({ customer, provider }) {
  // TODO: add sendThankYou prop (checkbox not implemented yet)
  const props = {
    hasEmail:                !!customer.contact.email?.address,
    hasPhoneNumber:          !!customer.contact.phone?.number,
    assistingTeamMemberType: getAssistingTeamMemberType({ customer, provider }),
  };

  logEvent('AddedContact', props);
}


function getAssistingTeamMemberType({ customer, provider }) {
  const hasProvider           = !!customer.provider;
  const providerIsCurrentUser = (hasProvider && customer.provider === provider.id);
  if (providerIsCurrentUser)
    return 'currentUser';
  else if (hasProvider)
    return 'teamMember';
  else
    return 'noMember';
}


export function createContacts({ businessID, customers }) {
  return async function(dispatch) {
    const withTransactions = customers.map(customer => addTransaction({ customer }));

    await dispatch(Resources.createResources({
      url:           `${API_URL}/business/${businessID}/customers`,
      body:          { customers: withTransactions },
      resourceType:  'customers',
      requestKey:    'createContacts',
      list:          businessID,
      // eslint-disable-next-line no-shadow
      transformData: ({ customers = [] }) => customers.map(toResource),
    }));

    const count = customers.length;
    if (count === 1)
      UI.toast('Contact successfully added');
    else if (count > 1)
      UI.toast(`${count} customers successfully added`);
  };
}


function addTransaction({ customer, defaultThankYouDelay, provider }) {
  const isDefaultDelay      = customer.sendDelay === defaultThankYouDelay;
  const transactionProvider = customer.provider ? { id: customer.provider } : null;
  const transaction         = {
    timestamp: customer.lastTransaction || new Date(),
    provider:  transactionProvider,
    sendDelay: isDefaultDelay ? undefined : customer.sendDelay,
  };

  return { ...customer, provider, transactions: [ transaction ] };
}


export function addManyContacts({ businessID, customers }) {
  return async function(dispatch) {
    try {
      const { body } = await dispatch(Resources.request({
        url:     `${API_URL}/business/${businessID}/customers`,
        method:  'POST',
        body:    customers,
        timeout: ms('5s'),
      }));
      return body;
    } catch (error) {
      const { body, statusCode } = error;
      const message              = body && (body.message || JSON.stringify(body));
      const statusError          = new Error(message);
      statusError.statusCode     = statusCode;

      if (statusCode > 0)
        rollbar.error(statusError);
      throw statusError;
    }
  };
}


export function updateContact(customer, params = {}) {
  return async function(dispatch) {
    await dispatch(Resources.updateResources({
      url:           `${API_URL}/customer/${customer.id}`,
      body:          customer,
      resourceType:  'customers',
      resources:     [ customer ],
      transformData: contactToResources,
      ...params,
    }));
  };
}

export function updateContactFollowUpStatus(customer, incomingStatus) {
  const updatedCustomer = {
    ...customer,
    contact: {
      ...customer.contact,
      followUp: !!incomingStatus,
    },
  };

  logEvent('ToggledCustomerStatus', {
    customer: updatedCustomer,
  });

  return updateContact(updatedCustomer);
}


export function updateContactBlockStatus(customer, blocked) {
  const status          = blocked ? 'blocked' : 'active';
  const updatedCustomer = {
    ...customer,
    status,
  };

  logEvent('ToggledCustomerBlock', {
    customer: updatedCustomer,
  });

  return updateContact(updatedCustomer);
}


export function updateContactPreferredChannel(customer, preferredChannel) {
  const updatedCustomer = {
    ...customer,
    contact: {
      ...customer.contact,
      preferredChannel,
    },
  };

  logEvent('ToggledPreferredChannel', {
    customer: updatedCustomer,
  });

  return updateContact(updatedCustomer);
}


export function contactUpdated(customer) {
  return async function(dispatch) {
    const businessID     = customer.business.id;
    const conversationID = customer.conversation?.id;
    assert(conversationID);
    await dispatch(ConversationsActions.silentlyReadConversation({ businessID, conversationID }));
  };
}


export function deleteContact(customer) {
  return async function(dispatch) {
    try {
      await dispatch(Resources.deleteResources({
        url:                `${API_URL}/customer/${customer.id}`,
        resourceType:       'customers',
        resources:          [ customer.id ],
        showDefaultErrorUI: false,
      }));
    } catch (error) {
      dispatch(errors.showToastError(error));
      throw error;
    }
  };
}


export function messageReceived({ channel, customer, business, conversation }) {
  return async function(dispatch, getState) {
    logNotifiedOfMessageEvent({ getState, channel, customer, business });
    const conversationID = conversation.id;
    assert(conversationID);
    await dispatch(ConversationsActions.silentlyReadConversation({ businessID: business.id, conversationID }));
  };
}


export function sendThankYouMessage({ businessID, conversationID, customerID, clientID }) {
  return async function(dispatch, getState) {
    try {
      await dispatch(Resources.request({
        url:    `${API_URL}/customer/${customerID}/thank_you`,
        method: 'POST',
        body:   {
          id: clientID,
        },
        showDefaultErrorUI: false,
      }));
    } catch (error) {
      const limitReachedErrorMessage = 'Review request limit reached';
      const isReviewRequestError     = error.body?.errors?.reviewRequest === limitReachedErrorMessage;

      if (isReviewRequestError) {
        const limit = Business.getReviewRequestsLimit(getState(), businessID);
        UI.toast(`The monthly limit of ${limit} review requests has been reached.`);
      } else
        throw error;
    }

    if (conversationID)
      await dispatch(ConversationsActions.silentlyReadConversation({ businessID, conversationID }));
  };
}


function logNotifiedOfMessageEvent({ getState, channel, customer, business }) {
  const businessName = Business.getBusinessName(getState(), business.id);

  logEvent('NotifiedOfMessage', {
    channel,
    customerStatus: customer.status,
    customerID:     customer.id,
    businessID:     business.id,
    businessName,
  });
}

export function contactDeleted({ id, conversation }) {
  return function(dispatch) {
    const actionCreators = createActionCreators('delete', {
      resourceType: 'customers',
      resources:    [ id ],
    });
    dispatch(actionCreators.succeeded());

    const conversationID = conversation.id;
    assert(conversationID);
    dispatch(ConversationsActions.conversationDeleted({ conversationID }));
  };
}


function contactToResources(customer) {
  return [ toResource(customer) ];
}


// -- Messaging --

export function sendMessage({ body, businessID, customer, files, id }) {
  return async function(dispatch) {
    const customerID     = customer.id;
    const conversationID = customer?.conversation?.id;

    const props = {
      customer,
      businessID,
      channel: customer.nextMessage?.channel,
    };

    logEvent('SentMessage', props);

    await dispatch(Resources.silentRequest({
      method: 'post',
      url:    `${API_URL}/customer/${customerID}/message`,
      body:   { id, body, files },
    }));

    await dispatch(ConversationsActions.silentlyReadConversation({ businessID, conversationID }));
  };
}

export async function waitUntilFileFinishesProcessing({ businessID, dispatch, fileID }) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async function() {
      try {
        const status = await getFileStatus({ businessID, dispatch, fileID });

        if (status === 'completed') {
          clearInterval(interval);
          resolve();
        } else if (status === 'failed') {
          clearInterval(interval);
          reject(Error('File failed being proccesed'));
        }
      } catch (error) {
        rollbar.error(error);
      }
    }, ms('3s'));
  });
}

async function getFileStatus({ businessID, dispatch, fileID }) {
  const { body } = await dispatch(Resources.silentRequest({
    url: `${API_URL}/business/${businessID}/file/${fileID}`,
  }));

  // completed | failed | processing
  return body.status;
}

export async function uploadFile({ businessID, clientID, dispatch, fileEntry, onProgress, getState, position }) {
  const { jwtToken } = User.getAccessToken(getState());
  const mimeType     = await FileEntry.getMimeTypeFromFileEntry(fileEntry);
  const blob         = await FileEntry.getBlobFromFileEntry(fileEntry, mimeType);

  // Refresh token if needed before use Uppy's XHRUpload
  await Resources.withAccessToken({ dispatch, getState });

  return new Promise((resolve, reject) => {
    const uppy = Uppy({ autoProceed: false })
      .use(XHRUpload, {
        endpoint:  `${API_URL}/business/${businessID}/file`,
        fieldName: 'file',
        headers:   {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .run()
      .on('upload-progress', function(currentFile, progress) {
        // we only upload one file for now. So, no need for currentFile
        // variable.
        const total    = progress.bytesTotal;
        const uploaded = progress.bytesUploaded;
        onProgress(Math.round((uploaded * 100) / total));
      })
      .on('complete', function(result) {
        if (result.successful.length) {
          resolve({
            fileID: result.successful[0].response.body.id,
            mimeType,
          });
        } else
          reject(Error('There was an error uploading the file'));
      })
      .on('error', reject);

    uppy.setMeta({ clientID });
    uppy.setMeta({ position });
    uppy.addFile({ name: fileEntry.name, type: mimeType, data: blob });
    uppy.upload();
  });
}


export function requestPayment({
  businessID,
  customerID,
  amount,
  deposit,
  description,
  items,
  notes,
  quoteID,
  sendReviewRequestAfterPayment,
}) {
  return async function(dispatch) {
    await dispatch(Resources.request({
      url:    `${API_URL}/customer/${customerID}/payment_request`,
      method: 'POST',
      body:   {
        amount,
        deposit,
        description,
        items,
        notes,
        quoteID,
        sendReviewRequestAfterPayment,
      },
      showDefaultErrorUI: false,
    }));

    await dispatch(silentlyReadContact(customerID, businessID));
  };
}


export function makeConnectingCall({ customerID, businessID }) {
  return async function(dispatch) {
    const response = await dispatch(Resources.request({
      url:    `${API_URL}/customer/${customerID}/call`,
      method: 'POST',
    }));

    await dispatch(silentlyReadContact(customerID, businessID));

    return response;
  };
}


export function getIntegrationURL({ customerID, showProgress = true }) {
  return async function(dispatch) {
    try {
      const { body } = await dispatch(Resources.request({
        url:    `${API_URL}/customer/${customerID}/integration_url`,
        method: 'GET',
        showProgress,
      }));

      return body.url;
    } catch (error) {
      const isIntegrationNotSupported = error.statusCode === 400 || error.statusCode === 404;
      if (isIntegrationNotSupported)
        return null;
      else
        throw error;
    }
  };
}
