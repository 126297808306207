

const defaultState = getDefaultState();

const emptyLocationIDs           = { selectedLocationIDs: [] };
const emptyProviderIDs           = { selectedProviderIDs: [] };
const emptyLocationsAndProviders = { ...emptyLocationIDs, ...emptyProviderIDs };
const emptySelectedLocationID    = { selectedLocationID: null };
const emptyAvailableLocations    = { availableLocations: null };

// eslint-disable-next-line max-statements
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    // on some pages only a single brand ID can be selected
    case 'SELECT_SINGLE_BRAND_ID': {
      const selectedBrandID = action.payload;
      return {
        ...state,
        selectedBrandID,
        ...emptySelectedLocationID,
        ...emptyLocationsAndProviders,
        ...emptyAvailableLocations,
      };
    }

    case 'SELECT_BRAND_ID': {
      const brandID              = action.payload;
      const { selectedBrandIDs } = state;
      if (selectedBrandIDs.includes(brandID))
        return state;
      else
        return { ...state, selectedBrandIDs: [ ...selectedBrandIDs, brandID ] };
    }

    case 'DESELECT_BRAND_ID': {
      const brandID              = action.payload;
      const { selectedBrandIDs } = state;
      if (selectedBrandIDs.includes(brandID))
        return { ...state, selectedBrandIDs: selectedBrandIDs.filter(id => id !== brandID) };
      else
        return state;
    }

    case 'TOGGLE_BRAND_ID': {
      const brandID              = action.payload;
      const { selectedBrandIDs } = state;
      if (selectedBrandIDs.includes(brandID))
        return reducer(state, { type: 'DESELECT_BRAND_ID', payload: brandID });
      else
        return reducer(state, { type: 'SELECT_BRAND_ID', payload: brandID });
    }

    case 'SET_SELECTED_BRAND_IDS': {
      const selectedBrandIDs = action.payload;
      return { ...state, selectedBrandIDs };
    }

    case 'SET_SENTIMENT_FILTER': {
      return { ...state, sentimentFilter: action.payload };
    }

    case 'SET_VISIBILITY_FILTER': {
      return { ...state, visibilityFilter: action.payload };
    }

    // on some pages only a single location ID can be selected
    case 'SELECT_SINGLE_LOCATION_ID': {
      const selectedLocationID = action.payload;
      return { ...state, selectedLocationID, ...emptyProviderIDs };
    }

    case 'SELECT_LOCATION_ID': {
      const locationID              = action.payload;
      const { selectedLocationIDs } = state;
      if (selectedLocationIDs.includes(locationID))
        return state;
      else
        return { ...state, selectedLocationIDs: [ ...selectedLocationIDs, locationID ], ...emptyProviderIDs };
    }

    case 'DESELECT_LOCATION_ID': {
      const locationID              = action.payload;
      const { selectedLocationIDs } = state;
      if (selectedLocationIDs.includes(locationID))
        return { ...state, selectedLocationIDs: selectedLocationIDs.filter(id => id !== locationID), ...emptyProviderIDs };
      else
        return state;
    }

    case 'TOGGLE_LOCATION_ID': {
      const locationID              = action.payload;
      const { selectedLocationIDs } = state;
      if (selectedLocationIDs.includes(locationID))
        return reducer(state, { type: 'DESELECT_LOCATION_ID', payload: locationID });
      else
        return reducer(state, { type: 'SELECT_LOCATION_ID', payload: locationID });
    }

    case 'SET_SELECTED_LOCATION_IDS': {
      const selectedLocationIDs = action.payload;
      return { ...state, selectedLocationIDs, ...emptyProviderIDs };
    }

    case 'SET_AVAILABLE_LOCATIONS': {
      const availableLocations = action.payload;
      return { ...state, availableLocations };
    }

    case 'SELECT_PROVIDER_ID': {
      const providerID              = action.payload;
      const { selectedProviderIDs } = state;
      if (selectedProviderIDs.includes(providerID))
        return state;
      else
        return { ...state, selectedProviderIDs: [ ...selectedProviderIDs, providerID ] };
    }

    case 'DESELECT_PROVIDER_ID': {
      const providerID              = action.payload;
      const { selectedProviderIDs } = state;
      if (selectedProviderIDs.includes(providerID))
        return { ...state, selectedProviderIDs: selectedProviderIDs.filter(id => id !== providerID) };
      else
        return state;
    }

    case 'TOGGLE_PROVIDER_ID': {
      const providerID              = action.payload;
      const { selectedProviderIDs } = state;
      if (selectedProviderIDs.includes(providerID))
        return reducer(state, { type: 'DESELECT_PROVIDER_ID', payload: providerID });
      else
        return reducer(state, { type: 'SELECT_PROVIDER_ID', payload: providerID });
    }

    case 'SET_SELECTED_PROVIDER_IDS': {
      const selectedProviderIDs = action.payload;
      return { ...state, selectedProviderIDs };
    }

    default: {
      return state;
    }
  }
}


function getDefaultState() {
  const query               = new URLSearchParams(window.location.search);
  const selectedBrandID     = query.get('brand') || null;
  const selectedBrandIDs    = query.get('brands')?.split(',') ?? [];
  const selectedLocationID  = query.get('location') || null;
  const selectedLocationIDs = query.get('locations')?.split(',') ?? [];
  const selectedProviderIDs = query.get('providers')?.split(',') ?? [];
  const sentimentFilter     = query.get('sentiment') || null;
  const visibilityFilter    = query.get('visibility') || null;
  const availableLocations  = null;

  return {
    selectedBrandID,
    selectedBrandIDs,
    selectedLocationID,
    selectedLocationIDs,
    selectedProviderIDs,
    sentimentFilter,
    visibilityFilter,
    availableLocations,
  };
}
