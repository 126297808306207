import * as errors    from '../../util/errors';
import * as Resources from '../../util/resources';


// -- Campaigns

// map remote actions to methods
export const remote = {
  LOAD_CAMPAIGN: silentlyReadCampaign,
};

export function readCampaigns(businessID) {
  return async function(dispatch) {
    try {
      await dispatch(Resources.readResources({
        url:                `${API_URL}/business/${businessID}/campaigns`,
        resourceType:       'campaigns',
        list:               businessID,
        mergeListIds:       false,
        requestKey:         `read-${businessID}`,
        showDefaultErrorUI: false,
        transformData:      ({ campaigns }) => campaigns,
      }));
    } catch (error) {
      dispatch(errors.showToastError(error));
      throw error;
    }
  };
}


export function readCampaign({ businessID, campaignID }) {
  return function(dispatch) {
    dispatch(Resources.readResources({
      url:           `${API_URL}/business/${businessID}/campaign/${campaignID}`,
      resourceType:  'campaigns',
      resources:     [ campaignID ],
      transformData: campaign => [ campaign ],
    }));
  };
}


export function silentlyReadCampaign({ businessID, campaignID }) {
  return function(dispatch) {
    dispatch(Resources.silentlyReadResources({
      url:           `${API_URL}/business/${businessID}/campaign/${campaignID}`,
      resourceType:  'campaigns',
      resources:     [ campaignID ],
      transformData: campaign => [ campaign ],
    }));
  };
}


export function createCampaign({ businessID, campaign }) {
  return async function(dispatch) {
    const resources = await dispatch(Resources.createResources({
      url:                `${API_URL}/business/${businessID}/campaign`,
      body:               campaign,
      resourceType:       'campaigns',
      requestKey:         'createCampaign',
      list:               businessID,
      transformData:      data => [ data ],
      showDefaultErrorUI: false,
      showProgress:       true,
    }));

    return resources[0];
  };
}


export function updateCampaign({ businessID, campaign }) {
  return async function(dispatch) {
    const resources = await dispatch(Resources.updateResources({
      url:                `${API_URL}/business/${businessID}/campaign/${campaign.id}`,
      body:               campaign,
      resourceType:       'campaigns',
      requestKey:         'updateCampaign',
      list:               businessID,
      transformData:      data => [ data ],
      showDefaultErrorUI: false,
      showProgress:       true,
    }));

    return resources[0];
  };
}


export function deleteCampaign({ businessID, campaign }) {
  return async function(dispatch) {
    try {
      await dispatch(Resources.deleteResources({
        url:                `${API_URL}/business/${businessID}/campaign/${campaign.id}`,
        resourceType:       'campaigns',
        resources:          [ campaign.id ],
        showDefaultErrorUI: false,
      }));
    } catch (error) {
      dispatch(errors.showToastError(error));
      throw error;
    }
  };
}
