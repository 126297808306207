import BroadlyIcon     from '../assets/broadly_icon.svg';
import FacebookIcon    from '../assets/services/facebook_logo.svg';
import GoogleIcon      from '../assets/services/google_logo.svg';
import NextDoorIcon    from '../assets/services/nextdoor_logo.svg';
import NpsIcon         from '../assets/services/nps.svg';
import TripAdvisorIcon from '../assets/services/trip_advisor_logo.svg';
import YelpIcon        from '../assets/services/yelp_logo.svg';


export const serviceNames = {
  google:      'Google',
  facebook:    'Facebook',
  tripAdvisor: 'TripAdvisor',
  yelp:        'Yelp',
  broadly:     'Broadly',
  nextdoor:    'Nextdoor',
  nps:         'NPS',
};

export function getServiceName(service) {
  return serviceNames[service] || service;
}


const serviceIcons = {
  broadly:     BroadlyIcon,
  facebook:    FacebookIcon,
  google:      GoogleIcon,
  nextdoor:    NextDoorIcon,
  tripAdvisor: TripAdvisorIcon,
  yelp:        YelpIcon,
  nps:         NpsIcon,
};

export function getServiceIcon(service) {
  return serviceIcons[service];
}
