import React   from 'react';
import rollbar from '../rollbar';

// from https://usehooks.com/useLocalStorage/

export default function useLocalStorage(key, initialValue) {
  // Pass initial state function to useState so logic is only executed once
  const [ storedValue, setStoredValue ] = React.useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item).value : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // when value changes, store new value
  React.useEffect(function onValueChange() {
    try {
      window.localStorage.setItem(key, JSON.stringify({ value: storedValue }));
    } catch (error) {
      // A more advanced implementation would handle the error case
      rollbar.error(error);
    }
  }, [ key, storedValue ]);

  return [ storedValue, setStoredValue ];
}
