import React from 'react';

// https://usehooks.com/useEventListener/

export default function useEventListener(eventName, handler, element = window) {
  const savedHandler = React.useRef();

  React.useEffect(function saveHandlerReference() {
    savedHandler.current = handler;
  }, [ handler ]);

  React.useEffect(function addEventListener() {
    const isSupported = element && element.addEventListener;
    if (!isSupported)
      return null;

    function eventListener(event) {
      return savedHandler.current(event);
    }

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [ eventName, element ]);
}
