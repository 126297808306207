import React           from 'react';
import useCurrentRoute from './use_current_route';


const emptyParams = {};

export default function useRouteParams() {
  const route = useCurrentRoute();

  return React.useMemo(() => {
    return route?.params ?? emptyParams;
  }, [ route ]);
}
