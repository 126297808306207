import ms      from 'ms';
import rollbar from '../rollbar';


// Simple retry with exponential backoff and jitter.
// https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
export default async function retry(fn, { maxInterval = ms('30s'), attempt = 1 } = {}) {
  try {
    return await fn();
  } catch (error) {
    rollbar.error(error);
    const candidate = 500 * 2 ** attempt;
    const interval  = Math.min(candidate, maxInterval);
    const jittered  = getRandomNumberBetween(0, interval);
    await new Promise(resolve => setTimeout(resolve, jittered));
    return await retry(fn, { maxInterval, attempt: attempt + 1 });
  }
}


function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
}
