import * as DateRanges from '../../pages/dashboard/date_ranges';


const defaultState = getDefaultState();

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_DATE_RANGE': {
      const dateRange = action.payload;
      return { ...state, dateRange };
    }

    case 'SET_CUSTOM_DATE_RANGE': {
      const customDateRange = action.payload;
      return { ...state, customDateRange };
    }

    default: {
      return state;
    }
  }
}


function getDefaultState() {
  const query           = new URLSearchParams(window.location.search);
  const dateRange       = getDefaultDateRange(query);
  const customDateRange = getDefaultCustomDateRange(query);

  if (customDateRange)
    return { dateRange: customDateRange, customDateRange };
  else
    return { dateRange };
}


function getDefaultDateRange(query) {
  const range = query.get('range');
  try {
    return DateRanges.getRange(range);
  } catch (error) {
    return DateRanges.getRange('month');
  }
}

function getDefaultCustomDateRange(query) {
  const range = query.get('range');
  const to    = query.get('to');
  const from  = query.get('from');
  if (range === 'custom' && to && from)
    return { range, to, from };
  else
    return null;
}
