import { lazy }         from 'react';
import renderPage       from '../../components/page';
import SignupBackground from '../signup/signup_background';


const QboDisconnectedPage = lazy(() => import(/* webpackChunkName: 'quickbooksdisconnect' */ './qbo_disconnected_page'));


export default {
  path:       '/quickbooksdisconnect',
  component:  renderPage(QboDisconnectedPage),
  className:  'qbo-disconnected-page',
  public:     true,
  background: SignupBackground,
};
