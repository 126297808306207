import * as Resources from '../../../util/resources';
import store          from '../..';


export function newDeviceToken(deviceToken) {
  return async function(dispatch) {
    try {
      await dispatch(Resources.request({
        method: 'post',
        url:    `${API_URL}/me/device_token`,
        body:   { deviceToken },
      }));
    } catch (error) {
      // eslint-disable-line no-empty
    }
  };
}

export function setDeviceToken(deviceToken) {
  if (deviceToken)
    store.dispatch(newDeviceToken(deviceToken));
}
