export function isInProgress(state) {
  return state.ui?.inProgress > 0;
}

export function getToast(state) {
  return state.ui?.toast;
}

export function getNotification(state) {
  return state.ui?.notification;
}

export function getAlert(state) {
  return state.ui?.alert;
}

export function getMenuIsOpen(state) {
  return state.ui?.menu.isOpen;
}
