import './menu_panel.less';

import * as Business               from '../store/business';
import * as UI                     from '../store/ui';
import * as User                   from '../store/user';
import { getURL }                  from './location_links';
import { MenuLink }                from './menu_link';
import { MenuLinks }               from './menu_link';
import { useDispatch }             from 'react-redux';
import BroadlyLogo                 from './broadly-logo.svg';
import Button                      from '../components/button';
import Column                      from '../components/column';
import LocationLinks               from './location_links';
import React                       from 'react';
import Select                      from '../components/select';
import TrialIndicatorBox           from './trial_indicator_box';
import useNavigation               from '../hooks/use_navigation';
import useOptions                  from '../hooks/use_options';
import useRouteParams              from '../hooks/use_route_params';

import { Shield as BrandsIcon }    from 'react-feather';
import { ExternalLink }            from 'react-feather';
import { HelpCircle }              from 'react-feather';
import { MapPin as LocationsIcon } from 'react-feather';
import { ThumbsUp as ReviewsIcon } from 'react-feather';
import { Users as TeamIcon }       from 'react-feather';
import { User as UserIcon }        from 'react-feather';


export const lastLocationKey = 'lastLocation';
const reportsTabs = [ 'reports-brands', 'reports-locations', 'reports-team', 'reports-reviews' ];


export default function MenuPanel({ tab, panelID }) {
  const { businessID } = useRouteParams();
  const business       = Business.useBusiness(businessID);
  const locations      = useLocations({ businessID });
  const scrollable     = React.useRef();
  const isFreemium     = !!business?.isFreemium;

  const showLocations = (locations?.length > 0);

  React.useEffect(function scrollUpWhenLocationChanges() {
    scrollable.current?.scrollTo?.(0, 0);
  }, [ businessID ]);

  return (
    <div className="menu-panel" id={panelID}>
      <div className="menu-panel-content">
        <div className="menu-panel-top">
          <Logo/>
          <div className="scrollable" ref={scrollable}>
            <Column className="menu-sections">
              <Reports {...{ tab }}/>
              {showLocations && <Locations {...{ tab, businessID, locations, isFreemium }}/>}
            </Column>
          </div>
        </div>
        <div className="menu-panel-bottom">
          {isFreemium && <TrialIndicatorBox {...{ business }}/>}
          {!isFreemium && <DashboardLink/>}
          <AccountLink {...{ tab }}/>
          <HelpLink/>
        </div>
      </div>
    </div>
  );
}


function Logo() {
  return <BroadlyLogo className="logo" onClick={UI.closeMenu}/>;
}


function Reports({ tab }) {
  // Don't show reports as active unless one of the reports routes are active.
  const primary          = reportsTabs.includes(tab);
  const secondary        = !primary;
  const showReportsLinks = !!primary;

  return (
    <div className="reports">
      <Button
        href="/reports"
        className="menu-section-button"
        wide
        primary={primary}
        secondary={secondary}
        label="Reports"
        renderNestedContent={false}
        onClick={UI.closeMenu}
      />
      {showReportsLinks && <ReportsLinks {...{ tab }}/>}
    </div>
  );
}


function ReportsLinks({ tab }) {
  const showBrands  = false;
  const showReviews = false;

  return (
    <MenuLinks>
      {showBrands && (
        <MenuLink
          icon={<BrandsIcon/>}
          label="Brands"
          active={tab === 'reports-brands'}
          href="/reports/brands"
        />
      )}
      <MenuLink
        icon={<LocationsIcon/>}
        label="Locations"
        active={tab === 'reports-locations'}
        href="/reports/locations"
      />
      <MenuLink
        icon={<TeamIcon/>}
        label="Team"
        active={tab === 'reports-team'}
        href="/reports/team"
      />
      {showReviews && (
        <MenuLink
          icon={<ReviewsIcon/>}
          label="Reviews"
          active={tab === 'reports-reviews'}
          href="/reports/reviews"
        />
      )}
    </MenuLinks>
  );
}


function Locations({ tab, businessID, locations, isFreemium }) {
  const previousLocationID = window.localStorage.getItem(lastLocationKey);
  const firstLocationID    = locations?.[0]?.id;
  const selectedBusinessID = businessID || previousLocationID || firstLocationID;

  const dispatch          = useDispatch();
  const hasOtherLocations = (locations.length > 1);

  // load the business for single location user viewing a non-business route
  React.useEffect(function loadBusinessIfNeeded() {
    dispatch(Business.loadBusinessIfNeeded(selectedBusinessID));
  }, [ dispatch, selectedBusinessID ]);

  return (
    <div className="locations">
      {hasOtherLocations && <LocationSelector {...{ businessID, selectedBusinessID, locations }}/>}
      {!hasOtherLocations && <SingleLocationHeader {...{ businessID, selectedBusinessID, locations }}/>}
      <LocationLinks businessID={selectedBusinessID} {...{ tab, isFreemium }}/>
    </div>
  );
}


function LocationSelector({ businessID, selectedBusinessID, locations }) {
  const locationOptions = useOptions(locations);
  const { navigateTo }  = useNavigation();

  function onChange(event) {
    const id = event.target.value;
    window.localStorage.setItem(lastLocationKey, id);
    const locationHref = getDefaultLocationURL(id);
    navigateTo(locationHref);
  }

  const primary   = !!businessID;
  const secondary = !primary;

  return (
    <Select
      wide
      className="location-selector menu-section-button"
      label="Location selector"
      options={locationOptions}
      value={selectedBusinessID}
      onChange={onChange}
      primary={primary}
      secondary={secondary}
    />
  );
}


function SingleLocationHeader({ businessID, selectedBusinessID, locations }) {
  const location  = locations.find(({ id }) => id === selectedBusinessID);
  const primary   = !!businessID;
  const secondary = !primary;
  const href      = getDefaultLocationURL(selectedBusinessID);

  return (
    <Button
      href={href}
      className="menu-section-button truncate"
      wide
      primary={primary}
      secondary={secondary}
      label={location?.name}
      title={location?.name}
      renderNestedContent={false}
    />
  );
}


function DashboardLink() {
  return (
    <MenuLink
      icon={<ExternalLink/>}
      label="Multi-location Dashboard"
      active={false}
      href="https://dashboard.broadly.com"
      className="dashboard-link"
      external
    />
  );
}


function AccountLink({ tab }) {
  return (
    <MenuLink
      icon={<UserIcon/>}
      label="Account"
      active={tab === 'account'}
      href="/account"
    />
  );
}


function HelpLink() {
  return (
    <MenuLink
      icon={<HelpCircle/>}
      label="Help"
      active={false}
      href="https://broadly-support.zendesk.com/hc/en-us"
      external
    />
  );
}


function getDefaultLocationURL(id) {
  return getURL(id, 'inbox');
}


function useLocations({ businessID }) {
  const userLocations = User.useActiveLocations();
  const business      = Business.useBusiness(businessID);

  // Most users have a list of locations with ids and names in their profile.
  // But Salesforce users (who aren't emulating a user) have an empty locations
  // list, so we have to use the business as a fallback.

  if (userLocations.length > 0)
    return userLocations;
  else if (business)
    return [ business ];
  else
    return null;
}
