import * as ENV                               from '../util/env';
import { callCustomer as mobileCallCustomer } from './mobile';
import { useIsSalesforce }                    from '../store/user/hooks';
import { useIsSignedIn }                      from '../store/user/hooks';
import { callCustomer as webCallCustomer }    from './web';
import MobileCall                             from './mobile';
import React                                  from 'react';
import WebCall                                from './web';


export async function callCustomer({ business, customer }) {
  if (ENV.isIOS() && ENV.isCordova())
    return await mobileCallCustomer({ business, customer });
  else
    return await webCallCustomer({ business, customer });
}


export default function Call() {
  const isSignedIn   = useIsSignedIn();
  const isSalesforce = useIsSalesforce();

  const canUseCall = isSignedIn && !isSalesforce;
  if (!canUseCall)
    return null;

  if (ENV.isPuppeteer())
    return null;
  else if (ENV.isWeb() && ENV.isWebRTCSupported())
    return <WebCall/>;
  else if (ENV.isCordova() && ENV.isIOS())
    return <MobileCall/>;
  else
    return null;
}
