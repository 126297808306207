import * as UI                 from '../store/ui';
import getFriendlyErrorMessage from './get_friendly_error_message';
import rollbar                 from '../rollbar';


// Inform Rollbar of server, network, and code errors.
export function sendToRollbar(error) {
  if (isRequestError(error) || isServerError(error))
    rollbar.error(error);
}

// 401 will sign out user, do not show toast.
// We use toast message to show client errors.
// We use toast message to show server/network error.
export function showToastError(error) {
  return function(dispatch) {
    if (typeof error === 'string')
      dispatch(UI.setToast(error));
    else if (showClientError(error) || isServerError(error) || isRequestError(error))
      dispatch(UI.setToast(getFriendlyErrorMessage(error)));
  };
}

export function shouldRetry(error) {
  const isIdempotent = [ 'GET', 'PATCH', 'PUT', 'DELETE' ].includes(error.method);
  return isIdempotent && (isServerError(error) || isRequestError(error));
}

// Server error: 5xx
function isServerError(error) {
  return (error.statusCode >= 500);
}

function showClientError(error) {
  const isClientError  = error.statusCode >= 400 && error.statusCode < 500;
  const isUnauthorized = error.statusCode === 401;
  const isNotFound     = error.statusCode === 404;

  return isClientError && !isUnauthorized && !isNotFound;
}


// Network error: 0 (CORS, timeout, aborted, etc)
// Code error: no status code
function isRequestError(error) {
  return (error.statusCode === 0 || error.statusCode === undefined);
}
