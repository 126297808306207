import { getResources }        from 'redux-resource';
import { getResourceStatuses } from '../../util/get_resource_statuses';
import { getStatus }           from 'redux-resource';


export function getInvoices(state, businessID) {
  return getResources(state.invoices, businessID);
}


function getInvoicesReadStatus(state, businessID) {
  return getStatus(state.invoices, `requests.read-${businessID}.status`);
}


function getInvoicesReadStatusCode(state, businessID) {
  return state.invoices.requests[`read-${businessID}`]?.statusCode;
}


export function getInvoicesStatuses(state, businessID) {
  return {
    createStatus:   {},
    deleteStatus:   {},
    readStatus:     getInvoicesReadStatus(state, businessID),
    readStatusCode: getInvoicesReadStatusCode(state, businessID),
    updateStatus:   {},
  };
}


export function getInvoice(state, invoiceID) {
  return getResources(state.invoices, [ invoiceID ])[0];
}


export function getInvoiceReadStatus(state, invoiceID) {
  return getStatus(state.invoices, `meta[${invoiceID}].readStatus`);
}


export function getInvoiceStatuses(state, invoiceID) {
  return getResourceStatuses(state.invoices, invoiceID);
}
