// state structure:
//
// const state = {
//   runningQueues: [customerID1, customerID2, etc],
//   queues: {
//     customerID1: [
//       msg1,
//       msg2
//     ],
//     customerID2: [
//       msg1,
//       msg2
//     ]
//   },
//   localFiles: {
//     fileID1: nativeURL1,
//     fileID2: nativeURL2
//   }
// }

// msg structure:
//
// const msg = {
//   attachments,
//   body,
//   businessID,
//   customer,
//   id,
//   status: 'inQueuePending' | 'inQueueError',
//   timestamp
// }

const initialState = {
  runningQueues: [],
  queues:        {},
  localFiles:    {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_MESSAGE_TO_QUEUE': {
      const { customer } = action.payload;
      const customerID   = customer.id;

      const customerMessages = state.queues[customerID] || [];

      const message = {
        ...action.payload,
        status: 'inQueuePending',
      };

      return {
        ...state,
        queues: {
          ...state.queues,
          [customerID]: [ ...customerMessages, message ],
        },
      };
    }

    case 'UPDATE_MESSAGES_STATUS': {
      const { customerID } = action.payload;
      const { messageIds } = action.payload;
      const { status }     = action.payload;

      return {
        ...state,
        queues: {
          ...state.queues,
          [customerID]: state.queues[customerID].map(message => {
            if (messageIds.includes(message.id))
              message.status = status; // eslint-disable-line no-param-reassign
            return message;
          }),
        },
      };
    }

    case 'UPDATE_MESSAGE': {
      const { customerID } = action.payload;
      const { message }    = action.payload;

      return {
        ...state,
        queues: {
          ...state.queues,
          [customerID]: state.queues[customerID].map(currentMessage => {
            if (currentMessage.id === message.id)
              return { ...currentMessage, ...message  };
            else
              return currentMessage;
          }),
        },
      };
    }

    case 'REMOVE_MESSAGE_FROM_QUEUE': {
      const { customerID } = action.payload;
      const { messageId }  = action.payload;

      return {
        ...state,
        queues: {
          ...state.queues,
          [customerID]: state.queues[customerID].filter(message => message.id !== messageId),
        },
      };
    }

    case 'QUEUE_IS_RUNNING': {
      const { customerID } = action.payload;

      return {
        ...state,
        runningQueues: [ ...state.runningQueues, customerID ],
      };
    }

    case 'QUEUE_IS_NOT_RUNNING': {
      const { customerID } = action.payload;

      return {
        ...state,
        runningQueues: state.runningQueues.filter(id => id !== customerID),
      };
    }

    case 'ADD_LOCAL_FILE': {
      const { fileID }    = action.payload;
      const { nativeURL } = action.payload;

      return {
        ...state,
        localFiles: {
          ...state.localFiles,
          [fileID]: nativeURL,
        },
      };
    }

    case 'REMOVE_LOCAL_FILE': {
      const { fileID } = action.payload;

      return {
        ...state,
        localFiles: state.localFiles.filter(id => id !== fileID),
      };
    }

    default: {
      return state;
    }
  }
}
