import { lazy }   from 'react';
import renderPage from '../../../components/page';

const GmbAddressPage = lazy(() => import(/* webpackChunkName: 'google' */ './address_page'));
const GmbConnectPage = lazy(() => import(/* webpackChunkName: 'google' */ './connect_page'));
const GmbVerifyPage  = lazy(() => import(/* webpackChunkName: 'google' */ './verify_page'));
const GooglePage     = lazy(() => import(/* webpackChunkName: 'google' */ './google_page'));

export default function googleRoutes({ tabComponent }) {
  return {
    name:      'GoogleMyBusiness',
    path:      'google',
    component: renderPage(GooglePage),
    className: 'google-page',
    tabComponent,
    routes:    [
      {
        name:      'GoogleMyBusinessConnect',
        path:      'connect',
        component: renderPage(GmbConnectPage),
        className: 'gmb-connect-page',
        tabComponent,
      },
      {
        name:      'GoogleMyBusinessVerify',
        path:      'verify',
        component: renderPage(GmbVerifyPage),
        className: 'gmb-verify-page',
        tabComponent,
      },
      {
        name:      'GoogleMyBusinessAddress',
        path:      'address',
        component: renderPage(GmbAddressPage),
        className: 'gmb-address-page',
        tabComponent,
      },
    ],
  };
}
