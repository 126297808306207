import React from 'react';


// helper for rendering children of a given type
export default function childrenOfType(children, type) {
  return React.Children.map(children, child => {
    if (child && child.type === type)
      return child;
    else
      return null;
  });
}
