import rollbar from '../rollbar';


//  Subscribe to changes in the store.
//
//  store    - The store
//  selector - Callback whenever selector returns a new value
//  callback - The callback function
//
//  When using a callback, the callback receives three arguments:
//
//  oldValue    - The previous value
//  newValue    - The current value
//  unsubscribe - Call to stop receiving callbacks
//
//  Without a callback, this function returns a promise that resolves to the new
//  value.
//
//
//  For example, to be called whenever the counter increments:
//
//    function getCount(state) {
//      return state.count;
//    }
//
//    onChange(store, getCount, function every(oldCount, newCount) {
//      console.log('New count', newCount);
//      ...
//    });
//
//  To wait until the user signs in:
//
//    await onChange(store, User.isSignedIn);
//
export default function onChange(store, selector, callback) {
  if (callback) {
    _onChange(store, selector, callback);
    return null;
  } else {
    return new Promise(function(resolve) {
      _onChange(store, selector, function(oldValue, newValue, unsubscribe) {
        resolve(newValue);
        unsubscribe();
      });
    });
  }
}


function _onChange(store, selector, callback) {
  let lastValue     = selector(store.getState());
  const unsubscribe = store.subscribe(async function() {
    const newValue = selector(store.getState());
    const oldValue = lastValue;
    lastValue      = newValue;
    if (newValue !== oldValue) {
      try {
        await callback(oldValue, newValue, unsubscribe);
      } catch (error) {
        rollbar.error(error);
      }
    }
  });
}

