import { getResources } from 'redux-resource';
import { getStatus }    from 'redux-resource';

// -- Feedback --

export function getFeedback(state, businessID) {
  return getResources(state.feedback, businessID);
}

export function getPageInfo(state, businessID) {
  const pageInfo = state.feedback.meta.pageInfo;
  return pageInfo && pageInfo[businessID];
}

export function getNextCursor(state, businessID) {
  const pageInfo = getPageInfo(state, businessID);
  return pageInfo?.nextCursor;
}

export function getTotalCount(state, businessID) {
  const pageInfo = getPageInfo(state, businessID);
  return pageInfo?.totalCount;
}

export function getFeedbackReadStatus(state, businessID) {
  return getStatus(state.feedback, `requests.read-${businessID}.status`);
}

function getFeedbackReadStatusCode(state, businessID) {
  return state.feedback.requests[`read-${businessID}`]?.statusCode;
}

export function getFeedbackStatuses(state, businessID) {
  return {
    createStatus:   {},
    deleteStatus:   {},
    readStatus:     getFeedbackReadStatus(state, businessID),
    readStatusCode: getFeedbackReadStatusCode(state, businessID),
    updateStatus:   {},
  };
}

export function getFeedbackItem(state, feedbackID) {
  return getResources(state.feedback, [ feedbackID ])[0];
}
