import { isCordova } from './env';


export default async function copyToClipboard(text) {
  if (isCordova())
    await copyOnMobile(text);
  else
    copyOnWeb(text);
}


async function copyOnMobile(text) {
  await new Promise((resolve, reject) => {
    window.cordova.plugins.clipboard.copy(text, resolve, reject);
  });
}


function copyOnWeb(text) {
  if (document.queryCommandSupported('copy')) {
    const element          = document.createElement('textarea');
    element.value          = text;
    element.style.position = 'absolute';
    element.style.top      = '-100px';
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
  } else
    throw new Error('Copy not supported.');
}
