import React from 'react';


// helper to map a list of objects to { value, label } pairs for use with
// SelectDropdown or Button.Bar
export default function useOptions(items, optionMapper = defaultOptionMapper) {
  const options = React.useMemo(() => {
    return items?.map(optionMapper);
  }, [ items, optionMapper ]);

  return options;
}


function defaultOptionMapper(item) {
  return {
    value: String(item.id),
    label: String(item.name),
  };
}
