import { isIOS } from './env';

export function getBlobFromFileEntry(fileEntry, type) {
  return new Promise(function(resolve, reject) {
    const isFileFromCordova = !!fileEntry.nativeURL;

    if (isFileFromCordova) {
      const reader = new window.FileReader();

      fileEntry.file(function(file) {
        reader.onloadend = function() {
          const blob = new window.Blob([ new Uint8Array(this.result) ], { type });
          resolve(blob);
        };

        reader.readAsArrayBuffer(file);
      }, reject);
    } else {
      const blob = new window.Blob([ fileEntry ], { type });
      resolve(blob);
    }
  });
}

export function getMimeTypeFromFileEntry(fileEntry) {
  const defaultMimeType = 'application/octet-stream';

  return new Promise(function(resolve, reject) {
    const isFileFromCordova = !!fileEntry.nativeURL;

    if (isFileFromCordova) {
      fileEntry.file(function(success) {
        resolve(success.type || defaultMimeType);
      }, reject);
    } else
      resolve(fileEntry.type || defaultMimeType);
  });
}


export function getExtensionFromFileEntry(fileEntry) {
  const isFileFromCordova = !!fileEntry.nativeURL;
  const name              = isFileFromCordova ? fileEntry.nativeURL : fileEntry.name;
  const extension         = name.split('.').pop();
  return extension;
}


// Convert file:/// to http:// for WKWebView
// https://ionicframework.com/docs/v3/wkwebview/#rewriting-file
export function getFileURL(nativeURL) {
  if (!!window.cordova && isIOS())
    return window.Ionic.WebView.convertFileSrc(nativeURL);
  else
    return nativeURL;
}
