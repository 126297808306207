// default props when creating a new alert, to ensure previous alerts don't
// affect new alerts
export const defaultAlertProps = {
  isOpen:      true,
  type:        'info',
  label:       '',
  title:       '',
  message:     '',
  buttonLabel: undefined,
  buttons:     undefined,
};


export const toastDuration = 4000;

// Use this action to show a toast message.
export function setToast(text) {
  return function(dispatch) {
    dispatch({ type: 'UI_TOAST', payload: { text } });
  };
}

// Payload is title, body, and url; use null to hide notification.
export function setNotification(payload) {
  return { type: 'SET_NOTIFICATION', payload };
}

export function hideAlert() {
  return { type: 'SET_ALERT', payload: { isOpen: false } };
}

export function setAlert(props) {
  return function(dispatch) {
    return new Promise(resolve => {
      const payload = {
        ...defaultAlertProps,
        onReturn: resolve,
        onEscape: resolve,
        ...props,
      };
      dispatch({ type: 'SET_ALERT', payload });
    }).finally(() => dispatch(hideAlert()));
  };
}

export function setMenuIsOpen(isOpen) {
  return { type: 'SET_MENU', payload: isOpen };
}

// -- Progress / preloader --

export function progressStart() {
  return function(dispatch) {
    dispatch({ type: 'PROGRESS_START' });
  };
}


export function progressEnd() {
  return function(dispatch) {
    // Some actions sequence multiple requests in a row, eg updating user and
    // then reading team members list.  We want this progressEnd to overlap with
    // the next progressStart, so the indicator remains on screen continuously.
    setTimeout(function overlap() {
      dispatch({ type: 'PROGRESS_END' });
    });
  };
}
