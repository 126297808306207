import './trial_indicator_box.less';

import { chilipiperMeetings }       from '../components/contact_us_button';
import { differenceInCalendarDays } from 'date-fns';
import ContactUsButton              from '../components/contact_us_button';
import React                        from 'react';


export default function TrialIndicatorBox({ business }) {
  const trialEndsAtExist  = !!business?.trialEndsAt;
  const trialDaysLeft     = trialEndsAtExist && getDaysLeft(business.trialEndsAt);
  const trialHasExpired   = trialDaysLeft < 0;
  const showTrialDaysLeft = trialEndsAtExist && !trialHasExpired;
  const bodyMessage       = showTrialDaysLeft
    ? getDaysLeftMessage(trialDaysLeft)
    : 'Unlock all features';
  const meeting           = showTrialDaysLeft ? chilipiperMeetings.freeTrial : chilipiperMeetings.broadlyFree;

  return (
    <div className="trial-indicator-box">
      <div className="trial-indicator-box-left">
        {bodyMessage}
      </div>
      <ContactUsButton
        primary
        wide
        className="trial-upgrade-button"
        label="Contact us"
        meeting={meeting}
      />
    </div>
  );
}


function getDaysLeft(trialEndsAt) {
  const now  = new Date();
  const ends = new Date(trialEndsAt);
  return differenceInCalendarDays(ends, now);
}


function getDaysLeftMessage(daysLeft) {
  // We are rounding up the remaining days, if the business has
  // 19 days and 15 hours left on trial we show 20 days
  const remainingDays = daysLeft + 1;
  const dayLabel      = daysLeft > 1 ? 'days' : 'day';
  return `Trial ends in ${remainingDays} ${dayLabel}`;
}
