import * as Auth        from '../../auth';
import * as User        from '../../store/user';
import { lazy }         from 'react';
import renderPage       from '../../components/page';
import SignupBackground from './signup_background';
import store            from '../../store';


const BusinessStep       = lazy(() => import(/* webpackChunkName: 'signup' */ './business_step'));
const GoogleLocationStep = lazy(() => import(/* webpackChunkName: 'signup' */ './google_location_step'));
const ContactStep        = lazy(() => import(/* webpackChunkName: 'signup' */ './contact_step'));
const VerifyEmail        = lazy(() => import(/* webpackChunkName: 'signup' */ './verify_email'));
const AddressStep        = lazy(() => import(/* webpackChunkName: 'signup' */ './address_step'));
const CompleteStep       = lazy(() => import(/* webpackChunkName: 'signup' */ './complete_step'));


export default {
  path:   '/signup',
  public: true,
  async redirect(route, resolve, reject) {
    // If the user has not authenticated yet, redirect to the signup page on
    // login.broadly.com.
    if (!isAuthorized()) {
      Auth.authorize({ returnTo: '/', intent: 'signup' });
      reject();
      return;
    }

    const isAnon = isAnonymous();

    // Users signing up with GMB or QBO might not have a profile yet
    if (isAnon)
      await store.dispatch(User.createUserProfile());

    if (shouldVerifyEmail())
      resolve('/signup/verify_email');

    else if (shouldCompleteProfile())
      resolve('/signup/contact_info');

    else if (shouldConnectGoogle())
      resolve('/signup/google_location');

    else if (shouldCompleteBusiness())
      resolve('/signup/business_info');

    else if (shouldCompleteAddress())
      resolve('/signup/address_info');

    else
      resolve('/signup/complete');
  },
  routes: [
    {
      path:       'verify_email',
      component:  renderPage(VerifyEmail),
      className:  'signup-page verify-email-step',
      public:     false,
      background: SignupBackground,
      // eslint-disable-next-line max-params
      beforeEnter(routeTo, routeFrom, resolve, reject) {
        if (shouldVerifyEmail())
          resolve();
        else {
          this.navigate('/signup', { reloadCurrent: true });
          reject();
        }
      },
    },
    {
      path:       'contact_info',
      component:  renderPage(ContactStep),
      className:  'signup-page contact-step',
      public:     true,
      background: SignupBackground,
    },
    {
      path:       'google_location',
      component:  renderPage(GoogleLocationStep),
      className:  'signup-page google-location-step',
      public:     true,
      background: SignupBackground,
    },
    {
      path:       'business_info',
      component:  renderPage(BusinessStep),
      className:  'signup-page business-step',
      public:     true,
      background: SignupBackground,
    },
    {
      path:       'address_info',
      component:  renderPage(AddressStep),
      className:  'signup-page address-step',
      public:     true,
      background: SignupBackground,
    },
    {
      path:       'complete',
      component:  renderPage(CompleteStep),
      className:  'signup-page complete-step',
      public:     true,
      background: SignupBackground,
    },
  ],
};


function isAuthorized() {
  const hasAccessToken = !!User.getAccessToken(store.getState());
  return hasAccessToken;
}

function isAnonymous() {
  return User.isAnonymous(store.getState());
}

function shouldVerifyEmail() {
  const isEmailVerified = User.isEmailVerified(store.getState());
  return !isEmailVerified;
}

function shouldCompleteProfile() {
  const user       = User.getProfile(store.getState());
  const isComplete = (!!user?.name && !!user?.phone);
  return !isComplete;
}

function shouldConnectGoogle() {
  const user        = User.getProfile(store.getState());
  const business    = user?.memberOf?.[0]?.business;
  const hasBusiness = !!business;
  return !hasBusiness;
  // if they have a business, going to assume they connected a Google location.
  // edge cases will be handled once they start using the app
}


function shouldCompleteBusiness() {
  const user       = User.getProfile(store.getState());
  const business   = user?.memberOf?.[0]?.business;
  const isComplete = isBusinessComplete(business);
  return !isComplete;
}

function isBusinessComplete(business) {
  const required = [
    business?.name,
    business?.contact?.phone,
    business?.contact?.email,
    business?.contact?.url,
    business?.industry,
  ];

  return required.every(Boolean);
}

function shouldCompleteAddress() {
  const user       = User.getProfile(store.getState());
  const business   = user?.memberOf?.[0]?.business;
  const isComplete = isBusinessAddressComplete(business);
  return !isComplete;
}

function isBusinessAddressComplete(business) {
  const required = [
    business?.contact?.street,
    business?.contact?.city,
    business?.contact?.state,
    business?.contact?.countryCode,
    business?.contact?.postalCode,
  ];

  return required.every(Boolean);
}
