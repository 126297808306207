import { lazy }         from 'react';
import renderPage       from '../../components/page';
import SignupBackground from '../signup/signup_background';


const QboUnverifiedPage = lazy(() => import(/* webpackChunkName: 'quickbooksunverified' */ './qbo_unverified_page'));


export default {
  path:       '/quickbooksunverified',
  component:  renderPage(QboUnverifiedPage),
  className:  'qbo-unverified-page',
  public:     true,
  background: SignupBackground,
};
