// Access to Webapp API resources.
//
// Hooks-first API based on SWR https://swr.vercel.app
//
// Does not use the Redux store.
//

import { request }        from '../util/resources';
import { useDispatch }    from 'react-redux';
import { useSWRInfinite } from 'swr';
import ms                 from 'ms';
import React              from 'react';
import useSWR             from 'swr';

// Loads a paginated collection of resources.
//
// For resources that support filtering resources based on a timestamp,
// this hook will keep the list updated by querying the server for changes
// since the latest timestamp that we have in the cache. These resources
// expose a `pageInfo.latest` attribute, and the endpoints allow us to
// make requests like /conversations?since={latest}
//
// Example:
//
//   const { data: conversations } = useResourceList(
//     '/conversations',
//     { name: 'conversations' }
//   });
//
// This will happen:
//
// - Get the first page, limit to 100 elements for quick feedback
//   GET /conversations?limit=100
// - Response will have a `pageInfo` with the next cursor and `latest`, if any
// - Get the rest of the pages, 1000 elements per page for better throughput
//   GET /conversations?cursor={cursor}&limit=1000
// - When it's time to refresh the data (e.g. focus back on page), we will
//   just the newly updated conversations
//   GET /conversations?since={latest}
//
export function useResourceList(path, { name, sizeOfFirstPage = 100, sizeOfNthPage = 1000 }) {
  const { data: pages, ...restOfSWR } = useLoadPages(path, { sizeOfFirstPage, sizeOfNthPage });
  const { data: pagesSince, refresh } = usePaginationSince(path, () => pages?.[0]?.pageInfo.latest);

  const mergedData = React.useMemo(() => {
    const ids = new Set();

    if (!pages)
      return undefined;

    return [ ...(pagesSince || []), ...(pages || []) ]
      .flatMap(page => {
        if (page[name])
          return page[name];
        else
          throw new Error(`Expected to find ${name} in page`);
      })
      .reduce((accum, item) => {
        if (!ids.has(item.id)) {
          ids.add(item.id);
          accum.push(item);
        }
        return accum;
      }, []);
  }, [ pages, pagesSince, name ]);

  return { data: mergedData, refresh, ...restOfSWR };
}


// Loads new data based on the timestamp of the last
// successful request.
function usePaginationSince(path, sinceFn) {
  // SWR caching is by path. Our path changes on every succesful request
  // (new `pageInfo.latest` value every time). This is a way to get a
  // mutate function that is bound to the key that holds the data.
  const key                     = `PaginationSince:${path}`;
  const { data: pages, mutate } = useSWR(key, () => {}, {
    revalidateOnMount:     false,
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
  });

  const { mutate: refresh } = useSWRInfinite(getURLForSincePagination, useFetcher(), {
    onSuccess(data) {
      // Let UI refresh as we get each page.
      mutate(oldData => data.concat(oldData || []), false);
    },
    refreshInterval: ms('1m'),
  });

  function getURLForSincePagination() {
    const latest = pages?.[0]?.pageInfo.latest ?? sinceFn();
    if (!latest)
      return null;
    const url = getWebappURL(path);
    url.searchParams.set('since', latest);
    return url.toString();
  }

  return { data: pages, refresh };
}


// Loads all pages using cursor-based pagination.
// Meant to be used together with timestamp-based pagination,
// hence does not revalidate pages.
function useLoadPages(path, { sizeOfFirstPage, sizeOfNthPage }) {
  const { data: pages, size, setSize, error, mutate } = useSWRInfinite(getNextPageURL, useFetcher(), {
    revalidateOnFocus:     false,
    revalidateOnReconnect: false,
  });


  React.useEffect(function setTotalAmountOfPagesToLoad() {
    const recordCount = pages?.[0]?.pageInfo.totalCount;
    const pageCount   = recordCount ? Math.ceil((recordCount - sizeOfFirstPage) / sizeOfNthPage) + 1 : 1;
    if (pageCount > size)
      setSize(pageCount);
  }, [ size, pages, setSize, sizeOfFirstPage, sizeOfNthPage ]);


  function getNextPageURL(pageIndex, previousPageData) {
    const url = getWebappURL(path);

    if (pageIndex === 0) {
      url.searchParams.set('limit', sizeOfFirstPage);
      return url.toString();
    }

    if (previousPageData?.pageInfo.nextCursor) {
      url.searchParams.set('cursor', previousPageData.pageInfo.nextCursor);
      url.searchParams.set('limit', sizeOfNthPage);
      return url.toString();
    }

    // Stop paginating.
    return null;
  }

  return { data: pages, error, mutate };
}


export function useResource(path, fetcherParams) {
  const { data, error } = useSWR(getWebappURL(path), useFetcher(fetcherParams));
  return { data, error };
}


const defaultFetcherParams = {
  method:             'GET',
  showDefaultErrorUI: true,
};

export function useFetcher(fetcherParams) {
  const dispatch = useDispatch();

  return React.useCallback(async function fetcher(url, callerParams) {
    const response = await dispatch(request({
      url: getWebappURL(url),
      ...defaultFetcherParams,
      ...fetcherParams,
      ...callerParams,
    }));

    return response.body;
  }, [ dispatch, fetcherParams ]);
}

let timeout = 0;
// temporary replacement which allows fetching JSON files from /public
export function useLocalFetcher(stagger = 500) {
  const dispatch = useDispatch();

  return async function fetcher(url, { method = 'GET', body } = {}) {
    timeout += stagger; // stagger multiple simultaneous requests to simulate network latency
    await new Promise(resolve => setTimeout(resolve, timeout));
    setTimeout(() => {
      timeout = 0; // reset timeout for next batch of requests
    }, 1000);

    const response = await dispatch(request({
      url,
      method,
      body,
      showDefaultErrorUI: true,
    }));

    return response.body;
  };
}


function getWebappURL(path) {
  return new URL(path, API_URL);
}
