import isCustomerBlocked from '../contacts/is_customer_blocked';


export function isMessageEnabled({ customer, business }) {
  const channelAvailability = customer && hasAvailableChannel({ customer, business });
  const isBlocked           = customer && isCustomerBlocked(customer);
  return (channelAvailability && !isBlocked);
}


export function getAvailableChannels({ customer, business }) {
  const availableChannels = customer?.contact?.channels?.available ?? [];
  const isA2PComplete     = getIsA2PComplete({ business });
  if (isA2PComplete)
    return availableChannels;
  else
    return availableChannels.filter(channel => channel !== 'sms');
}


export function hasAvailableChannel({ customer, business }) {
  return !!getAvailableChannels({ customer, business }).length;
}


export function getPreferredChannel({ customer, business }) {
  const preferred            = customer?.contact?.channels?.preferred;
  const available            = getAvailableChannels({ customer, business });
  const isPreferredAvailable = available.includes(preferred);
  return isPreferredAvailable ? preferred : available[0];
}


export function hasChannel({ channelName, customer, business }) {
  const availableChannels = getAvailableChannels({ customer, business });
  return availableChannels.includes(channelName);
}


export function getIsA2PComplete({ business }) {
  const a2pStatus = business?.twilioA2P?.status;
  return a2pStatus === 'completed';
}
