// polyfill must be imported first
import './polyfill';
import './rollbar';

import BroadlyApp      from './app';
import iPhoneXDetector from './util/iphonex_detector';
import React           from 'react';
import ReactDOM        from 'react-dom';

import Framework7      from 'framework7';
import Framework7React from 'framework7-react';
import PullToRefresh   from 'framework7/components/pull-to-refresh/pull-to-refresh';


Framework7.use(iPhoneXDetector);
Framework7.use(Framework7React);
Framework7.use(PullToRefresh);

const markup = (
  <React.StrictMode>
    <BroadlyApp/>
  </React.StrictMode>
);

ReactDOM.render(markup, document.getElementById('app'));
