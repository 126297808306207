import MenuOverlay from './menu_overlay';
import MenuPanel   from './menu_panel';
import React       from 'react';
import useDOMID    from 'use-dom-id';


export default function Menu({ tab }) {
  const panelID = useDOMID('menu-panel');

  return (
    <>
      <MenuOverlay/>
      <MenuPanel {...{ tab, panelID }}/>
    </>
  );
}
