import { App } from 'framework7-react';
import React   from 'react';


export const AppContext = React.createContext();

const appParameters = {
  theme:  'ios',
  dialog: {
    title: 'Broadly',
  },
  touch: {
    disableContextMenu: false,
    tapHold:            true,
  },
  panel: {
    // On tablet, we keep the left panel open at all times.
    //
    // The default is designed ro a panel that opens when you click the
    // hamburger and closes when you do anything else (ie click the backdrop).
    //
    // This disables the default behavior.
    closeByBackdropClick: false,
  },
  // JS is slow so rather not
  animateWithJS: false,
};


export default function AppProvider({ children }) {
  const [ app, setApp ]       = React.useState();
  const [ router, setRouter ] = React.useState();
  const [ route, setRoute ]   = React.useState();

  const on = {
    // init is called with the app instance as context
    init() {
      setApp(this);
    },
  };

  const context = React.useMemo(
    () => ({ app, router, setRouter, route, setRoute }),
    [ app, router, route ],
  );

  return (
    <App params={{ ...appParameters, on }}>
      <AppContext.Provider value={context}>
        {children}
      </AppContext.Provider>
    </App>
  );
}


export function useAppContext() {
  return React.useContext(AppContext);
}

export function useApp() {
  const { app } = useAppContext();
  return app;
}
