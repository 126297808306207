import * as User  from '../../store/user';
import { lazy }   from 'react';
import renderPage from '../../components/page';

const TeamTab        = lazy(() => import(/* webpackChunkName: 'team' */ './team_tab'));
const TeamChatPage   = lazy(() => import(/* webpackChunkName: 'team' */ './team_chat_page'));
const InvitePage     = lazy(() => import(/* webpackChunkName: 'team' */ './invite_page'));
const ManageTeamPage = lazy(() => import(/* webpackChunkName: 'team' */ './manage_team_page'));
const TeamMemberPage = lazy(() => import(/* webpackChunkName: 'team' */ './team_member_page'));


const tabComponent = {
  component: renderPage(TeamTab),
  className: 'team-tab',
  name:      'team',
};

export default {
  path:      '/:businessID/team',
  component: renderPage(TeamTab, { tabPage: true }),
  className: 'team-tab',
  name:      'TeamChat',
  tabComponent,
  routes:    [
    {
      path:     'performance',
      redirect: (route, resolve) => {
        const { businessID } = route.params;
        resolve(`/${businessID}/team`);
      },
    },
    {
      path:      'manage',
      component: renderPage(ManageTeamPage),
      className: 'manage-team-page',
      allow:     User.isAdmin,
      name:      'ManageTeam',
      tabComponent,
      routes:    [
        {
          path:      ':userID',
          name:      'ManageTeam',
          component: renderPage(TeamMemberPage),
          className: 'team-member-page',
          tabComponent,
        },
      ],
    },
    {
      path:      'invite',
      name:      'ManageTeam',
      component: renderPage(InvitePage),
      className: 'invite-team-page',
      tabComponent,
      routes:    [
        {
          path:      ':providerID',
          name:      'ManageTeam',
          component: renderPage(InvitePage),
          className: 'invite-team-page',
          tabComponent,
        },
      ],
    },
    {
      path:      ':conversationID',
      name:      'TeamChat',
      component: renderPage(TeamChatPage),
      className: 'team-chat-page',
      tabComponent,
    },
  ],
};
