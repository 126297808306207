const translations = {
  sms:       'text message',
  email:     'email',
  facebook:  'Facebook',
  instagram: 'Instagram',
  gbc:       'Google',
};

const shortTranslations = {
  sms:       'text',
  email:     'email',
  facebook:  'Facebook',
  instagram: 'Instagram',
  gbc:       'Google',
};


export function hasAvailableChannel(customer) {
  return !!customer?.contact?.channels?.available?.length;
}


export function hasContactInfo(customer) {
  return !!customer?.contact?.email?.address || !!customer?.contact?.phone?.number;
}


export function channelToLabel(channel) {
  return translations[channel] || channel;
}


export function channelToShortLabel(channel) {
  return shortTranslations[channel] || channel;
}


export function hasOnlySms(customer) {
  const availableChannels = customer?.contact?.channels?.available || [];
  return availableChannels.length === 1 && availableChannels[0] === 'sms';
}
