import { lazy }   from 'react';
import renderPage from '../../components/page';

const ServiceMonsterPage = lazy(() => import(/* webpackChunkName: 'service-monster' */ './servicemonster_page'));


export default {
  path:      '/integration/servicemonster',
  component: renderPage(ServiceMonsterPage),
  className: 'service-monster-page',
};
