import * as User           from '../store/user';
import { lastLocationKey } from '../menu/menu_panel';
import bail                from '../util/bail';
import NotFoundPage        from '../components/not_found_page';
import React               from 'react';
import renderPage          from '../components/page';
import store               from '../store';

import AccountRoutes       from '../pages/account/routes';
import AddLocationRoutes   from '../pages/add_location/routes';
import CampaignsRoutes     from '../pages/campaigns/routes';
import ContactsRoutes      from '../pages/contacts/routes';
import CustomersRoutes     from '../pages/customers/routes';
import DashboardRoutes     from '../pages/dashboard/routes';
import GetStartedRoutes    from '../pages/get_started/routes';
import InboxRoutes         from '../pages/inbox/routes';
import IntegrationRoutes   from '../pages/integration/routes';
import LoginRoutes         from '../pages/login/routes';
import MetricsRoutes       from '../pages/metrics/routes';
import PaymentsRoutes      from '../pages/payments/routes';
import QboDisconnected     from '../pages/qbo_disconnected/routes';
import QboRoutes           from '../pages/qbo/routes';
import QboUnverified       from '../pages/qbo_unverified/routes';
import ReferralsRoutes     from '../pages/referrals/routes';
import ReportsRoutes       from '../pages/reports/routes';
import ReviewsRoutes       from '../pages/reviews/routes';
import ScratchpadRoutes    from '../pages/scratchpad/routes';
import SettingsRoutes      from '../pages/settings/routes';
import SigninRoutes        from '../pages/signin/routes';
import SignupRoutes        from '../pages/signup/routes';
import TeamChatRoutes      from '../pages/team/routes';


const rootRoute = {
  path:     '/',
  redirect: (route, resolve) => {
    const isAnonymous       = User.isAnonymous(store.getState());
    const isLoggedIn        = User.isSignedIn(store.getState());
    const hasAccessToken    = !!User.getAccessToken(store.getState());
    const isSignupPending   = User.isSignupPending(store.getState());
    const defaultLocationID = getDefaultLocationID();
    const hashRedirect      = getHashRedirect();
    const errorRedirect     = getErrorRedirect();

    if (hashRedirect)
      resolve(hashRedirect);

    else if (errorRedirect)
      resolve(errorRedirect);

    else if (isSignupPending)
      resolve(SignupRoutes.path);

    else if (isAnonymous)
      resolve('/account/unknown');

    else if (!isLoggedIn)
      resolve(LoginRoutes.path);

    else if (defaultLocationID)
      resolve(`/${defaultLocationID}/inbox`);

    else if (!hasAccessToken)
      bail();
  },
};

const indexHtmlRoute = {
  path:     '/index.html',
  redirect: '/',
};

const defaultRoute = {
  path:         '(.*)',
  component:    renderPage(() => <NotFoundPage showPageInfo/>),
  tabComponent: {
    component: null,
  },
};

const routes = [
  GetStartedRoutes,
  LoginRoutes,
  SigninRoutes,
  SignupRoutes,
  AccountRoutes,
  ReportsRoutes,
  IntegrationRoutes,
  CustomersRoutes,
  MetricsRoutes,
  ReviewsRoutes,
  PaymentsRoutes,
  SettingsRoutes,
  TeamChatRoutes,
  CampaignsRoutes,
  ContactsRoutes,
  DashboardRoutes,
  InboxRoutes,
  ReferralsRoutes,
  AddLocationRoutes,
  ScratchpadRoutes,
  QboDisconnected,
  QboRoutes,
  QboUnverified,
  indexHtmlRoute,
  rootRoute,
  defaultRoute,
];

export default routes;


function getDefaultLocationID() {
  const locationIDs        = User.getLocationIDs(store.getState());
  const storedLocationID   = window.localStorage.getItem(lastLocationKey);
  const matchingLocationID = storedLocationID && locationIDs?.find(id => id === storedLocationID);
  return matchingLocationID || locationIDs?.[0];
}


// redirect legacy hash URLs, removing the hash
function getHashRedirect() {
  if (window.location.hash)
    return window.location.hash.slice(1);
  else
    return undefined;
}


function getErrorRedirect() {
  const params      = new URLSearchParams(window.location.search);
  const error       = params.get('error');
  const description = params.get('error_description');

  if (error === 'invalid_request' && description === 'Unverified QBO user')
    return '/quickbooksunverified';
  else
    return undefined;
}
