import * as User  from '../../store/user';
import { lazy }   from 'react';
import renderPage from '../../components/page';

const InboxTab         = lazy(() => import(/* webpackChunkName: 'inbox' */ './tab'));
const AssignPage       = lazy(() => import(/* webpackChunkName: 'inbox' */ './assign_page'));
const ConversationPage = lazy(() => import(/* webpackChunkName: 'inbox' */ './conversation_page'));
const NewQuotePage     = lazy(() => import(/* webpackChunkName: 'payments' */ '../payments/new_quote_page'));
const NewInvoicePage   = lazy(() => import(/* webpackChunkName: 'payments' */ '../payments/new_invoice_page'));


const tabComponent = {
  component: renderPage(InboxTab),
  className: 'inbox-page',
  name:      'inbox',
};


export default {
  path:      '/:businessID/inbox',
  component: renderPage(InboxTab, { tabPage: true }),
  className: 'inbox-page',
  name:      'Inbox',
  tabComponent,
  routes:    [
    {
      path:      ':conversationID',
      component: renderPage(ConversationPage),
      className: 'conversation-page',
      name:      'Inbox',
      tabComponent,
      routes:    [
        {
          path:            'assign',
          component:       renderPage(AssignPage),
          className:       'assign-page',
          allow:           User.isManager,
          disallowedTitle: 'You are not authorized to assign conversations.',
          name:            'AssignInfo',
          tabComponent,
        },
        {
          path:      ':contactID/quote',
          component: renderPage(NewQuotePage),
          className: 'new-quote-page',
          name:      'InboxQuote',
          tabComponent,
        },
        {
          path:      ':contactID/invoice',
          component: renderPage(NewInvoicePage),
          className: 'new-invoice-page',
          name:      'InboxInvoice',
          tabComponent,
        },
      ],
    },
  ],
};
