// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./select_triangle_black.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "./select_triangle_white.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "select.select.button {\n  margin: 0;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n  font-family: inherit;\n  font-size: inherit;\n  line-height: inherit;\n  height: auto;\n  width: auto;\n  display: inline-block;\n  --arrow-size: 1.5em;\n  --arrow-inset: 0.5em;\n  padding-right: 3em;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: calc(100% - var(--arrow-inset)) 50%;\n  background-size: var(--arrow-size) var(--arrow-size);\n}\nselect.select.button:-moz-focusring {\n  color: transparent;\n  text-shadow: 0 0 0 #000;\n}\nselect.select.button.primary {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
