import * as Actions    from './actions';
import * as Selectors  from './selectors';
import { compose }     from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import React           from 'react';

// -- User hooks --

// Selectors

export function useProfile() {
  return useSelector(state => Selectors.getProfile(state));
}


export function useAccessToken() {
  return useSelector(state => Selectors.getAccessToken(state));
}


export function useUserID() {
  return useSelector(state => Selectors.getUserID(state));
}


export function useUserName() {
  return useSelector(state => Selectors.getUserName(state));
}


export function useUserEmail() {
  return useSelector(state => Selectors.getUserEmail(state));
}


export function useRole(businessID) {
  return useSelector(state => Selectors.getRole(state, businessID));
}


export function useIsAdmin(businessID) {
  return useSelector(state => Selectors.isAdmin(state, businessID));
}


export function useIsManager(businessID) {
  return useSelector(state => Selectors.isManager(state, businessID));
}


export function useIsSalesforce() {
  return useSelector(state => Selectors.isSalesforce(state));
}


export function useIsPasswordless() {
  return useSelector(state => Selectors.isPasswordless(state));
}


export function useIsSignedOut() {
  return useSelector(state => Selectors.isSignedOut(state));
}


export function useCanChangeOnlineProfiles(businessID) {
  return useSelector(state => Selectors.canChangeOnlineProfiles(state, businessID));
}


export function useBrands() {
  const organizations = useOrganizations();
  const locations     = useActiveLocations();
  return React.useMemo(() => collateBrands({ organizations, locations }), [ organizations, locations ]);
}

function collateBrands({ organizations = [], locations = [] }) {
  const organizationIDs     = organizations.map(({ id }) => id);
  const otherLocations      = locations.filter(location => {
    // exclude locations with an organization, if the user already has access to
    // that organization
    return !organizationIDs.includes(location.organization?.id);
  });
  const otherLocationsBrand = otherLocations.length && {
    id:        'otherlocations',
    type:      'otherlocations',
    name:      'Other locations',
    locations: otherLocations,
  };

  // Sort organizations by name, and put 'Other locations' at the end
  return organizations
    .map(({ id, name }) => ({ id, name, type: 'organization' }))
    .sort(sortByName)
    .concat(otherLocationsBrand ? [ otherLocationsBrand ] : []);
}

function sortByName(a, b) {
  return a.name.localeCompare(b.name);
}

export function useOrganizations() {
  const profile  = useProfile();
  const memberOf = profile?.memberOf;
  return React.useMemo(() => Selectors.getBrands({ memberOf }), [ memberOf ]);
}


export function useOrganization(organizationID) {
  const organizations = useOrganizations();
  return organizations?.find(({ id }) => id === organizationID);
}


export function useLocations() {
  const profile  = useProfile();
  const memberOf = profile?.memberOf;
  return React.useMemo(() => Selectors.getLocations({ memberOf }), [ memberOf ]);
}


export function useLocation(locationID) {
  const locations = useLocations();
  return locations?.find(({ id }) => id === locationID);
}


export function useActiveLocations() {
  const locations = useLocations();
  return React.useMemo(() => {
    return locations?.filter(Selectors.isLocationStatusActive);
  }, [ locations ]);
}


export function useBadgeCount() {
  return useSelector(state => Selectors.getBadgeCount(state));
}


export function useContactsBadgeCount(businessID) {
  return useSelector(state => Selectors.getContactsBadgeCount(state, businessID));
}


export function useIsSignedIn() {
  return useSelector(state => Selectors.isSignedIn(state));
}


export function useIsSignupPending() {
  return useSelector(state => Selectors.isSignupPending(state));
}


export function useIsAnonymous() {
  return useSelector(state => Selectors.isAnonymous(state));
}


export function useIsEmailVerified() {
  return useSelector(state => Selectors.isEmailVerified(state));
}


export function useCallSettings() {
  return useSelector(state => Selectors.getCallSettings(state));
}


export function useCanUserMakeCalls() {
  return useSelector(state => Selectors.getCanUserMakeCalls(state));
}


export function useNotificationSettings() {
  return useSelector(state => Selectors.getNotificationSettings(state));
}

export function useCanSeeGetStarted(businessID) {
  return useSelector(state => Selectors.canSeeGetStarted(state, businessID));
}


// Actions

export function useSetAccessToken() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.setAccessToken), [ dispatch ]);
}


export function useSignOut() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.signOut), [ dispatch ]);
}


export function useCreateUserProfile() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.createUserProfile), [ dispatch ]);
}


export function useUpdateProfile() {
  const dispatch = useDispatch();
  return React.useMemo(() => compose(dispatch, Actions.updateProfile), [ dispatch ]);
}

