import * as Actions            from './actions';
import Button                  from '../../components/button';
import getFriendlyErrorMessage from '../../util/get_friendly_error_message';
import merge                   from 'lodash.merge';
import React                   from 'react';
import store                   from '..';


export function alert(message, props = {}) {
  return store.dispatch(Actions.setAlert({ message, ...props }));
}

export function errorAlert(message, props = {}) {
  return alert(message, { type: 'error', ...props });
}

export function successAlert(message, props = {}) {
  return alert(message, { type: 'success', ...props });
}

export function toast(message) {
  return store.dispatch(Actions.setToast(message));
}

export function toastError(error) {
  const message = getFriendlyErrorMessage(error);
  return toast(message);
}

export async function confirm(props) {
  return new Promise((resolve, reject) => {
    return store.dispatch(Actions.setAlert(getConfirmProps({ resolve, reject, props })));
  }).finally(() => store.dispatch(Actions.hideAlert()));
}

function getConfirmProps({ resolve, reject, props }) {
  const callerProps  = typeof props === 'function' ? props(resolve, reject) : props;
  const defaultProps = getDefaultConfirmProps(resolve, reject);
  const mergedProps  = merge({}, defaultProps, callerProps);
  const buttons      = getButtonRow({ buttons: mergedProps.buttons, resolve, reject });
  // lodash.merge mangles props.message, so we have to set it manually
  return { ...mergedProps, buttons, message: props.message };
}

function getDefaultConfirmProps(resolve, reject) {
  return {
    onEscape: reject,
    onReturn: null, // don't want to make it too easy to accidentally confirm
    buttons:  [
      { label: 'Cancel', secondary: true, wide: true },
      { label: 'Confirm', primary: true, wide: true },
    ],
  };
}

function getButtonRow({ buttons, resolve, reject }) {
  const button1 = buttons[0];
  const button2 = buttons[1];

  async function onCancel() {
    await button1?.onClick?.();
    reject();
  }

  async function onConfirm() {
    await button2?.onClick?.();
    resolve();
  }

  return (
    <Button.Row>
      <Button {...button1} onClick={onCancel}/>
      <Button {...button2} onClick={onConfirm}/>
    </Button.Row>
  );
}


export function openMenu() {
  return store.dispatch(Actions.setMenuIsOpen(true));
}

export function closeMenu() {
  return store.dispatch(Actions.setMenuIsOpen(false));
}

export function toggleMenu() {
  const isOpen = store.getState().ui.menu.isOpen;
  return store.dispatch(Actions.setMenuIsOpen(!isOpen));
}

export function notify({ title, body, url }) {
  return store.dispatch(Actions.setNotification({ isOpen: true, title, body, url }));
}
