export default async function lockOrientationInMobile() {
  const queryMobileInPortrait  = window.matchMedia('(max-width: 767px) and (orientation: portrait)');
  const queryMobileInLandscape = window.matchMedia('(max-height: 767px) and (orientation: landscape)');

  const shouldLock = queryMobileInPortrait.matches || queryMobileInLandscape.matches;
  if (shouldLock)
    await lock();
}


async function lock(orientation = 'portrait') {
  try {
    const browserLocker = getBrowserScreenOrientationLocker();
    if (browserLocker)
      await browserLocker(orientation);

    const cordovaLocker = await getCordovaScreenOrientationLocker();
    if (cordovaLocker)
      await cordovaLocker(orientation);
  } catch (_) {
    // no support to lock
  }
}


function getBrowserScreenOrientationLocker() {
  return window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation;
}


let deviceReady = false;
document.addEventListener('deviceready', () => {
  deviceReady = true;
});

function getCordovaScreenOrientationLocker() {
  if (window.cordova) {
    if (deviceReady)
      return window.screen.orientation?.lock;
    else {
      return new Promise(resolve => {
        document.addEventListener('deviceready', () => {
          resolve(window.screen.orientation?.lock);
        });
      });
    }
  } else
    return null;
}
