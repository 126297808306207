import * as UI            from '../store/ui';
import { getAccessToken } from './token';
import { useAccessToken } from './token';
import CallBar            from './call_bar';
import React              from 'react';
import rollbar            from '../rollbar';
import store              from '../store';


const defaultOnCall = {
  type:        null,
  status:      null,
  business:    null,
  customer:    null,
  startedAt:   null,
  isMuted:     false,
  isOnSpeaker: false,
};


export default function MobileCall() {
  const token                 = useAccessToken();
  const [ onCall, setOnCall ] = React.useState(defaultOnCall);

  function handleMobileCall({ detail: { customer, business } }) {
    const type      = 'outgoing';
    const status    = 'onCall';
    const startedAt = new Date();
    setOnCall({ type, status, customer, business, startedAt });
  }

  React.useEffect(() => {
    window.Twilio.TwilioVoiceClient.error(function(error) {
      rollbar.error('Twilio call connection error', error);
      setOnCall({ ...defaultOnCall });
      UI.toast(error.message || error.toString());
    });

    window.Twilio.TwilioVoiceClient.calldiddisconnect(function() {
      setOnCall({ ...defaultOnCall });
    });

    document.addEventListener('mobileCall', handleMobileCall);

    return () => {
      document.removeEventListener('mobileCall', handleMobileCall);
    };
  }, [ setOnCall ]);


  React.useEffect(function whenTokenChanges() {
    if (token)
      window.Twilio.TwilioVoiceClient.initialize(token);
  }, [ token ]);

  function handleOnFinish() {
    setOnCall({ ...defaultOnCall });
    window.Twilio.TwilioVoiceClient.disconnect();
  }

  function handleOnMute() {
    if (onCall.isMuted) {
      setOnCall({ ...onCall, isMuted: false });
      window.Twilio.TwilioVoiceClient.unmuteCall();
    } else {
      setOnCall({ ...onCall, isMuted: true });
      window.Twilio.TwilioVoiceClient.muteCall();
    }
  }

  function handleOnSpeaker() {
    if (onCall.isOnSpeaker) {
      setOnCall({ ...onCall, isOnSpeaker: false });
      window.Twilio.TwilioVoiceClient.setSpeaker('off');
    } else {
      setOnCall({ ...onCall, isOnSpeaker: true });
      window.Twilio.TwilioVoiceClient.setSpeaker('on');
    }
  }

  const isOnCall = !!onCall.startedAt;

  if (isOnCall)
    return <CallBar {...{ ...onCall, handleOnMute, handleOnSpeaker, handleOnFinish }}/>;
  else
    return null;
}


export async function callCustomer({ business, customer }) {
  store.dispatch(UI.progressStart());
  try {
    const token = await getAccessToken();
    window.Twilio.TwilioVoiceClient.call(token, { To: customer.contact.phone.number, customerID: customer.id });
    const callEvent = new window.CustomEvent(
      'mobileCall',
      {
        detail:  { customer, business },
        bubbles: true,
      },
    );
    document.dispatchEvent(callEvent);
  } finally {
    store.dispatch(UI.progressEnd());
  }
}
