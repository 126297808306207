import { lazy }   from 'react';
import EmptyPage  from '../../components/empty_page';
import renderPage from '../../components/page';

const ContactsPage        = lazy(() => import(/* webpackChunkName: 'contacts' */ '../contacts/contacts_page'));
const RedirectToInboxPage = lazy(() => import(/* webpackChunkName: 'contacts' */ './redirect_to_inbox_page'));


const tabComponent = {
  component: renderPage(ContactsPage),
  name:      'contacts',
};


export default {
  path:      '/:businessID/customers',
  component: renderPage(EmptyPage),
  className: 'empty-page',
  name:      'CustomerList',
  on:        {
    pageBeforeIn(_, { route, router }) {
      const { params }     = route;
      const { businessID } = params;

      router.navigate(`/${businessID}/inbox`, { reloadAll: true });
    },
  },
  routes: [
    {
      path:      ':contactID',
      component: renderPage(RedirectToInboxPage),
      name:      'Customer',
      tabComponent,
      routes:    [
        {
          path:      'info',
          component: renderPage(RedirectToInboxPage),
          name:      'Customer',
          tabComponent,
        },
        {
          path:      'invoice',
          component: renderPage(EmptyPage),
          className: 'empty-page',
          name:      'Invoice',
          on:        {
            pageBeforeIn(_, { route, router }) {
              const { params }     = route;
              const { businessID } = params;
              const { customerID } = params;

              router.navigate(`/${businessID}/contacts/${customerID}/payment`, { reloadAll: true });
            },
          },
        }],
    },
  ],
};
