import React     from 'react';
import useRouter from './use_router';

// Usage:
//
// import useNavigation from '../hooks/use_navigation';
// const { navigateTo, navigateBackTo } = useNavigation();
//
// or
//
// import { useNavigateTo } from '../hooks/use_navigation';
// const navigateTo = useNavigateTo();
//


export default function useNavigation() {
  const navigateTo     = useNavigateTo();
  const navigateBackTo = useNavigateBackTo();
  return { navigateTo, navigateBackTo };
}


export function useNavigateTo() {
  const router = useRouter();

  return React.useCallback(function navigateTo(path, options = {}) {
    const href = joinPath(router.currentRoute.path, path);
    router.navigate(href, options);
  }, [ router ]);
}


export function useNavigateBackTo() {
  const router = useRouter();

  return React.useCallback(function navigateBackTo(path, options = {}) {
    // if navigateBackTo is called with an undefined path, use the previous
    // page, or infer the new path by removing the last param in the current URL
    const previousHistoryPage = getPreviousPageFromHistory(router);
    const previousRoutePage   = getPreviousPageFromRoute(router);
    const newPath             = path && joinPath(router.currentRoute.path, path);
    const href                = newPath || previousHistoryPage || previousRoutePage;

    // F7 router.back ignores our path if there's no previous history state to
    // replace. So in that case we call router.navigate instead.
    const canUseBack = !!previousHistoryPage;

    if (canUseBack)
      router.back(href, options);
    else
      router.navigate(href, { ...options, clearPreviousHistory: true });
  }, [ router ]);
}


function getPreviousPageFromHistory(router) {
  const invalidRoutes = [ '/', router.currentRoute.url ];
  const previousPages = router.history.filter(url => !invalidRoutes.includes(url));
  return previousPages.pop();
}


function getPreviousPageFromRoute(router) {
  const parts = router.currentRoute.url?.split('/');

  if (parts?.length > 3)
    return parts.slice(0, -1).join('/');
  else
    return undefined;
}


export function getPreviousPage(router) {
  const previousHistoryPage = getPreviousPageFromHistory(router);
  const previousRoutePage   = getPreviousPageFromRoute(router);
  return previousHistoryPage || previousRoutePage;
}


export function joinPath(base, path = '') {
  if (path === '')
    return base;

  if (path[0] === '/')
    return path;

  const first = path.split('/')[0];
  if (first === '.')
    return joinPath(base, path.slice(2));

  if (first === '..') {
    const parent = base.split('/').slice(0, -1).join('/');
    return joinPath(parent, path.slice(3));
  }

  return `${base}/${path}`;
}
