import { getStatus } from 'redux-resource';


export function getResourceStatuses(state, metaKey) {
  const statuses    = getStatuses(state, metaKey);
  const statusCodes = getStatusCodes(state, metaKey);

  return {
    ...statuses,
    ...statusCodes,
  };
}


export function getStatusCodes(state, metaKey) {
  const meta = state.meta[metaKey];

  if (meta) {
    const { createStatusCode } = meta;
    const { deleteStatusCode } = meta;
    const { readStatusCode   } = meta;
    const { updateStatusCode } = meta;

    return {
      createStatusCode,
      deleteStatusCode,
      readStatusCode,
      updateStatusCode,
    };
  } else
    return {};
}


export function getStatuses(state, statusLocation) {
  return {
    createStatus: getStatus(state, `meta[${statusLocation}].createStatus`),
    deleteStatus: getStatus(state, `meta[${statusLocation}].deleteStatus`),
    readStatus:   getStatus(state, `meta[${statusLocation}].readStatus`),
    updateStatus: getStatus(state, `meta[${statusLocation}].updateStatus`),
  };
}


// Returns the read status for a list of resources.
// To be used together with Resources.listResources().
export function getListReadStatus(state, resourceType, businessID) {
  const status     = getStatus(state[resourceType], `requests.read-${businessID}.status`);
  const paginating = !!state[resourceType].meta.paginating?.[businessID];
  const statusCode = state[resourceType].requests[`read-${businessID}`]?.statusCode ?? null;
  return {
    ...status,
    paginating,
    statusCode,
  };
}
