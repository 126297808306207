import dispatchAction from './dispatch_action';
import rollbar        from '../rollbar';
import store          from '../store';


export default function onDataMessage(json) {
  try {
    // Looks like Cordova parses this JSON on Android, but not on iOS; Firebase
    // always passes the document as is.
    const message = (typeof json === 'string') ? JSON.parse(json) : json;

    // TODO: Should check message is for this user?
    // const userID  = User.getUserID(store.getState());
    // if (message.action && userID === message.userID)
    if (message.action) {
      const action = (typeof message.action === 'string') ? JSON.parse(message.action) : message.action;
      dispatchEvent(action);
      store.dispatch(dispatchAction(action));
    }
  } catch (error) {
    rollbar.error(error);
  }
}


function dispatchEvent(action) {
  const { type, payload } = action;
  const eventName         = `broadly:${type}`;
  const event             = new window.CustomEvent(eventName, { detail: payload });
  window.dispatchEvent(event);
}
