import * as User  from '../../store/user';
import { lazy }   from 'react';
import renderPage from '../../components/page';
import store      from '../../store';

const GetStartedPage = lazy(() => import(/* webpackChunkName: 'getstarted' */ './get_started_page'));

const tabComponent = {
  name:      'getstarted',
  component: null,
};


export default {
  path:      '/:businessID/getstarted',
  component: renderPage(GetStartedPage),
  className: 'get-started-page',
  // eslint-disable-next-line max-params
  beforeEnter(routeTo, routeFrom, resolve, reject) {
    const canSeeGetStarted = User.canSeeGetStarted(store.getState(), routeTo.params.businessID);

    if (canSeeGetStarted)
      resolve();
    else {
      reject();
      this.navigate('/');
    }
  },
  tabComponent,
};
