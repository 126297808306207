import './route_background.less';

import React           from 'react';
import useCurrentRoute from '../hooks/use_current_route';


export default function RouteBackground() {
  const route = useCurrentRoute();
  if (!route)
    return null;

  const Background = route.route.background;
  if (!Background)
    return null;

  return (
    <div className="view-background">
      <Background/>
    </div>
  );
}
