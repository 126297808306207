// Exports Rollbar instance
import * as ENV  from './util/env';
import buildInfo from './app/build_info';
import Rollbar   from 'rollbar';

const rollbar = getInstance();


function getInstance() {
  // Web and Cordova set window.Rollbar to catch errors when loading JavaScript.
  // Tests don't, so we'll give them an instance that doesn't do anything.
  const fromHTML     = (typeof window === 'object' && window.Rollbar);
  const instance     = fromHTML || new Rollbar({});
  const isProduction = ENV.isProduction();
  instance.configure({
    enabled: isProduction,
    verbose: !isProduction,
  });
  return instance;
}


switch (process.env.NODE_ENV) {
  case 'production': {
    wrapError(null);

    // In production we want to report device ID, build number, user ID, etc.
    document.addEventListener('deviceready', onDeviceReady, false);
    break;
  }

  case 'development': {
    // In development we want to see all errors in the console.
    // When running code in Puppeteer, it is Webpack that sets the environment,
    // so the environment would be development.
    wrapError(console.error);
    break;
  }

  default: {
    // This only applies when running unit tests and loading code in Node
    // process.
    wrapError(null);
    break;
  }
}


// Changes rollbar.error to a function:
//
// 1. Turn it into a function, so you can do promise.catch(rollbar.error).
//    You probably shouldn't — explicit > implicit, use an arrow function
//    instead — but if you did, it should work.  Error reporting is not the
//    place to be strict about calling styles.
//
// 2. When run in production or development, also log to console (or any
//    supplied method).  Rollbar has a verbose option, but it only uses this
//    method when Rollbar.enabled is on, so useless for debugging.
//
function wrapError(write) {
  const error   = rollbar.error;
  rollbar.error = function(...args) {
    error.apply(rollbar, args);

    if (write)
      write(args[0].stack || args[0]);
  };
}


// Cordova - Include build number so we know where the bug is
async function onDeviceReady() {
  try {
    const { version } = buildInfo;
    if (version) {
      const payload = {
        code_version: version, // eslint-disable-line camelcase
      };
      rollbar.configure({ payload });
    }
  } catch (error) {
    rollbar.error(error);
  }
}


// Include user ID and username based on JWT token
export function setPerson(person) {
  rollbar.configure({ payload: { person } });
}

export default rollbar;
