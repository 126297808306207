export function getBusinessPlaceID(business) {
  return business?.google?.placeID || '';
}

export function getGoogleLocationAddress(location) {
  if (isServiceAreaLocation(location))
    return getServiceAreaBusinessAddress(location);

  return getAddressString(location.storefrontAddress);
}

function getAddressString(address) {
  const {
    administrativeArea,
    locality,
    postalCode,
    regionCode,
  } = address;

  const addressLines = Array.isArray(address.addressLines)
    ? address.addressLines
    : [];

  const addressParts = [
    ...addressLines,
    locality,
    joinWithSpaces([ administrativeArea, postalCode ]),
  ];

  if (regionCode !== 'US')
    addressParts.push(regionCode);

  const addressString = joinWithCommas(addressParts);

  return addressString;
}

function joinWithSpaces(parts) {
  return parts.filter(Boolean).join(' ');
}
function joinWithCommas(parts) {
  return parts.filter(Boolean).join(', ');
}

export function getVerificationAddressString(verifyOption) {
  // verifyOption.addressData has an address property that is the same shape as
  // googleLocation.address
  return getAddressString(verifyOption.addressData.address);
}

// https://developers.google.com/my-business/reference/businessinformation/rest/v1/accounts.locations#metadata
export function getGoogleLocationIsVerified(location) {
  return !!location?.metadata?.hasVoiceOfMerchant;
}

export function getGoogleLocationIsDuplicate(location) {
  return !!location?.metadata?.duplicateLocation;
}

export function getGoogleLocationPlaceID(location) {
  return location?.metadata?.placeId;
}

// https://developers.google.com/my-business/reference/verifications/rest/v1/locations/getVoiceOfMerchantState
export function getGoogleLocationIsPendingVerification(location) {
  return !!location?.voiceOfMerchantState?.verify?.hasPendingVerification;
}

export function getGoogleLocationIsSuspended(location) {
  return (location?.voiceOfMerchantState?.complyWithGuidelines?.recommendationReason === 'BUSINESS_LOCATION_SUSPENDED');
}

export function getGoogleLocationIsDisabled(location) {
  return (location?.voiceOfMerchantState?.complyWithGuidelines?.recommendationReason === 'BUSINESS_LOCATION_DISABLED');
}

export function getGoogleMessagingIsInactive(gmbProfile) {
  return (gmbProfile?.messaging?.status === 'inactive');
}

export function getGoogleMessagingIsIneligible(gmbProfile) {
  return (gmbProfile?.messaging?.status === 'ineligible');
}

export function isServiceAreaLocation(location) {
  // https://developers.google.com/my-business/reference/businessinformation/rest/v1/accounts.locations#Location.BusinessType
  const businessType = location.serviceArea?.businessType;
  return businessType === 'CUSTOMER_LOCATION_ONLY';
}

function getServiceAreaBusinessAddress(location) {
  try {
    return location.serviceArea.places.placeInfos[0].placeName;
  } catch (error) {
    return 'No address provided.';
  }
}

export function getBusinessAddressForGoogle(businessAddress) {
  if (!businessAddress)
    return null;

  const { street }      = businessAddress;
  const { city }        = businessAddress;
  const { state }       = businessAddress;
  const { postalCode }  = businessAddress;
  const { countryCode } = businessAddress;

  if (!street || !city || !state || !postalCode || !countryCode)
    return null;

  const address = {
    languageCode: 'en',
    regionCode:   countryCode,
    postalCode,
    addressLines: [
      street,
      [ city, state ].join(', '),
    ],
  };

  return address;
}
